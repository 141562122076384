import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { useCallback } from 'react';
import {
    ListItemIcon,
    ListItemText,
    MenuItem,
    useMediaQuery,
} from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useUserMenu } from 'react-admin';
import { useGetIdentity, useRedirect } from "react-admin";

const MyAccount = ({...props}) => {
  const { data: identity } = useGetIdentity()
  const { onClose } = useUserMenu();
  const redirect = useRedirect();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const handleClick = useCallback(() => {
      redirect('edit', identity?.status === "Collaborateur" ? 'collaborators' : 'users', identity?.id)
      onClose();
  }, [identity, redirect, onClose]);

  return (
    <MenuItem
        onClick={handleClick}
        // @ts-ignore
        component={isXSmall ? 'span' : 'li'}
        {...props}
    >
      <ListItemIcon>
        <AccountCircleIcon/>
      </ListItemIcon>
      <ListItemText>Mon profil</ListItemText>
    </MenuItem>
  );
};

export default MyAccount
