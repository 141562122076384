import { Grid, Typography } from "@mui/material";
import React from "react";
import { Edit, TextInput, useNotify, useRedirect, Labeled, TextField, Toolbar, SaveButton } from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";

const ProvidersEdit = ({ ...props }) => {
  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={
          <Toolbar {...props}>
            <SaveButton />
          </Toolbar>
        }
      >
        <Typography variant="h6" mb={2}>
          Edition d'un <b>Fournisseur</b>
        </Typography>

        <div style={{ borderBottom: "1px solid lightgray", width: "100%", marginBottom: "28px", marginTop: "0px" }} />

        <Grid container direction={"column"}>
          <Labeled label="Identifiant">
            <TextField source="id" mb={2} />
          </Labeled>
          <TextInput required source="name" label="Nom" />
          <TextInput required source="entry_point" label="Url d'entrée" />
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default ProvidersEdit;
