import { Box, Typography } from "@mui/material";
import React from "react";
import {
  Edit,
  TextInput,
  SelectInput,
  required,
  Labeled,
  ReferenceField,
  TextField,
  useRecordContext,
  FunctionField,
} from "react-admin";
import { FormTab, TabbedForm } from "@react-admin/ra-rbac";
import PaymentMethodField from "../../components/fields/PaymentMethodField";
import PaymentsField from "../../components/fields/PaymentsField";
import PageTitle from "../../components/navigation/PageTitle";

const CartField = ({ props }: any) => {
  const { cart, totals } = useRecordContext();
  return (
    <Box>
      {cart.map((item: any) => {
        return (
          <Box display="flex" flexDirection="row" mb="1" key={item.product.name}>
            <Typography>&bull; &nbsp; {item.product.name}</Typography>
            <Typography>&nbsp; - {item.product.tarifs[item.tarifId].tarifDescription}</Typography>
            <Typography>&nbsp; - x{item.quantity}</Typography>
            <Typography>&nbsp; - {totals.totalByProduct[item.product.id].totalHt.toFixed(2)}€ HT</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const OrdersEdit = ({ ...props }) => {
  return (
    <Edit {...props}>
      <PageTitle text={(record: any) => `Commande ${record.id}`} />
      <TabbedForm>
        <FormTab label="Commande" name="order">
          <Labeled sx={{ mb: 2 }} label="Référence">
            <TextField source="id" />
          </Labeled>
          <Labeled sx={{ mb: 2 }} label="Organisation">
            <ReferenceField source="company_id" reference="companies">
              <TextField source="company" />
            </ReferenceField>
          </Labeled>
          <Labeled sx={{ mb: 2 }} label="Utilisateur">
            <ReferenceField source="user_id" reference="users">
              <TextField source="email" />
            </ReferenceField>
          </Labeled>
          <Labeled label="Montant">
            <FunctionField
              render={(record: any) =>
                `${Number(record.totals.totalHt).toFixed(2)}€ HT (${Number(record.totals.totalTtc).toFixed(2)}€ TTC)`
              }
              mb={"20px"}
            />
          </Labeled>
          <Labeled sx={{ mb: 2 }} label="Contenu commande">
            <CartField />
          </Labeled>
          <Labeled sx={{ mb: 2 }} label="Remise">
            <ReferenceField source="discount_id" reference="discounts">
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
          <Labeled sx={{ mb: 2 }} label="Statut commande">
            <TextField source="status" />
          </Labeled>
          <Labeled sx={{ mb: 2 }} label="Mode de paiement">
            <PaymentMethodField source="paymentMethod" component="edit" style={{ marginBottom: "20px" }} />
          </Labeled>
          <Labeled sx={{ mb: 2 }} label="Statut paiement">
            <TextField source="paymentStatus" />
          </Labeled>
        </FormTab>
        <FormTab
          label="Paiements"
          name="payments"
          sx={{
            "& .RaDatagrid-root": { width: 1 },
            "& .RaFileInput-root": { maxWidth: "300px" },
            "& .MuiTextField-root": { maxWidth: "300px" },
            "& .MuiInputBase-root": { width: 1 },
          }}
        >
          <PaymentsField target="order_id" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default OrdersEdit;
