import React from "react";
import {
  CreateButton,
  DateField,
  ReferenceField,
  Filter,
  TextField,
  TextInput,
  Toolbar,
  useGetIdentity,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
  useNotify,
  useListContext,
  BulkDeleteButton,
  useGetList,
} from "react-admin";
import { IfCanAccess, List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import stc from "string-to-color";
import CreateContactList from "./CreateContactList";
import SendSmsAction from "./SendSmsAction";
import { isEmailValid } from "../../services/common-utils/functions";
import ImportButtons from "../../components/importButtons/ImportButtons";

const BulkActionButtons = () => {
  return (
    <>
      <CreateContactList />
      <IfCanAccess action="delete">
        <BulkDeleteButton />
      </IfCanAccess>
    </>
  );
};

const headersMap: any = {
  Prénom: "firstname",
  Nom: "lastname",
  "Numéro de téléphone": "phonenumber",
  Email: "email",
};

const ListActions = ({ ...props }) => {
  const notify = useNotify();
  const { data: contacts, isLoading } = useGetList("contacts", {
    pagination: { page: 1, perPage: 10000 },
  });
  const config = {
    transformRows: (csvRows: any) => {
      // hack to change labels because resourceName cannot be customized in package
      let matches = document.querySelectorAll(".MuiPaper-root p, .MuiPaper-root h2");
      if(Object.keys(csvRows[0]).some((key: string) => {
        return !key || key ==="undefined";
      })) {
        notify("Erreur de formatage du fichier, merci de vérifier qu'il soit bien enregistré au format CSV UTF8", { type: "error", autoHideDuration: 20000 });
        return null;
      }
      for (let i = 0; i < matches.length; i++) {
        matches[i].innerHTML = matches[i].innerHTML.replace(/"contacts"/g, "<b>Contacts</b>");
      }
      const transformedRows = csvRows.map((row: any, index: number) => {
        const transformedRow: any = {
          group_id: props.company,
          phonenumber: row.phonenumber.trim(),
          firstname: row.firstname,
          lastname: row.lastname,
          email: row.email.trim().toLowerCase(),
          importLine: index + 1,
        };
        return transformedRow;
      });
      return transformedRows;
    },
    validateRow: async (csvRow: any) => {
      let error = "";
      if (csvRow.email && !isEmailValid(csvRow.email)) {
        error = csvRow.email + " n'est pas une adresse email valide";
      }
      if (!csvRow.firstname) {
        error = "Prénom manquant sur un des contacts";
      }
      if (!csvRow.lastname) {
        error = "Nom manquant sur un des contacts";
      }
      if (!csvRow.phonenumber) {
        error = "Numéro de téléphone manquant sur un des contacts";
      }
      if (contacts?.some((contact: any) => csvRow.phonenumber === contact.phonenumber)) {
        error = "Numéro de téléphone " + csvRow.phonenumber + " déjà existant";
      }
      if (error) {
        error = "Erreur ligne " + csvRow.importLine + " : " + error + " " + csvRow?.lastname;
        delete csvRow.importLine;
        notify(error, { type: "error", autoHideDuration: 20000 });
        return Promise.reject(new Error(error));
      } else {
        return Promise.resolve();
      }
    },
  };

  return (
    <Toolbar>
      <CreateButton label="Nouveau contact" />
      {props.company && !isLoading && (
        <ImportButtons
          config={config}
          headersMap={headersMap}
          xlsxTemplate={process.env.REACT_APP_TEMPLATE_IMPORT_CONTACTS_EXCEL}
          csvTemplate={process.env.REACT_APP_TEMPLATE_IMPORT_CONTACTS}
        />
      )}
    </Toolbar>
  );
};

const ContactsList = ({ ...props }) => {
  const { isLoading, identity } = useGetIdentity();

  return isLoading ? (
    <></>
  ) : (
    <List
      {...props}
      filter={
        props.group_id
          ? { group_id: props.group_id }
          : ["Client", "Indirect"].includes(identity?.status)
          ? { group_id: identity?.company }
          : {}
      }
      filters={
        <Filter {...props}>
          {!props.group_id && identity?.status === "Collaborateur" && (
            <ReferenceInput source="group_id" reference="companies" alwaysOn sort={{ field: "company", order: "ASC" }} perPage={1000}>
              <AutocompleteInput
                label="Rechercher par organisation"
                optionText="company"
                filterToQuery={(search: any) => ({ company: search })}
              />
            </ReferenceInput>
          )}
          <TextInput label="Rechercher par nom" source="fullName" alwaysOn />
        </Filter>
      }
      actions={<ListActions company={props.company || identity?.company} />}
      sort={{ field: "firstname", order: "ASC" }}
      exporter={false}
      empty={false}
    >
      <DigisoftDatagrid bulkActionButtons={<BulkActionButtons />}>
        <FunctionField
          label="Nom complet"
          render={(record: any) => {
            const formattedFirstName = record.firstname[0].toUpperCase() + record.firstname.slice(1);
            const formattedLastName = record.lastname.toUpperCase();
            return (
              <div style={{ display: "flex", flexDirection: "row", gap: "12px", alignItems: "center" }}>
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "999px",
                    backgroundColor: stc(`${formattedFirstName} ${formattedLastName}`),
                    color: "white",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  {formattedFirstName[0]}
                  {formattedLastName[0]}
                </div>
                <b>{`${formattedFirstName} ${formattedLastName}`}</b>
              </div>
            );
          }}
        />
        <TextField label="Numéro de téléphone" source="phonenumber" />
        <TextField label="Adresse email" source="email" />
        {!props.group_id && identity?.status === "Collaborateur" && (
          <ReferenceField label="Organisation" source="group_id" reference="companies" link={false}>
            <TextField source="company" />
          </ReferenceField>
        )}
        <DateField label="Mis à jour le" source="updatedAt" />
        {identity?.licenseId && (
          <FunctionField
            label="Envoyer SMS"
            render={(record: any) => {
              // const formattedFirstName = record.firstname[0].toUpperCase() + record.firstname.slice(1);
              // const formattedLastName = record.lastname.toUpperCase();
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SendSmsAction record={record} identity={identity} />
                </div>
              );
            }}
          />
        )}
      </DigisoftDatagrid>
    </List>
  );
};

export default ContactsList;
