import React, { useState } from "react";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AdminPanelSettings from "@mui/icons-material/AdminPanelSettings";
import BusinessIcon from "@mui/icons-material/Business";
import GroupIcon from "@mui/icons-material/Group";
import QrCodepIcon from "@mui/icons-material/QrCode";
import PaymentIcon from "@mui/icons-material/Payment";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CategoryIcon from "@mui/icons-material/Category";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import StarsIcon from "@mui/icons-material/Stars";
import SmsIcon from "@mui/icons-material/Sms";
import EuroIcon from "@mui/icons-material/Euro";
import { Menu } from "@react-admin/ra-rbac";
import { MultiLevelMenu } from "@react-admin/ra-navigation";
import MenuCategory from "./utils/MenuCategory";
import { useGetIdentity } from "react-admin";
import { Divider, useTheme } from "@mui/material";

// Digisoft CRM Sidebar component
const Sidebar = () => {
  const { data: identity } = useGetIdentity();
  const theme = useTheme();
  return (
    <Menu
      sx={{
        borderRight: "1px solid lightgray",
        height: "120%",
        paddingTop: "10px",
        bgcolor: "customBackground.main",
        fontWeight: "300",
      }}
    >
      <Menu.DashboardItem />
      <Divider sx={{ bgcolor: "neutral.main", mx: 2 }} />
      <Menu.Item
        // Required permissions
        action="read"
        resource="opportunities"
        // Item infos
        to="/opportunities"
        primaryText="Opportunités"
        leftIcon={<StarsIcon />}
      />

      {identity?.status !== "Collaborateur" && (
        <Menu.Item
          // Required permissions
          action="read"
          resource="companies"
          // Item infos
          to={"/companies/" + identity?.company}
          primaryText="Mon organisation"
          leftIcon={<BusinessIcon />}
        />
      )}

      {["Collaborateur", "Revendeur"].includes(identity?.status) && (
        <Menu.Item
          // Required permissions
          action="read"
          resource="companies"
          // Item infos
          to="/companies"
          primaryText="Clients"
          leftIcon={<PermIdentityIcon />}
          // hack to prevent companies menu item to be displayed as active when user's company item is active
          sx={{
            ...(window.location.pathname.includes(identity?.company) && {
              "&.RaMenuItemLink-active": {
                fontWeight: "300",
                backgroundColor: "inherit",
                color: theme.palette.mode === "light" ? "black" : "white",
                "& .RaMenuItemLink-icon": {
                  color: "grey",
                },
              },
            }),
          }}
        />
      )}

      {["Revendeur", "Client", "Indirect"].includes(identity?.status) && (
        <Menu.Item
          // Required permissions
          action="read"
          resource="users"
          // Item infos
          to="/users"
          primaryText="Utilisateurs"
          leftIcon={<GroupIcon />}
        />
      )}

      {["Client", "Indirect", "Revendeur"].includes(identity?.status) && (
        <Menu.Item
          // Required permissions
          action="read"
          resource="groups"
          // Item infos
          to="/groups"
          primaryText="Groupes"
          leftIcon={<GroupWorkIcon />}
        />
      )}

      {["Client", "Indirect", "Revendeur"].includes(identity?.status) && (
        <Menu.Item
          // Required permissions
          action="read"
          resource="contactsOrContactLists"
          // Item infos
          to="/contactsOrContactLists"
          primaryText="Contacts"
          leftIcon={<ContactPhoneIcon />}
        />
      )}

      <Menu.Item
        // Required permissions
        action="read"
        resource="subscriptions"
        // Item infos
        to="/subscriptions"
        primaryText="Abonnements"
        leftIcon={<PaymentIcon />}
      />

      <Menu.Item
        // Required permissions
        action="read"
        resource="licenses"
        // Item infos
        to="/licenses"
        primaryText="Licences"
        leftIcon={<ContactPageIcon />}
      />

      {["Client", "Indirect", "Revendeur"].includes(identity?.status) && (
        <Menu.Item
          // Required permissions
          action="read"
          resource="templates"
          // Item infos
          to="/templates"
          primaryText="Modèles SMS"
          leftIcon={<FormatAlignLeftIcon />}
        />
      )}

      {["Collaborateur", "Revendeur"].includes(identity?.status) && (
        <Menu.Item
          // Required permissions
          action="read"
          resource="credits"
          // Item infos
          to="/credits"
          primaryText="Crédits SMS"
          leftIcon={<SmsIcon />}
        />
      )}

      <Menu.Item
        // Required permissions
        action="read"
        resource="sms"
        // Item infos
        to="/sms"
        primaryText="Statistiques SMS"
        leftIcon={<ScheduleSendIcon />}
      />

      <Menu.Item
        // Required permissions
        action="read"
        resource="downloads"
        // Item infos
        to="/downloads"
        primaryText="Téléchargements"
        leftIcon={<DownloadForOfflineIcon />}
      />

      {["Client", "Indirect", "Revendeur"].includes(identity?.status) && (
        <Menu.Item
          // Required permissions
          action="read"
          resource="download"
          // Item infos
          to="/download"
          primaryText="Téléchargements"
          leftIcon={<DownloadForOfflineIcon />}
        />
      )}

      <Menu.Item
        // Required permissions
        action="read"
        resource="products"
        // Item infos
        to="/products"
        primaryText="Produits"
        leftIcon={<QrCodepIcon />}
      />

      <Menu.Item
        // Required permissions
        action="read"
        resource="discounts"
        // Item infos
        to="/discounts"
        primaryText="Remises"
        leftIcon={<LocalOfferIcon />}
      />

      <Divider sx={{ bgcolor: "neutral.main", mx: 2 }} />

      <Menu.Item
        // Required permissions
        action="read"
        resource="admin"
        // Item infos
        to="/admin/digisoft"
        primaryText="Administration"
        leftIcon={<AdminPanelSettings />}
      />

      <Menu.Item
        // Required permissions
        action="read"
        resource="orders"
        // Item infos
        to="/orders"
        primaryText="Commandes"
        leftIcon={<StorefrontIcon />}
      />

      {["Client", "Revendeur", "Indirect"].includes(identity?.status) && (
        <Menu.Item
          // Required permissions
          action="read"
          resource="shop"
          // Item infos
          to="/shop"
          primaryText="Boutique"
          leftIcon={<LocalOfferIcon />}
        />
      )}

      {["Client", "Revendeur"].includes(identity?.status) && (
        <Menu.Item
          // Required permissions
          action="read"
          resource="shop"
          // Item infos
          to="/checkout"
          primaryText="Panier"
          leftIcon={<LocalMallIcon />}
        />
      )}

      {/*<MenuCategory name="Catalogue" leftIcon={<PhotoLibraryIcon />} to="/products">


        <Menu.Item
          sx={{ background: '#EEEEEE' }}

          // Required permissions
          action="read"
          resource="attributes"

          // Item infos
          to="/attributes"
          primaryText="Attributs"
          leftIcon={<PhotoLibraryIcon />}
        />

        <Menu.Item
          sx={{ background: '#EEEEEE' }}

          // Required permissions
          action="read"
          resource="categories"

          // Item infos
          to="/categories"
          primaryText="Catégories"
          leftIcon={<CategoryIcon />}
        />


      </MenuCategory>*/}
    </Menu>
  );
};

export default Sidebar;
