import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";

// Firebase env (read from environement)
export const fbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Firebase services instances
export const fbApp = ObtainFirebaseApp(fbConfig);
export const fbAuth = getAuth(fbApp);
export const fbFirestore = getFirestore(fbApp);
export const fbStorage = getStorage(fbApp);

function ObtainFirebaseApp(firebaseConfig: {}) {
  const apps = getApps();

  const isInitialized = !!apps?.length;

  if (isInitialized) {
    return getApp();
  } else {
    return initializeApp(firebaseConfig);
  }
}