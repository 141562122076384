import AttributesList from './AttributesList';
import AttributesCreate from './AttributesCreate';
import AttributesEdit from './AttributesEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: AttributesList,
  create: AttributesCreate,
  edit: AttributesEdit
};
