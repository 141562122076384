import { Grid, Typography, InputAdornment } from "@mui/material";
import React from "react";
import {
  Edit,
  FormDataConsumer,
  Labeled,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceField,
  TextField,
  Toolbar,
  SaveButton,
  DeleteButton,
  useGetIdentity,
  useNotify,
  AutocompleteArrayInput,
} from "react-admin";
import { DualListInput } from "@react-admin/ra-relationships";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import AutocompleteDualListInput from "../../components/fields/AutocompleteDualListInput";
import PageTitle from "../../components/navigation/PageTitle";

const GroupsEdit = ({ ...props }) => {
  const { isLoading, data: identity } = useGetIdentity();
  const notify = useNotify();
  const redirect = useCustomListRedirect();

  // Transform override
  const onTransform = (data: any) => {
    delete data.itemToAdd;
    delete data.itemToRemove;
    return {
      ...data,
    };
  };

  const onSuccess = (data: any) => {
    notify(`Groupe modifié avec succès`, { type: "success" });
    redirect("groups");
  };

  return (
    <Edit {...props} transform={onTransform} mutationMode="optimistic" mutationOptions={{ onSuccess }} hasShow={false}>
      <SimpleForm>
        <PageTitle text={(record: any) => record.name} />
        <TextInput required label="Nom du groupe" source="name" />
        {["Collaborateur", "Revendeur"].includes(identity?.status) && (
          <ReferenceField source="company" reference="companies">
            <Labeled label="Organisation">
              <TextField source="company" mb={"20px"} />
            </Labeled>
          </ReferenceField>
        )}
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceArrayInput
              reference="users"
              source="users"
              filter={{
                company: ["Collaborateur", "Revendeur"].includes(identity?.status)
                  ? formData.company
                  : identity?.company,
                crmGroupId: "none",
              }}
              perPage={1000}
              sort={{ field: "email", order: "ASC" }}
            >
              <AutocompleteDualListInput label="Utilisateurs" optionText="email" queryField="email" source="users" />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default GroupsEdit;
