import React, { useCallback } from "react";
import {
  CreateButton,
  DateField,
  Filter,
  FunctionField,
  Pagination,
  ReferenceField,
  TextField,
  TextInput,
  Toolbar,
  useGetIdentity,
  useListContext,
  Count,
  SelectInput,
} from "react-admin";
import { IfCanAccess, List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import { ICompany } from "./ICompany";
import { Divider, Tabs, Tab, Typography } from "@mui/material";
import { COMPANIES_STATUSES } from "../../services/common-utils/constants";
import CountryField from "../../components/fields/CountryField";
import PageTitle from "../../components/navigation/PageTitle";
import { query, where } from "firebase/firestore";

const CompaniesPagination = (props: any) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const CompaniesList = ({ ...props }) => {
  //Only "Collaborateur" and "Revendeur" have access to this page
  const { isLoading, data: identity } = useGetIdentity();

  return isLoading ? (
    <></>
  ) : (
    <>
      <PageTitle text="Clients" backButton={false} />
      <List
        filter={
          identity?.status === "Collaborateur"
            ? {}
            : {
                collectionQuery: (c: any) =>
                  query(c, where("id", "!=", identity?.company), where("parentCompany", "==", identity?.company)),
              }
        }
        sort={{ field: "company", order: "ASC" }}
        filters={
          <Filter {...props}>
            <TextInput label="Rechercher" source="company" alwaysOn />
            {identity?.status === "Collaborateur" && (
              <SelectInput
                source="status"
                label="Statut"
                choices={COMPANIES_STATUSES.map((s) => ({ id: s, name: s }))}
                alwaysOn
              />
            )}
            {identity?.status === "Collaborateur" && (
              <SelectInput
                source="prospect"
                label="Prospect"
                emptyText={"Non"}
                emptyValue={false}
                choices={[{ id: true, name: "Oui" }]}
                alwaysOn
              />
            )}
          </Filter>
        }
        pagination={<CompaniesPagination />}
        {...props}
        actions={
          <Toolbar>
            <CreateButton label="Nouveau Client" />
          </Toolbar>
        }
        empty={false}
        exporter={false}
      >
        <DigisoftDatagrid bulkActionButtons={false}>
          <TextField source="company" label="Nom" />
          <TextField source="type" label="Nature" />
          <TextField source="sector" label="Activité" />
          <CountryField source="country" label="Pays" />
          {identity?.status === "Collaborateur" && (
            <FunctionField
              label="Statut"
              render={(record: ICompany) => {
                const statusColor =
                  record.status === "Client"
                    ? "#58A14E"
                    : record.status === "Revendeur"
                    ? "#3C88ec"
                    : record.status === "Indirect"
                    ? "#593cec"
                    : "#58A14E";
                return (
                  <div
                    style={{
                      background: statusColor,
                      width: "fit-content",
                      color: "white",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                      borderRadius: "6px",
                      fontWeight: "bold",
                    }}
                  >
                    {record.status}
                  </div>
                );
              }}
            />
          )}

          <DateField label="Modifié le" source="lastupdate" showTime={true} />
        </DigisoftDatagrid>
      </List>
    </>
  );
};

export default CompaniesList;
