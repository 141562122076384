import React, { Fragment, useCallback } from "react";
import {
  CreateButton,
  TextField,
  Toolbar,
  ReferenceField,
  FunctionField,
  DateField,
  useListContext,
  Count,
  Filter,
  TextInput,
  SelectInput,
} from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import { Divider, Tab, Tabs } from "@mui/material";
import CountryField from "../../components/fields/CountryField";
import { OPPORTUNITIES_STATUSES } from "../../services/common-utils/constants";
import PageTitle from "../../components/navigation/PageTitle";

const OpportunitiesList = ({ ...props }) => {
  return (
    <>
      <PageTitle text="Opportunités" backButton={false} />
      <List
        {...props}
        actions={
          <Toolbar>
            <CreateButton label="Ajouter une opportunité" />
          </Toolbar>
        }
        filters={
          <Filter {...props}>
            <TextInput label="Organisation" source="company" alwaysOn />
            <SelectInput
              source="status"
              label="Statut"
              choices={OPPORTUNITIES_STATUSES.map((s) => ({ id: s, name: s }))}
              alwaysOn
            />
          </Filter>
        }
        exporter={false}
        empty={false}
        sort={{ field: "company", order: "ASC" }}
      >
        <DigisoftDatagrid>
          <TextField source="company" label="Organisation" />
          <CountryField source="country" label="Pays" />
          <TextField label="Contact principal" source="mainContact" />
          <TextField label="Téléphone" source="phone" />
          <TextField label="Email" source="email" />
          <TextField label="Etape" source="step" />
          <TextField label="Statut" source="status" />
          <FunctionField
            label="Statut"
            render={(record: any) => {
              const statusColor =
                record.status === "En cours"
                  ? "#a7a400"
                  : record.status === "Version d'essai activée"
                  ? "#3C88ec"
                  : record.status === "Gagné"
                  ? "#0ea700"
                  : record.status === "Perdu"
                  ? "#cc412e"
                  : "#818181";
              return (
                <div
                  style={{
                    background: statusColor,
                    width: "fit-content",
                    color: "white",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    borderRadius: "6px",
                    fontWeight: "400",
                  }}
                >
                  {record.status}
                </div>
              );
            }}
          />
          <DateField label="Mis à jour le" source="lastupdate" showTime />
        </DigisoftDatagrid>
      </List>
    </>
  );
};

export default OpportunitiesList;
