import { LoadingButton } from "@mui/lab";
import { Box, Typography, Stack, Button, FormGroup, FormControlLabel, Checkbox, useTheme } from "@mui/material";
import React, { useState } from "react";
import PromoCode from "./PromoCode";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";

const CheckoutResume = ({
  totals,
  discount,
  setDiscount,
  cart,
  setCart,
  checkoutStep,
  handleStep,
  identity,
  paymentFormSubmitting,
  paymentErrorMessage,
}: any) => {
  const [conditionsAccepted, setConditionsAccepted] = useState(false);
  const theme = useTheme();
  return (
    <Stack sx={{ mt: [1, 10], display: "flex", alignItems: "center", MuiButtonBase: { width: 1 } }}>
      <Typography variant="h5" sx={{ mb: "0px" }}>
        Récapitulatif
      </Typography>

      <div style={{ backgroundColor: "grey", height: "1px", width: "80%", marginBottom: "15px" }} />

      <Box
        display="flex"
        flexDirection="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        gap="12px"
        sx={{ border: "1px solid lightgrey", width: "100%", padding: "5px 10px" }}
      >
        <Typography variant="body1">Total HT</Typography>

        <Typography variant="body1">{totals.withoutDiscount.totalHt.toFixed(2)} &euro;</Typography>
      </Box>

      {Object.keys(discount).length > 0 && totals.savings.totalHt > 0 && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems={"center"}
          justifyContent={"space-between"}
          gap="12px"
          sx={{ border: "1px solid lightgrey", width: "100%", padding: "5px 10px", color: "green" }}
        >
          <Typography variant="body1" maxWidth={0.75}>
            {discount.applicationType === "Code promo" ? "Code " + discount.promoCode : discount.name}{" "}
            {discount.type === "Pourcentage" && ` (-${discount.rate}%)`}
          </Typography>

          <Typography variant="body1">-{totals.savings.totalHt.toFixed(2)} &euro;</Typography>
        </Box>
      )}

      {Object.keys(discount).length > 0 && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems={"center"}
          justifyContent={"space-between"}
          gap="12px"
          sx={{ border: "1px solid lightgrey", width: "100%", padding: "5px 10px" }}
        >
          <Typography variant="body1">Total HT après remise</Typography>

          <Typography variant="body1">{totals.totalHt.toFixed(2)} &euro;</Typography>
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        gap="12px"
        sx={{ mt: "-1px", border: "1px solid lightgrey", width: "100%", padding: "5px 10px" }}
      >
        <Typography variant="body1">Taxes (20%)</Typography>

        <Typography variant="body1">
          {totals.taxes.total !== 0 ? totals.taxes.total.toFixed(2) + " €" : "Non applicable"}
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        gap="12px"
        sx={{
          mt: "-1px",
          border: "1px solid lightgrey",
          width: "100%",
          padding: "5px 10px",
          backgroundColor: theme.palette.mode === "light" ? "white" : "inherit",
          color: theme.palette.mode === "light" ? "black" : "white",
        }}
      >
        <Typography variant="h6">Total TTC</Typography>

        <Typography variant="h6">
          <b>{totals.totalTtc.toFixed(2)} &euro;</b>
        </Typography>
      </Box>
      {checkoutStep <= 1 && (
        <PromoCode discount={discount} setDiscount={setDiscount} cart={cart} setCart={setCart} identity={identity} />
      )}

      {checkoutStep === 0 && (
        <Button
          sx={{ color: "white", mt: 1, width: 1 }}
          color="primary"
          variant="contained"
          size="large"
          onClick={() => handleStep(1)}
          disabled={cart.length === 0}
        >
          Suivant
        </Button>
      )}
      {checkoutStep === 1 && (
        <Button
          sx={{ color: "white", mt: 1, width: 1 }}
          form="customer-infos-form"
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          disabled={cart.length === 0}
        >
          Suivant
        </Button>
      )}
      {checkoutStep === 2 && (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={<CheckCircle />}
                  color="info"
                  checked={conditionsAccepted}
                  onClick={() => {
                    setConditionsAccepted(!conditionsAccepted);
                  }}
                />
              }
              label={<Typography>J’accepte les conditions générales de ventes</Typography>}
            />
          </FormGroup>
          {paymentErrorMessage && (
            <Typography color="error" textAlign="center">
              {paymentErrorMessage}
            </Typography>
          )}
          <LoadingButton
            sx={{ color: "white", mt: 1, width: 1 }}
            form="payment-form"
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            disabled={cart.length === 0 || !conditionsAccepted}
            loading={paymentFormSubmitting}
          >
            Payer maintenant
          </LoadingButton>
        </>
      )}
      {checkoutStep > 0 && (
        <Button variant="outlined" sx={{ mt: 2, width: 1 }} size="large" onClick={() => handleStep(-1)}>
          Retour
        </Button>
      )}
    </Stack>
  );
};

export default CheckoutResume;
