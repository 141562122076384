import { FC, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextField, TextFieldProps, MenuItem, InputAdornment, IconButton } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import React from "react";

// Styled Material UI TextField Component
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#5e5b5d",
    fontWeight: 400,
  },
  "& .MuiInputBase-input": {
    borderColor: "#c8d0d4",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#d32f2f",
      },
    },
    "& fieldset": {
      borderColor: "#c8d0d4",
      borderRadius: "2px",
    },
    "&:hover fieldset": {
      border: "1px solid #c8d0d4",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #c8d0d4",
    },
  },
});

// Type of Props the FormInput will receive
type FormInputProps = {
  name: string;
  values?: any;
  password?: boolean;
} & TextFieldProps;

const FormInput: FC<FormInputProps> = ({ name, values, defaultValue, password = false, ...otherProps }: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  // Utilizing useFormContext to have access to the form Context
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const franceFlag = require(`../../assets/france.svg`).default as string;
  const belgiumFlag = require(`../../assets/belgium.svg`).default as string;
  const swissFlag = require(`../../assets/swiss.svg`).default as string;
  const canadaFlag = require(`../../assets/canada.svg`).default as string;
  const usaFlag = require(`../../assets/united-states.svg`).default as string;
  const visa = require("../../assets/visa.png") as string;
  const mastercard = require("../../assets/mastercard.png") as string;
  const amex = require("../../assets/amex.png") as string;
  const sepa = require("../../assets/sepa.png") as string;

  if (values) {
    values = values?.map((value: any) => {
      if (typeof value === "object" && !Array.isArray(value)) {
        return value;
      } else {
        return {
          id: value,
          label: value,
          cardType: value?.cardType || "",
          expMonth: value?.expMonth || "",
          expYear: value?.expYear || "",
        };
      }
    });
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field } }) => (
        <CssTextField
          variant="outlined"
          sx={{ mb: "1.5rem" }}
          error={!!errors[name]}
          type={!password || showPassword ? "text" : "password"}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: !password ? null : (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={errors[name] ? (errors[name]?.message as unknown as string) : ""}
          inputRef={ref}
          {...field}
          {...otherProps}
        >
          {values &&
            values.map((value: any) => (
              <MenuItem value={value.id} key={value.id}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  {name === "country" &&
                    (value.id === "France" ? (
                      <img src={franceFlag} alt={value.label} width="26" height="26" style={{ marginRight: "12px" }} />
                    ) : value.id === "Belgique" ? (
                      <img src={belgiumFlag} alt={value.label} width="26" height="26" style={{ marginRight: "12px" }} />
                    ) : value.id === "Suisse" ? (
                      <img src={swissFlag} alt={value.label} width="26" height="26" style={{ marginRight: "12px" }} />
                    ) : value.id === "Canada" ? (
                      <img src={canadaFlag} alt={value.label} width="26" height="26" style={{ marginRight: "12px" }} />
                    ) : value.id === "Etats-Unis" ? (
                      <img src={usaFlag} alt={value.label} width="26" height="26" style={{ marginRight: "12px" }} />
                    ) : value.id === "Guadeloupe" ? (
                      <img src={franceFlag} alt={value.label} width="26" height="26" style={{ marginRight: "12px" }} />
                    ) : value.id === "Saint-Barthelemy" ? (
                      <img src={franceFlag} alt={value.label} width="26" height="26" style={{ marginRight: "12px" }} />
                    ) : null)}
                  {name === "card" &&
                    (value.cardType === "visa" ? (
                      <img src={visa} alt={value.label} height="26" style={{ marginRight: "12px" }} />
                    ) : value.cardType === "mastercard" ? (
                      <img src={mastercard} alt={value.label} height="26" style={{ marginRight: "12px" }} />
                    ) : value.cardType === "amex" ? (
                      <img src={amex} alt={value.label} height="26" style={{ marginRight: "12px" }} />
                    ) : (
                      <img src={visa} alt={value.label} height="26" style={{ marginRight: "12px" }} />
                    ))}
                  {name === "iban" && <img src={sepa} alt={value.label} height="26" style={{ marginRight: "12px" }} />}
                  {value.label}
                  {name === "card" && value.id !== "none" && (
                    <div style={{ position: "absolute", right: "30px" }}>
                      Exp : {value.expMonth}/{value.expYear}
                    </div>
                  )}
                </div>
              </MenuItem>
            ))}
        </CssTextField>
      )}
    />
  );
};

export default FormInput;
