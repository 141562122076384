import React, { useCallback } from "react";
import { Tabs, Tab, Divider } from "@mui/material";
import {
  Filter,
  ReferenceInput,
  AutocompleteInput,
  DateField,
  TextField,
  FunctionField,
  useListContext,
  Count,
  useGetIdentity,
  TextInput,
  ReferenceField,
  SelectInput,
  FormDataConsumer,
  DateInput,
  minValue,
  maxValue,
} from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import SmsStats from "./SmsStats";
import PageTitle from "../../components/navigation/PageTitle";

const SmsList = ({ ...props }) => {
  const { isLoading, identity } = useGetIdentity();

  return isLoading ? null : (
    <>
      <PageTitle text="Envois SMS" backButton={false} />
      <List
        {...props}
        exporter={false}
        empty={false}
        filter={
          props.group_id
            ? { group_id: props.group_id }
            : ["Client", "Indirect"].includes(identity?.status)
            ? { group_id: identity?.company }
            : {}
        }
        filters={
          <Filter {...props}>
            <SelectInput
              source="viewType"
              label="Type de vue"
              alwaysOn
              emptyText={"Statistiques"}
              emptyValue={"statistics"}
              choices={[{ id: "historic", name: "Historique" }]}
            />
            {!props.group_id && ["Collaborateur", "Revendeur"].includes(identity?.status) && (
              <ReferenceInput
                source="group_id"
                reference="companies"
                alwaysOn
                filter={identity?.status === "Revendeur" ? { parentCompany: identity?.company } : {}}
                sort={{ field: "company", order: "ASC" }}
                perPage={1000}
              >
                <AutocompleteInput
                  label="Organisation"
                  optionText="company"
                  filterToQuery={(search: any) => ({ company: search })}
                />
              </ReferenceInput>
            )}
            <FormDataConsumer alwaysOn>
              {({ formData, ...rest }) => {
                const group_id = props.group_id || formData.group_id || identity?.company || "";
                return !group_id ? null : (
                  <SelectInput
                    source="filterType"
                    label="Filtrer sur"
                    emptyText={"Aucun filtre"}
                    alwaysOn
                    choices={[
                      { id: "crmGroup", name: "Groupe spécifique" },
                      { id: "allCrmGroups", name: "Tous les groupes" },
                      { id: "user", name: "Utilisateur" },
                    ]}
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer alwaysOn>
              {({ formData, ...rest }) => {
                const group_id = props.group_id || formData.group_id || identity?.company || "";
                return !(formData.filterType === "crmGroup" && group_id) ? null : (
                  <ReferenceInput
                    source="crmGroupId"
                    reference="groups"
                    alwaysOn
                    filter={{ company: group_id }}
                    sort={{ field: "name", order: "ASC" }}
                    perPage={1000}
                  >
                    <AutocompleteInput
                      label="Rechercher par groupe"
                      optionText="name"
                      filterToQuery={(search: any) => ({ name: search })}
                    />
                  </ReferenceInput>
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer alwaysOn>
              {({ formData, ...rest }) => {
                const group_id = props.group_id || formData.group_id || identity?.company || "";
                return !(formData.filterType === "user" && group_id) ? null : (
                  <ReferenceInput
                    source="userId"
                    reference="users"
                    alwaysOn
                    filter={{ company: group_id }}
                    sort={{ field: "email", order: "ASC" }}
                    perPage={1000}
                  >
                    <AutocompleteInput
                      label="Rechercher par utilisateur"
                      optionText="email"
                      filterToQuery={(search: any) => ({ email: search })}
                    />
                  </ReferenceInput>
                );
              }}
            </FormDataConsumer>
            <SelectInput
              source="period"
              label="Période"
              alwaysOn
              emptyText={"Mois en cours"}
              emptyValue={"currentMonth"}
              choices={[
                { id: "lastMonth", name: "Mois précédent" },
                { id: "currentYear", name: "Année en cours" },
                { id: "lastYear", name: "Année précédente" },
                { id: "custom", name: "Personnalisée" },
              ]}
            />
            <FormDataConsumer alwaysOn>
              {({ formData, ...rest }) => {
                return formData.period !== "custom" ? null : (
                  <DateInput
                    source="startDate"
                    label="Date début"
                    parse={(value: any) => {
                      if (value > formData.endDate) {
                        return formData.endDate;
                      } else {
                        return value > new Date().toISOString().split("T")[0]
                          ? new Date().toISOString().split("T")[0]
                          : value;
                      }
                    }}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer alwaysOn>
              {({ formData, ...rest }) => {
                return formData.period !== "custom" ? null : (
                  <DateInput
                    source="endDate"
                    label="Date fin"
                    parse={(value: any) => {
                      if (value < formData.startDate) {
                        return formData.startDate;
                      } else {
                        return value > new Date().toISOString().split("T")[0]
                          ? new Date().toISOString().split("T")[0]
                          : value;
                      }
                    }}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer alwaysOn>
              {({ formData, ...rest }) => {
                return formData.viewType !== "statistics" ? null : (
                  <SelectInput
                    source="countType"
                    label="Comptage par"
                    emptyText={"Crédits consommés"}
                    emptyValue={"credits"}
                    choices={[{ id: "SMS", name: "SMS envoyés" }]}
                  />
                );
              }}
            </FormDataConsumer>
          </Filter>
        }
        sort={{ field: "createdAt", order: "DESC" }}
        filterDefaultValues={{
          tabFilter: "sent",
          period: "currentMonth",
          viewType: "statistics",
          countType: "credits",
        }}
      >
        <>
          <SmsStats groupId={props.group_id} />
          <TabbedDatagrid
            group_id={props.group_id}
            identity={identity}
            permanentFilter={
              props.group_id
                ? { group_id: props.group_id }
                : ["Client", "Indirect"].includes(identity?.status)
                ? { group_id: identity?.company }
                : {}
            }
          />
        </>
      </List>
    </>
  );
};

const tabs = [
  { id: "sent", name: "Envoyés" },
  { id: "notSent", name: "Programmés" },
];

const TabbedDatagrid = ({ permanentFilter, group_id, identity }: any) => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;
  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters(
          { ...filterValues, tabFilter: value },
          displayedFilters,
          false // no debounce, we want the filter to fire immediately
        );
    },
    [displayedFilters, filterValues, setFilters]
  );

  return filterValues?.viewType !== "historic" ? null : (
    <>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.tabFilter}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => {
          return (
            <Tab
              key={choice.id}
              label={
                <span>
                  {choice.name} (
                  <Count
                    filter={{
                      ...filterValues,
                      ...permanentFilter,
                      tabFilter: choice.id,
                    }}
                    sx={{ lineHeight: "inherit" }}
                  />
                  )
                </span>
              }
              value={choice.id}
            />
          );
        })}
      </Tabs>
      <Divider />
      <DigisoftDatagrid
        bulkActionButtons={identity?.status === "Collaborateur" && window.location.href.includes("notSent")}
      >
        <DateField label="Date d'envoi" source="send_date" showTime />

        <ReferenceField label="Utilisateur" source="userId" reference="users" link={false}>
          <TextField source="email" />
        </ReferenceField>
        {!group_id && (
          <ReferenceField label="Organisation" source="group_id" reference="companies" link={false}>
            <TextField source="company" />
          </ReferenceField>
        )}
        <ReferenceField source="crmGroupId" label="Groupe" reference="groups" link={false}>
          <FunctionField
            render={(groupRecord: any) => {
              return <div>{groupRecord.name}</div>;
            }}
          />
        </ReferenceField>
        <TextField label="Destinataire" source="phonenumber" />
        <FunctionField
          label="Type"
          render={(record: any) => {
            return <div> {record.type === "reminder" ? "rappel" : "confirmation"}</div>;
          }}
        />
        <FunctionField
          label="Envoyé"
          render={(record: any) => {
            const statusColor = record.sent ? "#58A14E" : "#ff8800";
            return (
              <div
                style={{
                  background: statusColor,
                  width: "fit-content",
                  color: "white",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  borderRadius: "6px",
                  fontWeight: "bold",
                }}
              >
                {record.sent ? "oui" : "non"}
              </div>
            );
          }}
        />
        <TextField label="Coût (crédits)" source="length" />
      </DigisoftDatagrid>
    </>
  );
};

export default SmsList;
