import React from "react";
import {
  CreateButton,
  TextField,
  Toolbar,
  ReferenceField,
  AutocompleteInput,
  Filter,
  ReferenceInput,
  useGetIdentity,
  FunctionField,
  DateField,
  TextInput,
} from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import { Typography } from "@mui/material";
import PaymentMethodField from "../../components/fields/PaymentMethodField";
import PageTitle from "../../components/navigation/PageTitle";
import { query, where } from "firebase/firestore";

const statusToColor: any = {
  Complète: "success.light",
  "En cours de paiement": "warning.main",
  "En cours de traitement": "warning.main",
  Panier: "neutral.main",
  "Erreur de paiement": "error.main",
};

const paymentStatusToColor: any = {
  Succès: "success.light",
  "En cours": "warning.main",
  "En attente": "neutral.main",
  Erreur: "error.main",
  Litige: "error.main",
};

const OrdersList = ({ ...props }) => {
  const { isLoading, data: identity } = useGetIdentity();
  return isLoading ? null : (
    <>
      <PageTitle text="Commandes" backButton={false} />
      <List
        {...props}
        filter={
          props.company
            ? { company_id: props.company }
            : identity?.status !== "Collaborateur"
            ? { company_id: identity?.company, collectionQuery: (c: any) => query(c, where("status", "!=", "Panier")) }
            : {}
        }
        filters={
          <Filter {...props}>
            {identity?.status === "Collaborateur" && !props.company && (
              <ReferenceInput
                source="company_id"
                reference="companies"
                alwaysOn
                sort={{ field: "company", order: "ASC" }}
                perPage={1000}
              >
                <AutocompleteInput
                  label="Rechercher par organisation"
                  optionText="company"
                  filterToQuery={(search: any) => ({ company: search })}
                />
              </ReferenceInput>
            )}
            <TextInput label="Numéro de commande" source="id" alwaysOn />
          </Filter>
        }
        exporter={false}
        empty={false}
        sort={{ field: "createdate", order: "DESC" }}
      >
        <DigisoftDatagrid bulkActionButtons={identity?.status === "Collaborateur"}>
          <DateField label="Créé le" source="createdate" showTime />
          <TextField label="Référence" source="id" />
          {identity?.status === "Collaborateur" && (
            <ReferenceField label="Organisation" source="company_id" reference="companies" link={false}>
              <TextField source="company" />
            </ReferenceField>
          )}
          <ReferenceField label="Utilisateur" source="user_id" reference="users" link={false}>
            <TextField source="email" />
          </ReferenceField>
          <FunctionField
            label="Montant HT"
            render={(record: any) => {
              return <div> {record.totals.totalHt.toFixed(2)} €</div>;
            }}
          />
          <PaymentMethodField source="paymentMethod" label="Mode de paiement" />
          <FunctionField
            label="Statut paiement"
            render={({ paymentStatus }: any) => {
              return (
                <Typography
                  sx={{
                    color: "white",
                    bgcolor: paymentStatusToColor[paymentStatus],
                    px: 1,
                    py: 0.5,
                    borderRadius: 2,
                    width: "fit-content",
                    fontSize: "0.9rem",
                    opacity: 0.8,
                  }}
                >
                  {paymentStatus}
                </Typography>
              );
            }}
          />
          <FunctionField
            label="Statut commande"
            render={({ status }: any) => {
              return (
                <Typography
                  sx={{
                    color: "white",
                    bgcolor: statusToColor[status],
                    px: 1,
                    py: 0.5,
                    borderRadius: 2,
                    width: "fit-content",
                    fontSize: "0.9rem",
                    opacity: 0.8,
                  }}
                >
                  {status}
                </Typography>
              );
            }}
          />
        </DigisoftDatagrid>
      </List>
    </>
  );
};

export default OrdersList;
