import React, { useEffect } from "react";
import { Layout as RALayout, useGetIdentity } from "react-admin";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useLocalStorage } from "usehooks-ts";
import api from "../../services/apiWithAuth";

// React-Admin layout object
const Layout = (props: any) => {
  const [discount, setDiscount] = useLocalStorage("discount", ({} as any) || null);
  const { isLoading, identity } = useGetIdentity();
  useEffect(() => {
    if (discount && !Object.keys(discount).length && !isLoading && identity?.status !== "Collaborateur") {
      const asyncCall = async () => {
        try {
          const { data } = await api.post("/utils/getDiscount", { promoCode: "auto" });
          if (data) {
            setDiscount(data);
          }
        } catch (err) {
          console.log("no auto discount found");
        }
      };
      asyncCall();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount]);

  return <RALayout {...props} menu={Sidebar} appBar={Topbar} />;
};

export default Layout;
