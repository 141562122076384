import React from "react";
import { CreateButton, DateField, Filter, TextField, TextInput, Toolbar, FunctionField } from "react-admin";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import { List } from "@react-admin/ra-rbac";
import PageTitle from "../../components/navigation/PageTitle";

const ProductsList = ({ ...props }) => {
  return (
    <>
      <PageTitle text="Remises" backButton={false} />
      <List
        {...props}
        filters={
          <Filter {...props}>
            <TextInput label="Rechercher" source="name" alwaysOn />
          </Filter>
        }
        actions={
          <Toolbar>
            <CreateButton label="Nouvelle remise" />
          </Toolbar>
        }
        exporter={false}
        sort={{ field: "name", order: "ASC" }}
      >
        <DigisoftDatagrid>
          <TextField source="name" label="Nom" />
          <DateField source="startDate" label="Débute le" />
          <FunctionField
            label="Termine le"
            render={(record: any) => {
              return <span>{record.expire && record.endDate}</span>;
            }}
          />
          <TextField source="applicationType" label="Type de remise" />
          <TextField source="type" label="Type" />
        </DigisoftDatagrid>
      </List>
    </>
  );
};

export default ProductsList;
