import ContactsList from "../contacts/ContactsList";
import ContactListsList from "../contactLists/ContactListsList";
import React, { useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const ContactsOrContactLists = ({ ...props }) => {
  const [viewType, setViewType] = useState("contacts");

  const handleViewTypeChange = (event: React.MouseEvent<HTMLElement>, newViewType: string) => {
    setViewType(newViewType);
  };
  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={viewType}
        exclusive
        onChange={handleViewTypeChange}
        aria-label="Platform"
        sx={{
          mt: [0, 2],
          mb: 2,
          width: 1,
        }}
      >
        <ToggleButton
          value="contacts"
          sx={{
            width: 0.5,
            fontSize: "1rem",
            "&.Mui-selected, &.Mui-selected:hover": {
              color: "white",
              bgcolor: "primary.main",
            },
          }}
        >
          Contacts
        </ToggleButton>
        <ToggleButton
          value="contactLists"
          sx={{
            width: 0.5,
            fontSize: "1rem",
            "&.Mui-selected, &.Mui-selected:hover": {
              color: "white",
              bgcolor: "primary.main",
            },
          }}
        >
          Listes de contacts
        </ToggleButton>
      </ToggleButtonGroup>
      {viewType === "contacts" ? <ContactsList {...props} resource="contacts" /> : <ContactListsList {...props} resource="contactLists"/>}
    </>
  );
};

export default ContactsOrContactLists;