import { Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  TextInput,
  SaveButton,
  Toolbar,
  DeleteWithConfirmButton,
  SelectInput,
  ReferenceInput,
  required,
  FormDataConsumer,
  FunctionField,
  AutocompleteInput,
  useRecordContext,
  useGetIdentity,
  TextField,
  Labeled,
  ReferenceField,
  DateField,
  TabbedFormTabs,
  DateInput,
} from "react-admin";
import { Edit, IfCanAccess, TabbedForm, FormTab } from "@react-admin/ra-rbac";
import {
  COUNTRIES,
  COMPANIES_STATUSES,
  COMPANIES_SOURCES,
  COMPANIES_ACCOUNT_STATUSES,
  TYPES,
  COMPANIES_SECTORS,
} from "../../services/common-utils/constants";
import UsersList from "../users/UsersList";
import GroupsList from "../groups/GroupsList";
import SubscriptionsList from "../subscriptions/SubscriptionsList";
import LicensesList from "../licenses/LicensesList";
import TemplatesList from "../templates/TemplatesList";
import SmsList from "../sms/SmsList";
import PaymentMethodsList from "../paymentMethods/PaymentMethodsList";
import ContactsOrContactLists from "../contactsOrContactLists/ContactsOrContactLists";
import PageTitle from "../../components/navigation/PageTitle";

const CompaniesToolBar = ({ ...props }) => {
  const record = useRecordContext();
  // split length === 3 stuff is a hack to allow toolbar for main path (edit path), because if we put path on each formtab no default tab
  return !(
    /address|account/.test(window.location.pathname) || window.location.pathname.split("/").length === 3
  ) ? null : (
    <Toolbar>
      <SaveButton label="Sauvegarder" />
      <div style={{ flex: 1 }} />
      <IfCanAccess action="delete" record={record.id}>
        {window.location.pathname.includes("account") && (
          <DeleteWithConfirmButton
            disabled={props.identity?.role !== "Super Admin"}
            confirmTitle={`Suppression de l'organisation ${record.company.toUpperCase()}`}
            confirmContent="Êtes-vous sur de vouloir supprimer cette organisation? Toutes les données rattachées seront supprimées."
          />
        )}
      </IfCanAccess>
    </Toolbar>
  );
};

const resourceTabs = [
  { resource: "users", label: "Utilisateurs", Component: ({ ...props }) => <UsersList {...props} /> },
  { resource: "groups", label: "Groupes", Component: ({ ...props }) => <GroupsList {...props} /> },
  { resource: "subscriptions", label: "Abonnements", Component: ({ ...props }) => <SubscriptionsList {...props} /> },
  { resource: "licenses", label: "Licences", Component: ({ ...props }) => <LicensesList {...props} /> },
  { resource: "templates", label: "Modèles", Component: ({ ...props }) => <TemplatesList {...props} /> },
  {
    resource: "contactsOrContactLists",
    label: "Contacts",
    Component: ({ ...props }) => <ContactsOrContactLists {...props} />,
  },
  { resource: "sms", label: "Statistiques SMS", Component: ({ ...props }) => <SmsList {...props} /> },
];

const CompanyTitle = ({ identity }: any) => {
  const record = useRecordContext();
  return <PageTitle
    text={record.company}
    backButton={
      identity?.status === "Collaborateur" || (identity?.status === "Revendeur" && record.id !== identity?.company)
    }
    backUrl="/companies"
  />;
};

const CompaniesEdit = ({ ...props }) => {
  const { data: identity, isLoading } = useGetIdentity();

  const onTransform = (data: any) => {
    if (!data.accountManager) {
      delete data.accountManager;
    }
    return {
      ...data,
      country: data?.address?.country || data?.country || null,
    };
  };

  const isSelected = (tab: string) => window.location.pathname.includes(tab);

  return isLoading ? null : (
    <Edit {...props} transform={onTransform} hasShow={false}>
      <CompanyTitle identity={identity} />
      <TabbedForm
        tabs={<TabbedFormTabs indicatorColor="primary" />}
        toolbar={<CompaniesToolBar identity={identity} />}
        sx={{ "& .MuiTabs-root": { "& .MuiTypography-root": { fontSize: "0.9rem" } } }}
      >
        <FormTab
          label={
            <Typography sx={[window.location.pathname.split("/").length === 3 && { color: "primary.main" }]}>
              Organisation
            </Typography>
          }
          name="company"
        >
          <Typography variant="h6" mb={"20px"}>
            Informations générales
          </Typography>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              identity?.status === "Collaborateur" ||
              (identity?.status === "Revendeur" && formData.id !== identity?.company) ? (
                <TextInput defaultValue={null} source="company" label="Nom de l'organisation" />
              ) : (
                <Labeled label="Nom de l'organisation">
                  <TextField source="company" mb={2} />
                </Labeled>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              identity?.status === "Collaborateur" ||
              (identity?.status === "Revendeur" && formData.id !== identity?.company) ? (
                <SelectInput
                  defaultValue={null}
                  source="type"
                  label="Nature de l'organisation"
                  choices={TYPES.map((s) => ({ id: s, name: s }))}
                />
              ) : (
                <Labeled label="Nature de l'organisation">
                  <TextField source="type" mb={2} />
                </Labeled>
              )
            }
          </FormDataConsumer>
          <TextInput defaultValue={null} source="address.address" label="Rue et numéro" />
          <TextInput defaultValue={null} source="address.additionalAddress" label="Complément d'adresse" />
          <TextInput defaultValue={null} source="address.zipCode" label="Code postal" />
          <TextInput defaultValue={null} source="address.city" label="Ville" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              identity?.status === "Collaborateur" ||
              (identity?.status === "Revendeur" && formData.id !== identity?.company) ? (
                <SelectInput
                  validate={required()}
                  defaultValue="France"
                  source="address.country"
                  label="Pays"
                  choices={COUNTRIES.map((s) => ({ id: s, name: s }))}
                />
              ) : (
                <Labeled label="Pays">
                  <TextField source="country" mb={2} />
                </Labeled>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.address &&
              formData.address.country &&
              formData.address.country === "France" && (
                <>
                  {identity?.status === "Collaborateur" ||
                  (identity?.status === "Revendeur" && formData.id !== identity?.company) ? (
                    <TextInput defaultValue={null} required source="siret" label="SIRET" />
                  ) : (
                    <Labeled label="SIRET">
                      <TextField source="siret" mb={2} />
                    </Labeled>
                  )}
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.address &&
              formData.address.country &&
              formData.address.country !== "France" && (
                <>
                  {identity?.status === "Collaborateur" ||
                  (identity?.status === "Revendeur" && formData.id !== identity?.company) ? (
                    <TextInput defaultValue={null} source="vat" label="Numéro de TVA Intracommunautaire" />
                  ) : (
                    <Labeled label="Numéro de TVA Intracommunautaire">
                      <TextField source="vat" mb={2} />
                    </Labeled>
                  )}
                </>
              )
            }
          </FormDataConsumer>
          <SelectInput
            defaultValue={null}
            source="sector"
            label="Activité"
            choices={COMPANIES_SECTORS.map((s) => ({ id: s, name: s }))}
          />
          <TextInput defaultValue={null} source="phone" label="Numéro de téléphone" />
          <TextInput defaultValue={null} source="email" label="Adresse email" />
        </FormTab>
        {identity?.status === "Collaborateur" && (
          <FormTab
            label={<Typography sx={[isSelected("account") && { color: "primary.main" }]}>Compte</Typography>}
            name="account"
            path="account"
          >
            <Typography variant="h6" mb={"20px"}>
              Statut du compte
            </Typography>
            <Labeled label="Identifiant">
              <TextField source="id" mb={2} />
            </Labeled>
            <SelectInput
              defaultValue={null}
              source="status"
              label="Statut"
              choices={COMPANIES_STATUSES.map((s) => ({ id: s, name: s }))}
            />
            <SelectInput
              defaultValue={null}
              source="prospect"
              label="Prospect"
              emptyText={"Non"}
              emptyValue={false}
              choices={[{ id: true, name: "Oui" }]}
              alwaysOn
            />
            <FormDataConsumer>
              {({ formData, ...rest }: any) =>
                formData.status &&
                formData.status === "Indirect" && (
                  <ReferenceInput
                    required
                    source="parentCompany"
                    reference="companies"
                    sort={{ field: "company", order: "ASC" }}
                    perPage={1000}
                  >
                    <AutocompleteInput
                      label="Propriétaire du compte"
                      optionText="company"
                      filterToQuery={(search: any) => ({ company: search })}
                    />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
            <SelectInput
              defaultValue={"Actif"}
              source="accountStatus"
              label="Statut du compte"
              choices={COMPANIES_ACCOUNT_STATUSES.map((s) => ({ id: s, name: s }))}
            />
            <DateInput defaultValue={null} source="clientSince" label="Client depuis le" />
            <ReferenceInput required source="provider_id" reference="providers" perPage={1000}>
              <SelectInput
                defaultValue={process.env.REACT_APP_DEFAULT_PROVIDER_ID}
                label="Fournisseur SMS"
                optionText="name"
              />
            </ReferenceInput>

            <Typography variant="h6" mb={1.5}>
              Gestion Commerciale
            </Typography>
            <SelectInput
              defaultValue={null}
              source="source"
              label="Source"
              choices={COMPANIES_SOURCES.map((s) => ({ id: s, name: s }))}
            />
            <ReferenceInput label="Manager du compte" source="accountManager" reference="collaborators">
              <SelectInput
                label="Manager du compte"
                optionText={(record) => {
                  return `${record.firstName} ${record.lastName} `;
                }}
              />
            </ReferenceInput>
          </FormTab>
        )}
        {resourceTabs.map(({ resource, label, Component }: any) => {
          return (
            <FormTab
              key={label}
              path={resource}
              label={<Typography sx={[isSelected(resource) && { color: "primary.main" }]}>{label}</Typography>}
              name={resource}
            >
              {isSelected(resource) && (
                <FunctionField
                  sx={{ width: "100%" }}
                  render={({ id }: any) => {
                    return <Component resource={resource} company={id} group_id={id} />;
                  }}
                />
              )}
            </FormTab>
          );
        })}
        {/* to not show payment methods if on proper company */}
        {/* <FunctionField
          sx={{ width: "100%" }}
          render={({ id }: any) => {
            console.log("id",id)
            return (identity?.status === "Collaborateur" ||
                (identity?.status === "Revendeur" && id !== identity?.company)) ? (
                <FormTab
                  path="paymentMethods"
                  label={
                    <Typography sx={[isSelected("paymentMethods") && { color: "primary.main" }]}>
                      Moyens de paiement
                    </Typography>
                  }
                  name="paymentMethods"
                >
                  {isSelected("paymentMethods") && (
                    <FunctionField
                      sx={{ width: "100%" }}
                      render={({ id }: any) => {
                        return <PaymentMethodsList resource="paymentMethods" {...props} company={id} />;
                      }}
                    />
                  )}
                </FormTab>
              ) : null;
          }}
        /> */}
        <FormTab
          path="paymentMethods"
          label={
            <Typography sx={[isSelected("paymentMethods") && { color: "primary.main" }]}>Moyens de paiement</Typography>
          }
          name="paymentMethods"
        >
          {isSelected("paymentMethods") && (
            <FunctionField
              sx={{ width: "100%" }}
              render={({ id }: any) => {
                return <PaymentMethodsList resource="paymentMethods" {...props} company={id} />;
              }}
            />
          )}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default CompaniesEdit;
