import { Grid, Typography, InputAdornment } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import {
  Create,
  ReferenceInput,
  FormDataConsumer,
  ReferenceArrayInput,
  SimpleForm,
  useGetIdentity,
  TextInput,
  AutocompleteInput,
  useNotify,
  AutocompleteArrayInput,
  required,
  SelectInput,
} from "react-admin";
import { DualListInput } from "@react-admin/ra-relationships";
import { HistoryContext } from "../../services/historyProvider";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import AutocompleteDualListInput from "../../components/fields/AutocompleteDualListInput";
import { CONTACT_VISIBILITIES } from "../../services/common-utils/constants";
import PageTitle from "../../components/navigation/PageTitle";

const ContactListsCreate = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  const history: string[] = useContext(HistoryContext);
  const [fromCompany, setFromCompany] = useState("");
  const notify = useNotify();
  const redirect = useCustomListRedirect();

  // Transform override
  const onTransform = (data: any) => {
    if (["Client", "Indirect"].includes(identity?.status)) {
      data.group_id = identity?.company;
    }
    delete data.itemToAdd;
    delete data.itemRemove;
    return {
      ...data,
    };
  };

  const onSuccess = (data: any) => {
    notify(`Liste de contacts créé avec succès`, { type: "success" });
    redirect("contactsOrContactLists");
  };

  useEffect(() => {
    //route from routerContext can be not already updated
    if (history.length && !fromCompany && history[0] === window.location.pathname) {
      const match = history[1].match(/(?:companies\/)(.*)(?:\/)/);
      setFromCompany(match ? match[1] : "none");
    }
  }, [history, fromCompany]);

  return !fromCompany ? (
    <></>
  ) : (
    <Create {...props} transform={onTransform} mutationOptions={{ onSuccess }} hasShow={false}>
      <SimpleForm defaultValues={() => (fromCompany !== "none" ? { group_id: fromCompany } : {})}>
        <PageTitle text="Création d'une liste de contacts" divider />
        <TextInput required source="name" label="Nom de la liste de contacts" />
        {["Collaborateur", "Revendeur"].includes(identity?.status) && (
          <ReferenceInput
            required
            source="group_id"
            reference="companies"
            filter={identity?.status === "Revendeur" ? { parentCompany: identity?.company } : {}}
            sort={{ field: "company", order: "ASC" }}
            perPage={1000}
          >
            <AutocompleteInput
              label="Organisation"
              optionText="company"
              filterToQuery={(search: any) => ({ company: search })}
            />
          </ReferenceInput>
        )}
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.group_id || identity?.company) && (
              <ReferenceArrayInput
                label="Contacts"
                reference="contacts"
                source="contacts"
                defaultValue={[]}
                filter={{
                  group_id: ["Collaborateur", "Revendeur"].includes(identity?.status)
                    ? formData.group_id
                    : identity?.company,
                }}
                perPage={1000}
              >
                <AutocompleteDualListInput
                  label="Contacts"
                  optionText={(contact: any) => `${contact.firstname} ${contact.lastname}`}
                  source="contacts"
                  queryField="lastname"
                />
              </ReferenceArrayInput>
            )
          }
        </FormDataConsumer>
        {/* <SelectInput
          validate={required()}
          defaultValue={CONTACT_VISIBILITIES[0]}
          source="visibility"
          label="Visibilité"
          choices={CONTACT_VISIBILITIES.map((s) => ({ id: s, name: s }))}
        /> */}

        {/* <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.visibility === "Cloisonné à un groupe d'utilisateurs" && (
              <ReferenceInput required source="crmGroupId" reference="groups" filter={{ company: formData.group_id }}>
                <AutocompleteInput
                  label="Groupe d'accès à la liste de contacts"
                  optionText="name"
                  filterToQuery={(search: any) => ({ name: search })}
                  helperText="Seuls les utilisateurs du groupe pourront accéder à la liste de contacts"
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer> */}
      </SimpleForm>
    </Create>
  );
};

export default ContactListsCreate;
