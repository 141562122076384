import React, { useState } from "react";
import { useListContext, useUnselectAll, useNotify, useRefresh, Button } from "react-admin";
import api from "../../services/apiWithAuth";

const LicensesBulkRemove = ({ company }: any) => {
  const { selectedIds, data: licenses } = useListContext();
  const [isLoading, setisLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("licenses");

  const removeLicenses = async () => {
    setisLoading(true);
    const selectedLicenses = licenses
      .filter((license: any) => selectedIds.includes(license.id) && license.email)
      .map((license: any) => {
        return { id: license.id, email: license.email };
      });
    try {
      const response = await api.post("/licenses/removeLicenses", {
        licenses: selectedLicenses,
      });
      setisLoading(false);
      refresh();
      notify("Désaffectation de " + response.data.length + " licences effectuée");
      unselectAll();
    } catch (err) {
      setisLoading(false);
      refresh();
      notify("Erreur lors de la désaffectation des licences", { type: "error" });
    }
  };

  return <Button label={`Désaffecter ces licences`} disabled={isLoading} onClick={removeLicenses}></Button>;
};

export default LicensesBulkRemove;
