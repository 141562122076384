import { PaletteMode } from "@mui/material";
import { defaultTheme } from "react-admin";

export const Theme = (mode = "light") => {
  return {
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      mode: mode as PaletteMode,
      primary: {
        main: "#1c797e",
      },
      secondary: {
        main: "#36d764",
        darker: "#003613",
        lighter: "#BDF1CF",
        contrastText: "white",
      },
      customBackground: {
        main: mode === "light" ? "#f9fff6" : "black",
        contrastText: "black",
      },
      neutral: {
        darker: "#414141",
        dark: "#515151",
        main: "#c9c9c9",
        light: "#F6F6F6",
        lighter: "#F8F8F8",
      },
    },
    typography: {
      ...defaultTheme.typography,
      fontFamily: "'Poppins', sans-serif",
      h6: {
        fontWeight: 300,
      },
      body1: {
        fontWeight: 300,
      },
      body2: {
        fontWeight: 300,
      },
    },
    components: {
      ...defaultTheme.components,
      MuiAutocomplete: {
        defaultProps: {
          sx: { width: "350px" },
        },
      },
      MuiFormGroup: {
        defaultProps: {
          sx: { width: "350px" },
        },
      },
      MuiInputBase: {
        defaultProps: {
          sx: { width: "350px" },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          sx: { whiteSpace: "nowrap" },
        },
      },
      // fix react-admin-import-csv css error
      MuiDialogContent: {
        defaultProps: {
          sx: { "& p": { marginTop: "0 !important" } },
        },
      },
      RaImageInput: {
        styleOverrides: {
          root: {
            width: "350px",
          },
        },
      },
      RaFilterForm: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-root": {
              width: "unset",
            },
          },
        },
      },
      RaLayout: {
        styleOverrides: {
          root: {
            margin: 0,
            "& .RaLayout-content": {
              padding: "8px",
              backgroundColor: mode === "light" ? "white" : "#121212",
            },
          },
        },
      },
      RaToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: "unset",
          },
        },
      },
      RaAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "light" ? "white" : "#121212",
            color: mode === "light" ? "#121212" : "white",
          },
        },
      },
      RaFilterButton: {
        styleOverrides: {
          root: {
            display: "none",
          },
        },
      },
      RaCreateButton: {
        styleOverrides: {
          root: {
            backgroundColor: "#1c797e",
            color: "white",
            "&:hover": {
              backgroundColor: "#024361",
            },
          },
        },
      },
      RaMenuItemLink: {
        styleOverrides: {
          root: {
            color: mode === "light" ? "black" : "white",
            marginLeft: "0.5rem",
            marginRight: "1rem",
            borderRadius: "1.5rem",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            "&.RaMenuItemLink-active": {
              backgroundColor: "rgb(215, 254, 200)",
              color: "black",
              fontWeight: "bold",
              "& .RaMenuItemLink-icon": {
                color: "black",
              },
            },
            "& .RaMenuItemLink-icon": {
              color: "grey",
            },
            "&:hover": {
              backgroundColor: "#e8fedf",
              color: mode === "light" ? "inherit" : "black",
            },
          },
        },
      },
    },
  };
};
