import React from "react";
import { FunctionField } from "react-admin";

const CountryField = ({ source }: any) => {
  const franceFlag = require(`../../assets/france.svg`).default as string;
  const belgiumFlag = require(`../../assets/belgium.svg`).default as string;
  const swissFlag = require(`../../assets/swiss.svg`).default as string;
  const canadaFlag = require(`../../assets/canada.svg`).default as string;
  const usaFlag = require(`../../assets/united-states.svg`).default as string;
  const countryToImage: any = {
    "France": franceFlag,
    "Belgique": belgiumFlag,
    "Suisse": swissFlag,
    "Canada": canadaFlag,
    "Etats-Unis": usaFlag,
    "Guadeloupe": franceFlag,
    "Saint-Barthelemy": franceFlag,
  }
  return (
    <FunctionField
      render={(record: any) => <img src={countryToImage[record[source]]} alt={record[source]} width="32" height="32" />}
    />
  );
};

export default CountryField;
