import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Create, TextInput, useNotify, useRedirect } from 'react-admin';
import { SimpleForm } from '@react-admin/ra-rbac'

const ProvidersCreate =  ({ ...props }) => {
  const notify = useNotify()
  const redirect = useRedirect();

  // Success override
  const onSuccess = async (data: any) => {
    notify(`Fournisseur créé avec succès`, { type: 'success' });
    redirect('list', 'providers', data.id, data);
  };

  return (
    <Create {...props}  mutationOptions={{ onSuccess }}>
      <SimpleForm>
        <Typography variant="h6" mb={"20px"}>
          Edition d'un <b>Fournisseur</b>
        </Typography>

        <div style={{ borderBottom: '1px solid lightgray', width: '100%', marginBottom: '28px', marginTop: '0px' }} />

        <Grid container direction={"column"}>
          <TextInput required source="name" label="Nom" />
          <TextInput required source="entry_point" label="Url d'entrée" />
        </Grid>
      </SimpleForm>
    </Create>
  );
}

export default ProvidersCreate;
