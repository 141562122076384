import { Grid, Typography } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import {
  Edit,
  TextInput,
  useNotify,
  useRedirect,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  useDataProvider,
  useGetIdentity,
  FormDataConsumer,
  SelectInput,
  required,
  useUpdate,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import { HistoryContext } from "../../services/historyProvider";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import PageTitle from "../../components/navigation/PageTitle";

const ContactsEdit = ({ ...props }) => {
  const notify = useNotify();
  const redirect = useCustomListRedirect();
  const { isLoading, data: identity } = useGetIdentity();
  const history: string[] = useContext(HistoryContext);
  const [fromCompany, setFromCompany] = useState("");
  // const [crmGroupId, setCrmGroupId] = useState("");
  // const dataProvider = useDataProvider();
  // const [update] = useUpdate();

  // Transform override
  const onTransform = async (data: any) => {
    delete data.customExpeditor;
    if (["Client", "Indirect"].includes(identity?.status)) {
      data.group_id = identity?.company;
    }
    // if (data.crmGroupId) {
    //   setCrmGroupId(data.crmGroupId);
    // }
    // delete data.crmGroupid;
    return data;
  };

  // Success override
  const onSuccess = async (data: any) => {
    // if (crmGroupId) {
    //   try {
    //     const groupRes = await dataProvider.getOne("groups", { id: crmGroupId });
    //     update("groups", {
    //       id: crmGroupId,
    //       data: { contacts: (groupRes.data.contacts || []).concat(data.id) },
    //       previousData: groupRes.data,
    //     });
    //   } catch (error) {}
    // }
    notify(`Contact créé avec succès`, { type: "success" });
    redirect("contactsOrContactLists");
  };

  useEffect(() => {
    //route from routerContext can be not already updated
    if (history.length && !fromCompany && history[0] === window.location.pathname) {
      const match = history[1].match(/(?:companies\/)(.*)(?:\/)/);
      setFromCompany(match ? match[1] : "none");
    }
  }, [history, fromCompany]);

  return isLoading || !fromCompany ? (
    <></>
  ) : (
    <Edit {...props} transform={onTransform} mutationMode="optimistic" mutationOptions={{ onSuccess }}>
      <SimpleForm defaultValues={() => (fromCompany !== "none" ? { group_id: fromCompany } : {})}>
        <PageTitle text={(record: any) => `${record.firstname} ${record.lastname}`} />
        {["Collaborateur", "Revendeur"].includes(identity?.status) && (
          <ReferenceInput
            required
            source="group_id"
            reference="companies"
            filter={identity?.status === "Revendeur" ? { parentCompany: identity?.company } : {}}
            sort={{ field: "company", order: "ASC" }}
            perPage={1000}
          >
            <AutocompleteInput
              label="Organisation"
              optionText="company"
              filterToQuery={(search: any) => ({ company: search })}
            />
          </ReferenceInput>
        )}
        <SelectInput
          defaultValue={null}
          source="civility"
          label="Civilité"
          choices={["M", "Mme"].map((s) => ({ id: s, name: s }))}
        />
        <TextInput required source="firstname" label="Prénom" />
        <TextInput required source="lastname" label="Nom" />
        <TextInput required source="phonenumber" label="Numéro de téléphone" />
        <TextInput defaultValue={null} source="email" label="Adresse email" />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <BooleanInput
              defaultValue={true}
              source="public"
              label="Visible par tous les utilisateurs"
              // not possible to set to private if user have not any license. Not possible for collaborators or resellers for their clients as it would be necessary to specify which license they want to put the contact on. Possible but more complicated.
              disabled={!(identity?.licenseId && formData.group_id === identity?.company)}
            />
          )}
        </FormDataConsumer>
        <div style={{ borderBottom: "1px solid lightgray", width: "100%", marginBottom: "28px", marginTop: "0px" }} />
      </SimpleForm>
    </Edit>
  );
};

export default ContactsEdit;
