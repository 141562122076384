import React, { useState, useEffect, useRef } from "react";
import { useInterval, useLocalStorage } from "usehooks-ts";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutCart from "./checkout-cart/CheckoutCart";
import CheckoutForm from "./checkout-form/CheckoutForm";
// import CheckoutDelivery from './checkout-delivery/checkout-delivery'
import CheckoutPayment from "./checkout-payment/CheckoutPayment";
import CheckoutResume from "./checkout-resume/CheckoutResume";
import CheckoutStepper from "./checkout-stepper/CheckoutStepper";
import { Box, useTheme } from "@mui/material";
import { useGetIdentity } from "react-admin";
import priceCalculator from "../../services/common-utils/priceCalculator";
import api from "../../services/apiWithAuth";
import CheckoutSuccess from "./checkout-success/CheckoutSuccess";
import { isEqual } from "lodash";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");
const CheckoutPage = () => {
  const theme = useTheme();
  const [cart, setCart] = useLocalStorage("cart", [] as any[]);
  const [checkoutStep, setCheckoutStep] = useLocalStorage("checkoutStep", 0);
  const [paymentFormSubmitting, setPaymentFormSubmitting] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState("");
  const [paidWith, setPaidWith] = useState("");
  const [customerInfos, setCustomerInfos] = useState({});
  const [discount, setDiscount] = useLocalStorage("discount", ({} as any) || null);
  const [totals, setTotals] = useState({
    totalHt: 0,
    taxes: { total: 0 },
    totalTtc: 0,
    withoutDiscount: { totalHt: 0, taxes: 0, totalTtc: 0 },
    savings: { totalHt: 0, taxes: 0, totalTtc: 0 },
  } as any);
  const [checkoutCompleted, setCheckoutCompleted] = useState(false);
  const [checkoutFailed, setCheckoutFailed] = useState(false);
  const { isLoading, data: identity } = useGetIdentity();
  const callTimestamp = useRef(null as any);

  const handleStep = (direction: number) => {
    if ((checkoutStep < 3 && direction === 1) || (checkoutStep > 0 && direction === -1)) {
      const newStep = checkoutStep + direction;
      setCheckoutStep(newStep);
      setCheckoutStep((prevcheckoutStep: number) => prevcheckoutStep + direction);
      window.scrollTo({ top: 0 });
    }
  };

  const handleCheckoutCompleted = (paymentType: string) => {
    setPaidWith(paymentType);
    setCheckoutCompleted(true);
    setDiscount({});
    setCart([]);
    localStorage.removeItem("paymentIntentId");
    localStorage.removeItem("discount");
    localStorage.removeItem("clientSecret");
    localStorage.removeItem("prevPaymenIntentTotals");
    localStorage.removeItem("customerPaymentMethods");
    setCheckoutStep(0);
  };

  const handleCheckoutFailed = () => {
    setCheckoutFailed(true);
  };

  useEffect(() => {
    if (identity) {
      setCustomerInfos({
        firstName: identity?.firstName || null,
        lastName: identity?.lastName || null,
        email: identity?.email || null,
        contactPhone: identity?.proPhone || identity?.proMobilePhone || null,
        billingAddress: identity?.companyAddress?.address || null,
        billingAdditionalAddress: identity?.companyAddress?.additionalAddress || null,
        billingZipCode: identity?.companyAddress?.zipCode || null,
        billingCity: identity?.companyAddress?.city || null,
        // billingCountry: identity?.companyCountry || null,
      });
    }
  }, [identity, setCustomerInfos]);

  useEffect(() => {
    //allow to avoid multiple payment intent creation
    const prevTimestamp = callTimestamp.current;
    const currentTimestamp = Date.now();
    callTimestamp.current = currentTimestamp;
    const isNotComponentRerender = !prevTimestamp || currentTimestamp - prevTimestamp > 300;

    const asyncCall = async () => {
      const paymentIntentId = localStorage.getItem("paymentIntentId") || "";
      const prevPaymenIntentTotals = JSON.parse(localStorage.getItem("prevPaymenIntentTotals") || "{}");
      if (
        isNotComponentRerender &&
        checkoutStep >= 1 &&
        totals.totalTtc &&
        (!paymentIntentId || (paymentIntentId && !isEqual(prevPaymenIntentTotals, totals)))
      ) {
        const cartWithoutAdditionnalInfos = cart.map((item: any) => {
          const tarifs = item.product.tarifs.map((tarif: any) => {
            delete tarif.priceHT;
            delete tarif.periodLabel;
            delete tarif.choiceLabel;
            return tarif;
          });
          return {
            ...item,
            product: {
              ...item.product,
              tarifs,
            },
          };
        });
        const data = {
          cart: cartWithoutAdditionnalInfos,
          discountId: discount?.id || null,
          totals,
          paymentIntentId,
        };
        try {
          const res = await api.post("/utils/createOrUpdatePaymentIntent", data);
          if (res.data?.id) {
            localStorage.setItem("customerPaymentMethods", JSON.stringify(res.data.customerPaymentMethods));
            localStorage.setItem("clientSecret", res.data.clientSecret);
            localStorage.setItem("paymentIntentId", res.data.id);
            localStorage.setItem("orderReference", res.data.orderReference);
            localStorage.setItem("prevPaymenIntentTotals", JSON.stringify(totals));
          }
        } catch (err) {
          console.log("error during payment intent creation or edition", err);
        }
      }
    };
    asyncCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totals, checkoutStep]);

  useEffect(() => {
    setTotals(priceCalculator(cart, discount, identity?.companyCountry));
  }, [discount, cart, setTotals, identity?.companyCountry]);

  return isLoading ? null : (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ width: "100%", mt: 3, color: theme.palette.mode === "light" ? "black" : "white" }}
    >
      {!checkoutCompleted && !checkoutFailed ? (
        <Box sx={{ width: [1, 0.9] }}>
          <CheckoutStepper checkoutStep={checkoutStep} />
          <Box
            display="flex"
            justifyContent="space-around"
            width="100%"
            gap="30px"
            sx={{ flexDirection: ["column", "row"] }}
          >
            <Box sx={{ width: [1, 0.7] }}>
              {checkoutStep === 0 && (
                <CheckoutCart
                  cart={cart}
                  setCart={setCart}
                  totals={totals}
                  handleStep={handleStep}
                  discount={discount}
                  setDiscount={setDiscount}
                />
              )}
              {checkoutStep === 1 && Object.keys(customerInfos).length > 0 && (
                <CheckoutForm
                  handleStep={handleStep}
                  customerInfos={customerInfos}
                  setCustomerInfos={setCustomerInfos}
                />
              )}
              {checkoutStep === 2 && (
                <Elements stripe={stripePromise}>
                  <CheckoutPayment
                    totals={totals}
                    handleStep={handleStep}
                    handleCheckoutCompleted={handleCheckoutCompleted}
                    handleCheckoutFailed={handleCheckoutFailed}
                    cart={cart}
                    customerInfos={customerInfos}
                    discount={discount}
                    customerPaymentMethods={JSON.parse(localStorage.getItem("customerPaymentMethods") || "[]")}
                    setPaymentFormSubmitting={setPaymentFormSubmitting}
                    setPaymentErrorMessage={setPaymentErrorMessage}
                  />
                </Elements>
              )}
              {checkoutStep === 3 && <CheckoutSuccess />}
            </Box>
            <Box sx={{ width: [1, 0.25] }}>
              <CheckoutResume
                cart={cart}
                setCart={setCart}
                identity={identity}
                totals={totals}
                checkoutStep={checkoutStep}
                handleStep={handleStep}
                discount={discount}
                setDiscount={setDiscount}
                paymentFormSubmitting={paymentFormSubmitting}
                paymentErrorMessage={paymentErrorMessage}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ width: [1, 0.9] }}>
          <CheckoutSuccess paidWith={paidWith} />
        </Box>
      )}
    </Box>
  );
};

export default CheckoutPage;
