import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useGetIdentity, useNotify, useRedirect } from "react-admin";
import StripeCard from "../checkout/checkout-payment/StripeCard";
import api from "../../services/apiWithAuth";
import { loadStripe } from "@stripe/stripe-js";
import StripeSepa from "../checkout/checkout-payment/StripeSepa";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import { useForm, FormProvider } from "react-hook-form";
import { CardElement, Elements, IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { LoadingButton } from "@mui/lab";
import { HistoryContext } from "../../services/historyProvider";
import { doc, getDoc } from "firebase/firestore";
import { fbFirestore } from "../../services/firebase/firebase.services";
import PageTitle from "../../components/navigation/PageTitle";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

const SetupForm = ({ clientSecret, customerInfos, routeFrom }: any) => {
  const theme = useTheme();
  const [paymentType, setPaymentType] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const notify = useNotify();
  const redirect = useRedirect();
  const methods = useForm();
  const visa = require("../../assets/visa.png") as string;
  const mastercard = require("../../assets/mastercard.png") as string;
  const amex = require("../../assets/amex.png") as string;
  const sepa = require("../../assets/sepa.png") as string;

  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async () => {
    if (!stripe || !elements || !clientSecret) {
      return;
    }
    setErrorMessage("");
    const billingDetails = {
      address: {
        line1: customerInfos.billingAddress,
        postal_code: customerInfos.billingZipCode,
        city: customerInfos.billingCity,
        //country: customerInfos.billingCountry,
      },
      email: customerInfos.email,
      name: customerInfos.firstName + " " + customerInfos.lastName,
      phone: customerInfos.contactPhone,
    };
    let res: any;

    if (paymentType === "card") {
      const card = elements.getElement(CardElement);
      if (!card) {
        setErrorMessage("Veuillez choisir un mode de paiement");
        return;
      }
      res = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card,
          billing_details: billingDetails,
        },
      });
    } else {
      const iban = elements.getElement(IbanElement);
      if (!iban) {
        setErrorMessage("Veuillez choisir un mode de paiement");
        return;
      }
      res = await stripe.confirmSepaDebitSetup(clientSecret, {
        payment_method: {
          sepa_debit: iban,
          billing_details: billingDetails,
        },
      });
    }

    const error = res?.error || null;

    if (error) {
      setErrorMessage(error?.message || "");
    } else {
      notify("Votre nouveau mode de paiement à bien été ajouté");
      // we have to remove payments infos to begin again the process and get fresh payment methods
      localStorage.removeItem("clientSecret");
      localStorage.removeItem("customerPaymentMethods");
      localStorage.removeItem("paymentIntentId");
      localStorage.removeItem("checkoutStep");
      redirect(routeFrom);
    }
  };

  return (
    <Card sx={{ p: 3, mb: 2, mt: 3, width: [1, 0.7, 0.7, 0.7, 0.5] }}>
      <FormProvider {...methods}>
        <Box id="payment-form" component="form" autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
          <PageTitle text="Ajout d'un nouveau moyen de paiement" divider />
          <Paper sx={{ backgroundColor: theme.palette.mode === "light" ? "white" : "inherit", p: 2 }}>
            <Box
              display="flex"
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: 1,
                ":hover": { cursor: "pointer" },
              }}
              alignItems="center"
              onClick={() => setPaymentType("card")}
            >
              <FormGroup sx={{ width: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<CheckCircle />}
                      color="success"
                      checked={paymentType === "card"}
                    />
                  }
                  label="Carte bancaire"
                />
              </FormGroup>
              <Box display="flex" sx={{ flexDirection: "row" }} gap="15px">
                <img src={visa} width="70px" alt="carte visa" />
                <img src={mastercard} width="70px" alt="mastercard" />
                <img src={amex} width="70px" alt="carte american express" />
              </Box>
            </Box>
            {paymentType === "card" && (
              <Box display="flex" sx={{ flexDirection: "flex-start" }} alignItems="center" mt={2}>
                <Box sx={{ width: [1, 0.5] }}>
                  <StripeCard />
                  <Typography fontSize="0.8rem">
                    Aucune information bancaire n'est stockée sur ce site, le paiement se fait directement sur le
                    serveur de votre banque.
                  </Typography>
                </Box>
                {/* submit form with id */}
              </Box>
            )}
          </Paper>
          <Paper sx={{ backgroundColor: theme.palette.mode === "light" ? "white" : "inherit", p: 2, mt: 2 }}>
            <Box
              display="flex"
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: 1,
                ":hover": { cursor: "pointer" },
              }}
              alignItems="center"
              onClick={() => setPaymentType("sepa")}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<CheckCircle />}
                      color="success"
                      checked={paymentType === "sepa"}
                    />
                  }
                  label="SEPA"
                />
              </FormGroup>
              <img src={sepa} width="70px" alt="prélèvement sepa" />
            </Box>
            {paymentType === "sepa" && (
              <Box display="flex" sx={{ flexDirection: "flex-start" }} alignItems="center" mt={2}>
                <Box sx={{ width: [1, 0.5] }}>
                  <StripeSepa />
                  <Typography fontSize="0.8rem">
                    En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A) VENTUS
                    et Stripe, notre prestataire de services de paiement et/ou PPRO, son prestataire de services local,
                    à envoyer des instructions à votre banque pour débiter votre compte et (B) votre banque à débiter
                    votre compte conformément à ces instructions. Vous avez, entre autres, le droit de vous faire
                    rembourser par votre banque selon les modalités et conditions du contrat conclu avec votre banque.
                    La demande de remboursement doit être soumise dans un délai de 8 semaines à compter de la date à
                    laquelle votre compte a été débité. Vos droits sont expliqués dans une déclaration disponible auprès
                    de votre banque. Vous acceptez de recevoir des notifications des débits à venir dans les 2 jours
                    précédant leur réalisation.
                  </Typography>
                </Box>
                {/* submit form with id */}
              </Box>
            )}
          </Paper>
          {/* Show error message to your customers */}
          {errorMessage && <div>{errorMessage}</div>}
          <LoadingButton
            sx={{ color: "white", mt: 3, width: 1 }}
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            loading={methods.formState.isSubmitting}
          >
            Ajouter le moyen de paiement
          </LoadingButton>
        </Box>
      </FormProvider>
    </Card>
  );
};

const PaymentMethodsCreate = ({ ...props }) => {
  const [clientSecret, setClientSecret] = useState("");
  const { isLoading, data: identity } = useGetIdentity();
  const [customerInfos, setCustomerInfos] = useState({});
  const history: string[] = useContext(HistoryContext);
  const [fromCompany, setFromCompany] = useState("");

  useEffect(() => {
    //history from historyContext can be not already updated
    if (history.length && !fromCompany && history[0] === window.location.pathname) {
      const matches: any = history
        .map((path: string) => {
          const match = path.match(/(?:companies\/)(.*)(?:\/)/);
          return match ? match[1] : null;
        })
        .filter((match: string) => match !== null);
      setFromCompany(matches.length ? matches[0] : "none");
    }
  }, [history, fromCompany]);

  useEffect(() => {
    const asyncCall = async () => {
      try {
        const { data: createdClientSecret } = await api.post("/paymentMethods/createSetupIntent", {
          company: fromCompany || identity?.company,
        });
        setClientSecret(createdClientSecret);
      } catch (err) {
        console.log("error during setup intent creation", err);
      }
    };
    if (fromCompany || identity?.company) {
      asyncCall();
    }
  }, [fromCompany, identity?.company]);

  useEffect(() => {
    if (identity && fromCompany) {
      const asyncCall = async () => {
        try {
          if (identity?.status === "Collaborateur") {
            const companySnap = await getDoc(doc(fbFirestore, "companies", fromCompany as string));
            const company = companySnap.data();
            setCustomerInfos({
              firstName: company?.company || null,
              lastName: null,
              email: company?.email || null,
              contactPhone: company?.phone || null,
              billingAddress: company?.address?.address || null,
              billingAdditionalAddress: company?.address?.additionalAddress || null,
              billingZipCode: company?.address?.zipCode || null,
              billingCity: company?.address?.city || null,
              //billingCountry: company?.companyCountry || null,
            });
          } else {
            setCustomerInfos({
              firstName: identity?.firstName || null,
              lastName: identity?.lastName || null,
              email: identity?.email || null,
              contactPhone: identity?.proPhone || identity?.proMobilePhone || null,
              billingAddress: identity?.companyAddress?.address || null,
              billingAdditionalAddress: identity?.companyAddress?.additionalAddress || null,
              billingZipCode: identity?.companyAddress?.zipCode || null,
              billingCity: identity?.companyAddress?.city || null,
              //billingCountry: identity?.companyCountry || null,
            });
          }
        } catch (err) {
          console.log("error getting company details", err);
        }
      };
      asyncCall();
    }
  }, [identity, setCustomerInfos, fromCompany]);

  return !clientSecret || !fromCompany || !Object.keys(customerInfos).length ? (
    <CircularProgress />
  ) : (
    <Elements stripe={stripePromise}>
      <SetupForm clientSecret={clientSecret} customerInfos={customerInfos} routeFrom={history[1]} />
    </Elements>
  );
};

export default PaymentMethodsCreate;
