import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { fbFirestore } from "../../services/firebase/firebase.services";
import { Badge, IconButton } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useRefresh } from "react-admin";

//A lot can be made to enhance this component, (refactoring notifications by resource to avoid code duplication, pass notifications in a new collections, to be able to have multiple notifications by id)
const Notifications = ({ identity }: any) => {
  const [notifications, setNotifications] = useState([] as any);
  const [orderNotifications, setOrderNotifications] = useState([] as any);
  const [opportunityNotifications, setOpportunityNotifications] = useState([] as any);
  const navigate = useNavigate();
  const refresh = useRefresh();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const redirect = (resource: string, id: string) => {
    setAnchorEl(null);
    navigate(resource + "/" + id);
  };

  useEffect(() => {
    if (identity?.showOrderNotifications) {
      const docRef = collection(fbFirestore, "orders");
      const q = query(
        docRef,
        where("status", "in", ["Complète", "Erreur de paiement", "En cours de paiement", "En cours de traitement"]),
        orderBy("createdate", "desc"),
        limit(10)
      );

      const unsubscribeOrders = onSnapshot(q, (snap: any) => {
        let newNotifications = orderNotifications;
        snap.docs.forEach((doc: any) => {
          const id = doc.id;
          const order = doc.data();
          newNotifications = newNotifications.filter((notif: any) => {
            return notif.id !== id;
          });
          newNotifications.push({
            id,
            text: "Nouvelle commande : " + id,
            type: "created",
            resource: "orders",
            read: (order?.seenBy || []).includes(identity?.id),
            time: order.createdate.seconds,
            displayTime:
              new Date(order.createdate.seconds * 1000)
                .toISOString()
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/")
                .slice(0, 5) +
              " à " +
              new Date(order.createdate.seconds * 1000).toTimeString().slice(0, 5).replace(":", "h"),
          });
        });
        setOrderNotifications(newNotifications);
      });

      return () => unsubscribeOrders();
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (identity?.showOpportunityNotifications) {
      const docRef = collection(fbFirestore, "opportunities");
      const q = query(docRef, orderBy("lastupdate", "desc"), limit(10));

      const unsubscribeOpportunities = onSnapshot(q, (snap: any) => {
        let newNotifications = opportunityNotifications;
        snap.docs.forEach((doc: any) => {
          const opportunity = doc.data();
          const id = doc.id;
          newNotifications = newNotifications.filter((notif: any) => {
            return notif.id !== id;
          });
          newNotifications.push({
            id,
            text: "Opportunité " + opportunity.company + " -> " + opportunity.status,
            type: "created",
            resource: "opportunities",
            read: (opportunity?.seenBy || []).includes(identity?.id),
            time: opportunity.lastupdate.seconds,
            displayTime:
              new Date(opportunity.lastupdate.seconds * 1000)
                .toISOString()
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/")
                .slice(0, 5) +
              " à " +
              new Date(opportunity.lastupdate.seconds * 1000).toTimeString().slice(0, 5).replace(":", "h"),
          });
        });
        setOpportunityNotifications(newNotifications);
      });

      return () => unsubscribeOpportunities();
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNotifications(orderNotifications.concat(opportunityNotifications));
    refresh();
  }, [orderNotifications, opportunityNotifications, refresh]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
        <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
        <IconButton
          onClick={handleClick}
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Badge
            badgeContent={notifications.filter((notif: any) => !notif.read).length}
            color="error"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <NotificationsIcon sx={{ fontSize: 35 }} />
          </Badge>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {notifications.length ? (
          notifications
            .sort((a: any, b: any) => b.time - a.time)
            .filter((notif: any, index: number) => index < 10)
            .map(({ displayTime, text, read, resource, id }: any, index: number) => {
              return (
                <MenuItem key={index} onClick={() => redirect(resource, id)}>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", alignItems: "center", color: read ? "grey" : "red" }}
                  >
                    <div style={{ fontSize: "20px" }}>&#9632;&nbsp;</div>
                    <div style={{ width: "140px" }}>
                      <b>{displayTime}</b>
                    </div>{" "}
                    {text}
                  </Box>
                </MenuItem>
              );
            })
        ) : (
          <MenuItem>Aucune notification</MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
};

export default Notifications;
