import { Container, Grid, Box, Typography, Stack, Link as MuiLink, useMediaQuery } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FC, useState, useEffect } from "react";
import { useForm, SubmitHandler, FormProvider, useWatch } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { object, string, literal, union, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "../../components/form/FormInput";
import styled from "@emotion/styled";
import React from "react";
import { useLocalStorage } from "usehooks-ts";
import "./SignUpPage.css";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import api from "../../services/api";
import { COMPANIES_SECTORS } from "../../services/common-utils/constants";
import { fbAuthProvider } from "../../services/firebase/firebase.providers";

// Styled React Route Dom Link Component
export const LinkItem = styled(Link)`
  text-decoration: none;
  color: #3683dc;
  &:hover {
    text-decoration: underline;
    color: #5ea1b6;
  }
`;

// Select values
const COUNTRIES = ["France", "Belgique", "Suisse", "Guadeloupe", "Saint-Barthelemy"];

// SignUp Schema with Zod
// TODO: Get literals from Firestore
const signupSchema = object({
  // Company
  company: string().min(1, "Nom de l'entreprise requis"),
  country: union(COUNTRIES.map((country) => literal(country)) as any),
  siret: string(),
  vat: string(),
  sector: string(),

  // Account
  firstName: string().min(1, "Prénom requis"),
  lastName: string().min(1, "Nom de famille requis"),
  password: string().min(1, "Mot de passe requis").min(8, "Minimum 8 caractères"),
  email: string().min(1, "Adresse mail requise").email("Adresse mail invalide"),
})
  .refine((schema) => (schema.country === "France" ? schema.siret.length > 0 : true), {
    message: "SIRET requis",
    path: ["siret"],
  })
  .refine((schema) => (schema.country === "France" ? schema.siret.length === 14 : true), {
    message: "Le SIRET doit contenir 14 caractères",
    path: ["siret"],
  })
  .refine((schema) => (schema.country !== "France" ? schema.vat.length > 0 : true), {
    message: "Numéro de TVA intra-communautaire requis",
    path: ["vat"],
  });

// Infer the Schema to get the TS Type
type ISignUp = TypeOf<typeof signupSchema>;

const SignUpPage: FC = () => {
  // Utils for the SignUpPage rendering
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  const logoPath = require("../../assets/logo.svg").default as string;
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [emailForSignIn, setEmailForSignIn] = useLocalStorage("emailForSignIn", "");
  const navigate = useNavigate();

  const stepText = ["Étape 1 - Créer mon organisation", "Étape 2 - Créer un administrateur"];

  const stepButtonText = ["Étape suivante >>", "Confirmer >>"];

  // Default Values
  const defaultValues: ISignUp = {
    // Company
    company: "",
    country: "France",
    siret: "",
    vat: "",
    sector: "",

    // Account
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  // The object returned from useForm Hook
  const methods = useForm<ISignUp>({
    resolver: zodResolver(signupSchema),
    defaultValues,
  });

  const [country] = useWatch({ control: methods.control, name: ["country"] });

  // Submit Handler
  const onSubmitHandler: SubmitHandler<ISignUp> = async (values: ISignUp) => {
    setError("");

    // SignUp via firebase provider
    try {
      //Creates the company
      await api.post("/utils/createAccount", {
        company: {
          company: values.company,
          socialReason: values.company,
          country: values.country,
          siret: values.siret,
          vat: values.vat,
          type: "Entreprise privée",
          email: values.email.trim().toLowerCase(),
          sector: values.sector,
        },
        user: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email.trim().toLowerCase(),
          password: values.password,
        },
      });
      await fbAuthProvider.login({ username: values.email, password: values.password });
      navigate("/");
      //! keep it here just in case we go back on it : old version when auth type was passwordless
      // const auth = getAuth();
      // await sendSignInLinkToEmail(auth, values.email, {
      //   url: window.location.href.split("sign-up")[0] + "login",
      //   handleCodeInApp: true,
      // });
      // setEmailForSignIn(values.email);
      // navigate("/login?fromRegister=true");
    } catch (err) {
      if (err.response && err.response.status === 409) {
        setError("Cet utilisateur existe déjà ou votre organisation a déjà été enregistrée");
      } else {
        setError("Enregistrement impossible");
      }
    }
  };

  // JSX to be rendered
  return (
    <Container maxWidth={false} sx={{ height: "100vh", backgroundColor: ["#fff", "#cff3bd"] }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%" }}>
        <Grid item sx={{ backgroundColor: "#fff", width: [1, 500], mt: -5 }}>
          <FormProvider {...methods}>
            <Grid
              container
              position="relative"
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                py: [2, 6],
                px: [3, 8],
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={async (evt) => {
                  evt.preventDefault();
                  if (step === 2) methods.handleSubmit(onSubmitHandler)(evt);
                  else {
                    const isFirstStepValid = await methods.trigger(["company", "country", "siret", "vat"]);
                    if (isFirstStepValid) {
                      setStep(2);
                    }
                  }
                }}
                sx={{ width: 1, "& .MuiFormControl-root": { width: 1, mb: 1 }, "& .MuiInputBase-root": { width: 1 } }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: [0.5, 1] }}
                >
                  <img
                    src={logoPath}
                    alt="digisoft"
                    className="digisoft-logo-login"
                    style={{ width: "100%", maxWidth: "260px", height: "auto", margin: 0 }}
                  />
                </Box>

                <Typography
                  color="primary"
                  sx={{ textAlign: "center", fontWeight: "bold", fontSize: ["1.6rem", "2rem"] }}
                >
                  Inscrivez-vous
                </Typography>
                <Typography sx={{ textAlign: "center", fontSize: "1rem", mt: -1 }}>{stepText[step - 1]}</Typography>
                {step === 2 && (
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#3683dc",
                      "&:hover": {
                        textDecoration: "underline",
                        color: "#5ea1b6",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => setStep(1)}
                  >
                    Revenir à l'étape 1
                  </Typography>
                )}

                <Typography
                  variant="subtitle1"
                  component="h1"
                  sx={{ textAlign: "center", mb: "1.5rem", color: "#CD5C5C" }}
                >
                  {error}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  component="form"
                  noValidate
                  autoComplete="off"
                  gap="32px"
                  height={260}
                >
                  {/* Enterprise registration */}
                  {step === 1 && (
                    <Box display="flex" flexDirection="column" component="form" noValidate autoComplete="off" width={1}>
                      <FormInput
                        sx={{ mb: "0.5rem" }}
                        label="Nom de votre organisation"
                        type="input"
                        name="company"
                        required
                        disabled={methods.formState.isSubmitting}
                      />
                      <FormInput
                        sx={{ mb: "0.5rem" }}
                        label="Pays"
                        type="input"
                        name="country"
                        values={COUNTRIES}
                        select
                        required
                        disabled={methods.formState.isSubmitting}
                      />
                      {country === "France" ? (
                        <FormInput
                          sx={{ mb: "0.5rem" }}
                          label={"SIRET"}
                          type="input"
                          name="siret"
                          required
                          disabled={methods.formState.isSubmitting}
                        />
                      ) : (
                        <FormInput
                          sx={{ mb: "0.5rem" }}
                          label={"TVA intra-communautaire"}
                          type="input"
                          name="vat"
                          required
                          disabled={methods.formState.isSubmitting}
                        />
                      )}
                      <FormInput
                        sx={{ mb: "0.5rem" }}
                        label="Activité"
                        type="input"
                        name="sector"
                        values={COMPANIES_SECTORS}
                        select
                        required
                        disabled={methods.formState.isSubmitting}
                      />
                    </Box>
                  )}

                  {/* Account registration */}
                  {step === 2 && (
                    <Box display="flex" flexDirection="column" component="form" noValidate autoComplete="off" width={1}>
                      <FormInput
                        sx={{ mb: "0.5rem" }}
                        label="Prénom"
                        type="input"
                        name="firstName"
                        required
                        disabled={methods.formState.isSubmitting}
                      />
                      <FormInput
                        sx={{ mb: "0.5rem" }}
                        label="Nom"
                        type="input"
                        name="lastName"
                        required
                        disabled={methods.formState.isSubmitting}
                      />
                      <FormInput
                        sx={{ mb: "0.5rem" }}
                        label="Adresse email"
                        type="input"
                        name="email"
                        autoComplete="off"
                        required
                        disabled={methods.formState.isSubmitting}
                      />
                      <FormInput
                        sx={{ mb: "0.5rem" }}
                        label="Mot de passe"
                        name="password"
                        defaultValue=""
                        autoComplete="new-password"
                        password
                        required
                        disabled={methods.formState.isSubmitting}
                      />
                    </Box>
                  )}
                </Box>

                <LoadingButton
                  loading={methods.formState.isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    width: 1,
                    marginInline: "auto",
                  }}
                >
                  {stepButtonText[step - 1]}
                </LoadingButton>
                <Stack sx={{ mt: "1rem", textAlign: "center" }}>
                  <Typography sx={{ fontSize: "0.9rem", mb: "1rem" }}>
                    Vous avez déjà un compte ? <LinkItem to="/login">Connectez-vous</LinkItem>
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignUpPage;
