import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Edit, TextInput, SimpleForm, Form, SaveButton, Toolbar, DeleteButton, useEditController, ArrayInput, SimpleFormIterator } from 'react-admin';
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";

const AttributesToolBar = ({ ...props }) => (
  <Toolbar>
    <SaveButton label="Sauvegarder" />
    <div style={{ flex: 1 }} />
    <DeleteButton label="Supprimer" />
  </Toolbar>
)

const AttributesEdit =  ({ ...props }) => {
  const editContext = useEditController();

  return (
    <Edit {...props} hasShow={false}>
      <SimpleForm>
        <Typography variant="body1" mb={"20px"}>
          Modification de l'attribut
        </Typography>
        <Grid container direction={"column"}>
          <TextInput required defaultValue={null} source="name" label="Nom de l'attribut" />
          <TextInput required defaultValue={null} source="description" label="Déscription de l'attribut" />
          <ArrayInput required source="values" label="Valeurs possibles">
            <SimpleFormIterator>
              <TextInput sx={{ mt: '10px'}} source="name" label="Nom" />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default AttributesEdit;
