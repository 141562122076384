import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import autocolors from "chartjs-plugin-autocolors";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, autocolors);

const position: "right" = "right";

export const options: any = {
  responsive: true,
  plugins: {
    legend: {
      position,
      maxWidth: 400,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 10,
        },
        boxWidth: 10,
        boxHeight: 10,
      },
    },
    autocolors,
  },
  scales: {
    y: {
      ticks: {
        precision: 0,
        beginAtZero: true,
        suggestedMin: 0,
        min: 0,
      },
    },
  },
};

const monthToLabel: any = {
  "01": "Jan",
  "02": "Fev",
  "03": "Mar",
  "04": "Avr",
  "05": "Mai",
  "06": "Juin",
  "07": "Juil",
  "08": "Aout",
  "09": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};

const Stats = ({ datesTotal, timeUnit, label, showLegend = true }: any) => {
  options.plugins.legend = showLegend
    ? {
        position,
        maxWidth: 400,
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 10,
          },
          boxWidth: 10,
          boxHeight: 10,
        },
      }
    : {
        display: false,
      };
  let data: any;
  //if data not displayed by crmGroup then length > 10
  if (Object.keys(datesTotal)[0].length <= 10) {
    data = {
      labels: Object.keys(datesTotal).map((date: any) => {
        const year = date.split("-")[0];
        const month = date.split("-")[1];
        if (timeUnit === "day") {
          const day = parseInt(date.split("-")[2]);
          return day + " " + monthToLabel[month];
        }
        return monthToLabel[month] + " " + year.slice(2, 4);
      }),
      datasets: [
        {
          fill: true,
          label,
          //hack to hide negative when all values are 0
          data: Object.values(datesTotal).map((val: number, i: number) => (i === 0 && val === 0 ? 0.00001 : val)),
          borderColor: "#00bd82",
          backgroundColor: "#01ac75",
        },
      ],
    };
  } else {
    data = {
      labels: Object.keys(datesTotal[Object.keys(datesTotal)[0]]).map((date: any) => {
        const year = date.split("-")[0];
        const month = date.split("-")[1];
        if (timeUnit === "day") {
          const day = parseInt(date.split("-")[2]);
          return day + " " + monthToLabel[month];
        }
        return monthToLabel[month] + " " + year.slice(2, 4);
      }),
      datasets: Object.keys(datesTotal).map((crmGroup: string) => {
        return {
          label: crmGroup.split("$")[1],
          //hack to hide negative when all values are 0
          data: Object.values(datesTotal[crmGroup]).map((val: number, i: number) =>
            i === 0 && val === 0 ? 0.00001 : val
          ),
        };
      }),
    };
  }
  return <Line options={options} data={data} />;
};

export default Stats;
