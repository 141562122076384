import * as firebase from "@firebase/auth-types";
import { collection, doc, updateDoc, getDoc, getDocs, query, where, arrayUnion } from "firebase/firestore";
import { AuthProvider } from "react-admin";
import { FirebaseDataProvider } from "react-admin-firebase/dist/src";
import { fbAuth, fbConfig, fbFirestore, fbApp } from "./firebase.services";
import { getAuth, signInWithEmailLink, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import api from "../apiWithAuth";
declare const window: any;

// Public routes with authentication bypass
export const PUBLIC_ROUTES = ["/sign-up", "/forgotten-password"];

const app = fbApp;

// const provider = FirebaseDataProvider(fbConfig, {logging: true, useFileNamesInStorage: true});
const provider = FirebaseDataProvider(fbConfig, { useFileNamesInStorage: true, app });

const customProvider = {
  ...provider,
  getOne: async (resource: string, params: any) => {
    const record: any = await provider.getOne(resource, params);
    const identity: any = await getIdentity();
    if (resource === "orders" && identity.status === "Collaborateur" && identity.showOrderNotifications) {
      if (!record.seenBy || (record.seenBy && !record.seenBy.includes(identity.id))) {
        await updateDoc(doc(fbFirestore, "orders", params.id), {
          seenBy: arrayUnion(identity.id),
        });
      }
    }
    if (resource === "opportunities" && identity.status === "Collaborateur" && identity.showOpportunityNotifications) {
      if (!record.seenBy || (record.seenBy && !record.seenBy.includes(identity.id))) {
        await updateDoc(doc(fbFirestore, "opportunities", params.id), {
          seenBy: arrayUnion(identity.id),
        });
      }
    }
    if (resource === "users") {
      const q = query(collection(fbFirestore, "groups"), where("users", "array-contains", record.data.id));
      const querySnapshot = await getDocs(q);
      record.data.crmGroupId = querySnapshot.empty ? "" : querySnapshot.docs[0].data().id;
    }
    if (resource === "subscriptions" && record.data.type === "Licence") {
      const licenses = await Promise.all(
        record.data.licenses.map(async (licenseId: string) => {
          const licenceRes = await api.get(`licenses/${licenseId}`);
          return licenceRes.data;
        })
      );
      record.data.licenses = licenses;
    }
    return record;
  },
  getList: async (resource: string, params: any) => {
    // it would be far more efficient to use a crmGroupId foreign key on users thant keep users in group as array, but
    // initially relation wasn't 1-N and some restrictions lead us to not use that schema at the beginning.
    // Now it would be a good idea to refactor but it would be time (and money) consuming because a lot of quite
    // complex code has been written.
    let list: any = {};
    const crmGroupId = params?.filter?.crmGroupId;
    if (resource === "users") {
      delete params.filter.crmGroupId;
    }
    if (resource === "users" && crmGroupId && crmGroupId !== "none") {
      delete params.filter.crmGroupId;
      const crmGroupSnapshot = await getDoc(doc(fbFirestore, "groups", crmGroupId as string));
      const userIds = crmGroupSnapshot?.data()?.users || [];
      list.data = [];
      for (const userId of userIds) {
        try {
          const response = await provider.getOne(resource, { id: userId });
          list.data.push(response.data);
        } catch (err) {
          console.log("error getting user ", userId, err);
        }
      }
      list.total = list.data.length;
    } else {
      list = await provider.getList(resource, params);
    }
    if (resource === "users") {
      for (let user of list.data) {
        const q = query(collection(fbFirestore, "groups"), where("users", "array-contains", user.id));
        const querySnapshot = await getDocs(q);
        //false mean that user is not in any group
        user.crmGroupId = querySnapshot.empty ? "" : querySnapshot.docs[0].data().id;
        user.inCrmGroup = !querySnapshot.empty;
      }
      if (crmGroupId) {
        list.data = list.data.filter((user: any) => {
          if (crmGroupId === "none") {
            return user.inCrmGroup === false;
          } else {
            return user.crmGroupId === crmGroupId;
          }
        });
      }
    }
    return list;
  },
};
export const fbDataProvider = customProvider;

// Identity
const getIdentity = async () => {
  if (fbAuth.currentUser) {
    const collaborator = await getDoc(doc(fbFirestore, "collaborators", fbAuth.currentUser?.email as string));
    if (collaborator.exists()) return { ...collaborator.data(), status: "Collaborateur" };
    const q = query(collection(fbFirestore, "users"), where("email", "==", fbAuth.currentUser?.email));
    const querySnapshot = await getDocs(q);
    const user = querySnapshot.docs[0].data();
    user.id = querySnapshot.docs[0].id;
    const company = await getDoc(doc(fbFirestore, "companies", user?.company as string));
    let parentCompanyData = {} as any;
    if (company.data()?.status === "Indirect") {
      const parentCompanyDoc = await getDoc(doc(fbFirestore, "companies", company.data()?.parentCompany as string));
      parentCompanyData = parentCompanyDoc.data();
    }
    return {
      ...user,
      status: company.data()?.status,
      type: company.data()?.type,
      freeTry: company.data()?.freeTry,
      parentCompany: company.data()?.parentCompany,
      parentCompanyData,
      companyAddress: company.data()?.address,
      companyCountry: company.data()?.country,
      companyName: company.data()?.company,
    };
  } else {
    return Promise.reject();
  }
};

// React-Admin Auth Provider for Firebase
export const fbAuthProvider: AuthProvider = {
  /**
   * Login: send username and password to the auth server and get back credentials
   * @param {*} param0 - Credentials
   * @returns Firebase signin promise
   */
  login: async (credentials, redirectTo = "") => {
    const auth = getAuth();
    // if different user delete cart
    if (credentials.username !== localStorage.getItem("userEmail")) {
      localStorage.removeItem("cart");
    }
    localStorage.removeItem("discount");
    localStorage.removeItem("checkoutStep");
    localStorage.removeItem("clientSecret");
    localStorage.removeItem("customerPaymentMethods");
    localStorage.removeItem("paymentIntentId");
    localStorage.removeItem("orderReference");
    localStorage.removeItem("prevPaymenIntentTotals");
    localStorage.setItem("userEmail", credentials.username);
    if (!credentials.password) {
      await signInWithEmailLink(auth, credentials.username, window.location.href);
    } else {
      await signInWithEmailAndPassword(auth, credentials.username, credentials.password);
    }
  },

  /**
   * Check Auth: when the user navigates, make sure that their credentials are still valid
   * @returns Firebase checks promise
   */
  checkAuth: async () => {
    return new Promise<void>((resolve, reject) => {
      // Bypass public routes
      if (PUBLIC_ROUTES.includes(window.location.pathname)) {
        return resolve();
      }

      // Check user auth
      const auth = getAuth();
      const unsub = onAuthStateChanged(auth, (user) => {
        if (user) {
          unsub();
          return resolve();
        } else {
          reject({ redirectTo: "/login", message: false });
        }
      });
    });
  },

  /**
   * Logout: remove local credentials and notify the auth server that the user logged out
   * @returns Firebase signout promise
   */
  logout: async () => {
    return fbAuth.signOut();
  },

  /**
   * Get Identity: Get the user's profile
   * @returns Firebase entity (TODO: Get form collection)
   */
  getIdentity: async (): Promise<any> => getIdentity(),

  // Unused provider functions
  checkError: async (error: any) => Promise.resolve(),

  // It is possible to reduce lines using some map functions on resource arrays and flatten it at the end
  // but this is less readable
  getPermissions: async (params: any): Promise<any> => {
    const identity: any = await getIdentity();
    if (identity.status === "Collaborateur") {
      return [
        { action: "*", resource: "*" },
        { type: "deny", action: "*", resource: "shop" },
        { type: "deny", action: "*", resource: "my-company" },
        { type: "deny", action: "*", resource: "download" },
        { type: "deny", action: "clone", resource: "subscriptions" },
        { type: "deny", action: "clone", resource: "companies" },
        { type: "deny", action: "clone", resource: "licenses" },
        { type: "deny", action: ["delete", "clone"], resource: "admin" },
        { type: "deny", action: ["export", "clone"], resource: "admin" },
      ];
    }
    if (identity.status === "Revendeur") {
      return [
        // global
        { type: "deny", action: "clone", resource: "users" },
        { type: "deny", action: "clone", resource: "orders" },
        { type: "deny", action: "clone", resource: "paymentMethods" },
        { type: "deny", action: "clone", resource: "subscriptions" },
        { type: "deny", action: "clone", resource: "companies" },
        { type: "deny", action: "clone", resource: "licenses" },
        { type: "deny", action: "clone", resource: "contacts" },
        { type: "deny", action: "clone", resource: "contactLists" },
        { type: "deny", action: "clone", resource: "transfers" },
        { type: "deny", action: "clone", resource: "groups" },
        { type: "deny", action: "clone", resource: "sms" },

        // custom routes
        { action: "*", resource: "shop" },
        { action: "*", resource: "download" },
        { action: "*", resource: "credits" },
        { action: "*", resource: "contactsOrContactLists" },

        // users
        { action: "*", resource: "users" },
        { action: "*", resource: "users.*" },

        // orders
        { action: "*", resource: "orders" },
        { action: "*", resource: "orders.*" },
        { type: "deny", action: ["delete"], resource: "orders" },

        // paymentMethods
        { action: "*", resource: "paymentMethods" },
        { action: "*", resource: "paymentMethods.*" },

        // subscriptions
        { action: "*", resource: "subscriptions" },
        { action: "*", resource: "subscriptions.*" },
        { type: "deny", action: ["delete"], resource: "subscriptions" },
        { type: "deny", action: ["read", "write"], resource: "subscriptions.cancelled" },

        // companies
        { action: "*", resource: "companies" },
        { action: "*", resource: "companies.*" },
        { type: "deny", action: ["read", "write"], resource: "companies.status" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.account" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.users", record: { id: identity.company } },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.groups", record: { id: identity.company } },
        {
          type: "deny",
          action: ["read", "write"],
          resource: "companies.tab.subscriptions",
          record: { id: identity.company },
        },
        {
          type: "deny",
          action: ["read", "write"],
          resource: "companies.tab.licenses",
          record: { id: identity.company },
        },
        {
          type: "deny",
          action: ["read", "write"],
          resource: "companies.tab.templates",
          record: { id: identity.company },
        },
        {
          type: "deny",
          action: ["read", "write"],
          resource: "companies.tab.contactsOrContactLists",
          record: { id: identity.company },
        },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.sms", record: { id: identity.company } },

        // licences
        { action: "*", resource: "licenses" },
        { action: "*", resource: "licenses.*" },
        { type: "deny", action: ["delete"], resource: "licenses" },

        // templates
        { action: "*", resource: "templates" },
        { action: "*", resource: "templates.*" },

        // contacts
        { action: "*", resource: "contacts" },
        { action: "*", resource: "contacts.*" },

        // contact lists
        { action: "*", resource: "contactLists" },
        { action: "*", resource: "contactLists.*" },

        // transfers
        { action: "*", resource: "transfers" },
        { action: "*", resource: "transfers.*" },

        // groups
        { action: "*", resource: "groups" },
        { action: "*", resource: "groups.*" },

        // sms
        { action: "*", resource: "sms" },
        { action: "*", resource: "sms.*" },
        //{ type: 'deny', action: ["read", "write", "edit"], resource: 'subscriptions.company_id' },
        // { action: ["list", "show", 'read', 'edit'], resource: 'subscriptions', record: { "company_id": identity.company }},
        // { action: ["read", "edit"], resource: 'subscriptions.*', record: { "company_id": identity.company } },
        // { type: 'deny', action: ["delete"], resource: 'subscriptions', record: { "company_id": identity.company } },
        // { type: 'deny', action: ["read", "write"], resource: 'subscriptions.active' },
        //{ action: ["read", "edit"], resource: 'licenses.*', record: { "group_id": identity.company } },
      ];
    }
    if (["Client", "Indirect"].includes(identity.status)) {
      return [
        // global
        { type: "deny", action: "clone", resource: "users" },
        { type: "deny", action: "clone", resource: "orders" },
        { type: "deny", action: "clone", resource: "paymentMethods" },
        { type: "deny", action: "clone", resource: "subscriptions" },
        { type: "deny", action: "clone", resource: "companies" },
        { type: "deny", action: "clone", resource: "licenses" },
        { type: "deny", action: "clone", resource: "contacts" },
        { type: "deny", action: "clone", resource: "contactLists" },
        { type: "deny", action: "clone", resource: "transfers" },
        { type: "deny", action: "clone", resource: "groups" },
        { type: "deny", action: "clone", resource: "sms" },

        // custom routes
        { action: "*", resource: "shop" },
        { action: "*", resource: "download" },
        { action: "*", resource: "credits" },
        { action: "*", resource: "contactsOrContactLists" },

        // users
        { action: "*", resource: "users", record: { company: identity.company } },
        { action: "*", resource: "users.*", record: { company: identity.company } },
        { type: "deny", action: ["write"], resource: "users.company" },

        // orders
        { action: "*", resource: "orders" },
        { action: "*", resource: "orders.*" },
        { type: "deny", action: ["delete"], resource: "orders" },

        // paymentMethods
        { action: "*", resource: "paymentMethods" },
        { action: "*", resource: "paymentMethods.*" },

        //subscriptions
        { action: "*", resource: "subscriptions", record: { company_id: identity.company } },
        { action: "*", resource: "subscriptions.*", record: { company_id: identity.company } },
        { type: "deny", action: ["delete"], resource: "subscriptions" },
        { type: "deny", action: ["read", "write"], resource: "subscriptions.cancelled" },

        // companies
        { action: "*", resource: "companies", record: { id: identity.company } },
        { action: "*", resource: "companies.*", record: { id: identity.company } },
        { type: "deny", action: ["delete"], resource: "companies" },
        { type: "deny", action: ["read", "write"], resource: "companies.status" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.account" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.users" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.groups" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.subscriptions" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.licenses" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.templates" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.contactsOrContactLists" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.sms" },

        // licences
        { action: "*", resource: "licenses", record: { group_id: identity.company } },
        { action: "*", resource: "licenses.*", record: { group_id: identity.company } },
        { type: "deny", action: ["delete"], resource: "licenses" },
        { type: "deny", action: ["read", "write"], resource: "licenses.group_id" },
        { type: "deny", action: ["read", "write"], resource: "licenses.disabled" },
        { type: "deny", action: ["read", "write"], resource: "licenses.isAdmin" },

        // templates
        { action: "*", resource: "templates", record: { group_id: identity.company } },
        { action: "*", resource: "templates.*", record: { group_id: identity.company } },
        { type: "deny", action: ["read", "write"], resource: "templates.group_id" },

        // contacts
        { action: "*", resource: "contacts", record: { group_id: identity.company } },
        { action: "*", resource: "contacts.*", record: { group_id: identity.company } },
        { type: "deny", action: ["read", "write"], resource: "contacts.group_id" },

        // contact lists
        { action: "*", resource: "contactLists", record: { group_id: identity.company } },
        { action: "*", resource: "contactLists.*", record: { group_id: identity.company } },
        { type: "deny", action: ["read", "write"], resource: "contactLists.group_id" },

        // transfers
        { action: "*", resource: "transfers" },
        { action: "*", resource: "transfers.*" },

        // groups
        { action: "*", resource: "groups", record: { group_id: identity.company } },
        { action: "*", resource: "groups.*", record: { group_id: identity.company } },

        // sms
        { action: "*", resource: "sms", record: { group_id: identity.company } },
        { action: "*", resource: "sms.*", record: { group_id: identity.company } },
      ];
    }
    return [{ type: "deny", action: "*", resource: "*" }];
  },
};
