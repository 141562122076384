import { Grid, Typography, TextField as MuiTextField } from "@mui/material";
import React from "react";
import {
  BooleanInput,
  DateField,
  TextInput,
  useDataProvider,
  Labeled,
  FormDataConsumer,
  SelectInput,
  required,
  useUpdate,
  useGetIdentity,
  ReferenceInput,
  AutocompleteInput,
  useNotify,
} from "react-admin";
import { Edit, SimpleForm } from "@react-admin/ra-rbac";
import { DELAYS } from "../../services/common-utils/constants";
import SmsTextInput from "../../components/inputs/SmsInput";
import { count } from "sms-length";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import PageTitle from "../../components/navigation/PageTitle";

const validateTemplateEdit = (values: any) => {
  let errors = {} as any;
  ["confirmation_message", "reminder_message", "alert_message"].forEach((input: string) => {
    if (values[input]) {
      const smsInfos = count(values[input]);
      if (smsInfos.length > 1377) errors[input] = "Maximum 9 SMS";
    }
  });
  if (values.customExpeditor) {
    if (values.expeditor.length > 11) {
      errors["expeditor"] = "Maximum 11 caractères";
    }
    if (values.expeditor.indexOf(" ") !== -1) {
      errors["expeditor"] = "Espaces interdits";
    }
  }
  return errors;
};

const TemplatesEdit = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  const redirect = useCustomListRedirect();
  const notify = useNotify();

  // Transform override
  const onTransform = async (data: any, { previousData }: any) => {
    if (!data.customExpeditor) {
      data.expeditor = "";
    }
    if (!data.showAlert) {
      data.alert_message = "";
      data.alert_delay = 0;
    }
    delete data.customExpeditor;
    if (["Client", "Indirect"].includes(identity?.status)) {
      data.group_id = identity?.company;
    }
    data.oldCrmGroupId = previousData?.crmGroupId;
    return data;
  };

  const onSuccess = async (data: any) => {
    notify(`Modèle modifié avec succès`, { type: "success" });
    redirect("templates");
  };

  return (
    <Edit {...props} transform={onTransform} mutationMode="optimistic" mutationOptions={{ onSuccess }} hasShow={false}>
      <PageTitle text={(record: any) => `Modèle ${record.name}`} />
      <SimpleForm validate={validateTemplateEdit}>
        {["Collaborateur", "Revendeur"].includes(identity?.status) && (
          <ReferenceInput
            required
            source="group_id"
            reference="companies"
            filter={identity?.status === "Revendeur" ? { parentCompany: identity?.company } : {}}
            sort={{ field: "company", order: "ASC" }}
            perPage={1000}
          >
            <AutocompleteInput
              label="Organisation"
              optionText="company"
              filterToQuery={(search: any) => ({ company: search })}
            />
          </ReferenceInput>
        )}
        <TextInput required source="name" label="Nom du modèle" />
        <BooleanInput defaultValue={true} source="group_access" label="Visible par tout les utilisateurs" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.group_access === false && (
              <ReferenceInput
                required
                source="crmGroupId"
                reference="groups"
                filter={{
                  company: ["Collaborateur", "Revendeur"].includes(identity?.status)
                    ? formData.group_id
                    : identity?.company,
                }}
                sort={{ field: "name", order: "ASC" }}
                perPage={1000}
              >
                <AutocompleteInput
                  label="Groupe d'accès au modèle"
                  optionText="name"
                  filterToQuery={(search: any) => ({ name: search })}
                  helperText="Seuls les utilisateurs du groupe pourront accéder au modèle"
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <div style={{ borderBottom: "1px solid lightgray", width: "100%", marginBottom: "28px", marginTop: "0px" }} />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <BooleanInput
              defaultValue={!!formData.expeditor}
              source="customExpeditor"
              label="Personnaliser l'émetteur"
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.customExpeditor && (
              <TextInput
                source="expeditor"
                defaultValue={null}
                label="Émetteur"
                {...rest}
                helperText="11 caractères au maximum"
              />
            )
          }
        </FormDataConsumer>

        <SmsTextInput source="confirmation_message" defaultValue={null} label="Message de confirmation" />

        <SmsTextInput source="reminder_message" label="Message de rappel" />
        <SelectInput
          defaultValue={1440}
          validate={required()}
          source="reminder_delay"
          label="Délai avant rappel"
          choices={DELAYS}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <BooleanInput
              defaultValue={!!formData.alert_message}
              source="showAlert"
              label="Envoyer un message d'alerte"
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.showAlert && <SmsTextInput source="alert_message" label="Message d'alerte" />
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.showAlert && (
              <SelectInput
                defaultValue={1440}
                validate={required()}
                source="alert_delay"
                label="Délai avant alerte"
                choices={DELAYS}
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default TemplatesEdit;
