import React from "react";
import { CreateButton, DateField, TextField, TextInput, Toolbar } from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import PageTitle from "../../components/navigation/PageTitle";

const DownloadsList = ({ ...props }) => {
  return (
    <>
      <PageTitle text="Téléchargements" backButton={false} />
      <List
        {...props}
        actions={
          <Toolbar sx={{ background: "white" }}>
            <CreateButton label="Ajouter un logiciel" />
          </Toolbar>
        }
        exporter={false}
        empty={false}
      >
        <DigisoftDatagrid>
          <TextField label="Nom du logiciel" source="name" />
          <TextField label="Version" source="version" />
        </DigisoftDatagrid>
      </List>
    </>
  );
};

export default DownloadsList;
