import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Typography,
  Box,
  Card,
  FormGroup,
  Paper,
  ButtonBase,
  FormControlLabel,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import api from "../../../services/apiWithAuth";

import { CardElement, IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
import StripeCard from "./StripeCard";
import StripeSepa from "./StripeSepa";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import FormInput from "../../../components/form/FormInput";
import { useInterval } from "usehooks-ts";
import { useGetIdentity, useGetOne } from "react-admin";

const CheckoutPayment = ({
  handleCheckoutCompleted,
  handleCheckoutFailed,
  cart,
  customerInfos,
  customerPaymentMethods,
  setPaymentFormSubmitting,
  setPaymentErrorMessage,
}: any) => {
  const theme = useTheme();
  const { data: identity } = useGetIdentity();
  const { data: company } = useGetOne("companies", { id: identity?.company });
  const [paymentType, setPaymentType] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const elements = useElements();
  const methods = useForm();
  const [customerCards, setCustomerCards] = useState([] as any);
  const [customerIbans, setCustomerIbans] = useState([] as any);
  const [message, setMessage] = useState("");
  const [useNewCard, setUseNewCard] = useState(false);
  const [useNewIban, setUseNewIban] = useState(false);
  const stripe = useStripe();
  const visa = require("../../../assets/visa.png") as string;
  const mastercard = require("../../../assets/mastercard.png") as string;
  const amex = require("../../../assets/amex.png") as string;
  const sepa = require("../../../assets/sepa.png") as string;

  useEffect(() => {
    if(customerPaymentMethods.length) {
      setCustomerCards(customerPaymentMethods.filter((paymentMethod: any) => paymentMethod.type === "card"))
      setCustomerIbans(customerPaymentMethods.filter((paymentMethod: any) => paymentMethod.type === "sepa_debit"))
    }
  }, [customerPaymentMethods])
  

  useInterval(
    () => {
      const storedClientSecret = localStorage.getItem("clientSecret");
      if (storedClientSecret) {
        setClientSecret(storedClientSecret);
      }
    },
    !clientSecret ? 500 : null
  );

  useEffect(() => {
    setPaymentFormSubmitting(methods.formState.isSubmitting);
  }, [methods.formState.isSubmitting, setPaymentFormSubmitting]);

  const onSubmit = async (data: any) => {
    if (!stripe || !elements || !clientSecret || cart.length === 0) {
      return;
    }
    setPaymentErrorMessage("");
    const billingDetails = {
      address: {
        line1: customerInfos.address,
        postal_code: customerInfos.zipCode,
        city: customerInfos.city,
        //country: customerInfos.billingCountry,
      },
      email: customerInfos.email,
      name: customerInfos.firstName + " " + customerInfos.lastName,
      phone: customerInfos.contactPhone,
    };
    let res: any;
    try {
      if (paymentType === "card") {
        const card = elements.getElement(CardElement);
        if (((!customerCards.length || useNewCard) && !card) || (customerCards.length && !useNewCard && !data?.card)) {
          setPaymentErrorMessage("Veuiller choisir un mode de paiement");
          return;
        }
        if (data?.card && useNewCard) {
          data.card = "";
        }
        res = await stripe.confirmCardPayment(clientSecret, {
          receipt_email: customerInfos.email,
          payment_method: data?.card || {
            card,
            billing_details: billingDetails,
          },
        });
        console.log("stripe res", res)
      } else {
        const iban = elements.getElement(IbanElement);
        if (((!customerIbans.length || useNewIban) && !iban) || (customerIbans.length && !useNewIban && !data?.iban)) {
          setPaymentErrorMessage("Veuiller choisir un mode de paiement");
          return;
        }
        if (data?.iban && useNewIban) {
          data.iban = "";
        }
        res = await stripe.confirmSepaDebitPayment(clientSecret, {
          receipt_email: customerInfos.email,
          payment_method: data?.iban || {
            sepa_debit: iban,
            billing_details: billingDetails,
          },
        });
      }
    } catch(err) {
      console.log("error catched", err)
      setPaymentErrorMessage("Erreur lors du paiement, merci de réessayer avec un autre moyen de paiement.");
      return;
    }

    const error = res?.error || null;

    if (error) {
      console.log(error.message || "Erreur lors du paiement, merci de réessayer avec un autre moyen de paiement.");
      setPaymentErrorMessage("Erreur lors du paiement, merci de réessayer avec un autre moyen de paiement ou contactez un administrateur.");
    } else {
      handleCheckoutCompleted(paymentType);
      setMessage("Paiement réussi! ");
    }
  };

  return (
    <Box
      sx={{
        mb: 2,
        mt: 3,
        width: 1,
        "& .MuiInputBase-root": { width: 1 },
        "& .MuiFormControl-root": { width: 1 },
      }}
    >
      <Card sx={{ p: 3, width: 1 }}>
        <FormProvider {...methods}>
          <Box id="payment-form" component="form" autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
            <Typography variant="h4" m={2}>
              Modes de paiement
            </Typography>
            <div style={{ color: "red", fontSize: "1.5rem" }}>{message}</div>
            {!clientSecret ? (
              <CircularProgress sx={{ m: 5, ml: 10 }} />
            ) : (
              <>
                {" "}
                <Paper sx={{ backgroundColor: theme.palette.mode === "light" ? "white" : "inherit", p: 2 }}>
                  <Box
                    display="flex"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: 1,
                      ":hover": { cursor: "pointer" },
                    }}
                    alignItems="center"
                    onClick={() => setPaymentType("card")}
                  >
                    <FormGroup sx={{ width: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUnchecked />}
                            checkedIcon={<CheckCircle />}
                            color="success"
                            checked={paymentType === "card"}
                          />
                        }
                        label="Carte bancaire"
                      />
                    </FormGroup>
                    <Box display="flex" sx={{ flexDirection: "row" }} gap="15px">
                      <img src={visa} width="70px" alt="carte visa" />
                      <img src={mastercard} width="70px" alt="mastercard" />
                      <img src={amex} width="70px" alt="carte american express" />
                    </Box>
                  </Box>
                  {paymentType === "card" && (
                    <Box display="flex" sx={{ flexDirection: "flex-start" }} alignItems="center" mt={2}>
                      <Box sx={{ width: [1, 0.5] }}>
                        {customerCards.length > 0 && (
                          <>
                            <Typography>Utiliser une de vos cartes enregistrées</Typography>
                            <FormInput
                              type="input"
                              name="card"
                              label="Sélectionner une carte"
                              values={customerCards.map((paymentMethod: any) => {
                                return {
                                  id: paymentMethod.id,
                                  label: "**** **** **** " + paymentMethod.card.last4,
                                  cardType: paymentMethod.card.brand,
                                  expMonth: paymentMethod.card.exp_month,
                                  expYear: paymentMethod.card.exp_year,
                                };
                              })}
                              select
                              disabled={useNewCard}
                            />
                            <FormGroup sx={{ width: 1 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<RadioButtonUnchecked />}
                                    checkedIcon={<CheckCircle />}
                                    color="primary"
                                    checked={useNewCard}
                                    onClick={() => setUseNewCard(!useNewCard)}
                                  />
                                }
                                label="Enregistrer une nouvelle carte"
                              />
                            </FormGroup>
                          </>
                        )}
                        {(customerCards.length === 0 || useNewCard) && (
                          <>
                            <StripeCard />
                            <Typography fontSize="0.8rem">
                              Aucune information bancaire n'est stockée sur ce site, le paiement se fait directement sur
                              le serveur de votre banque.
                            </Typography>
                          </>
                        )}
                      </Box>
                      {/* submit form with id */}
                    </Box>
                  )}
                </Paper>
                <Paper sx={{ backgroundColor: theme.palette.mode === "light" ? "white" : "inherit", p: 2, mt: 2 }}>
                  <Box
                    display="flex"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: 1,
                      ":hover": { cursor: "pointer" },
                    }}
                    alignItems="center"
                    onClick={() => setPaymentType("sepa")}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUnchecked />}
                            checkedIcon={<CheckCircle />}
                            color="success"
                            checked={paymentType === "sepa"}
                          />
                        }
                        label="SEPA"
                      />
                    </FormGroup>
                    <img src={sepa} width="70px" alt="prélèvement sepa" />
                  </Box>
                  {paymentType === "sepa" && (
                    <Box display="flex" sx={{ flexDirection: "flex-start" }} alignItems="center" mt={2}>
                      <Box sx={{ width: [1, 0.5] }}>
                        {customerIbans.length > 0 && (
                          <>
                            <Typography>Utiliser un de vos mandats SEPA enregistrées</Typography>
                            <FormInput
                              type="input"
                              name="iban"
                              label="Sélectionner un mandat de prélèvement"
                              values={customerIbans.map((paymentMethod: any) => {
                                return {
                                  id: paymentMethod.id,
                                  label: `${paymentMethod.sepa_debit.country}**${paymentMethod.sepa_debit.bank_code}${paymentMethod.sepa_debit.branch_code}*********${paymentMethod.sepa_debit.last4}`,
                                };
                              })}
                              disabled={useNewIban}
                              select
                            />
                            <FormGroup sx={{ width: 1 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<RadioButtonUnchecked />}
                                    checkedIcon={<CheckCircle />}
                                    color="primary"
                                    checked={useNewIban}
                                    onClick={() => setUseNewIban(!useNewIban)}
                                  />
                                }
                                label="Enregistrer un nouvel IBAN"
                              />
                            </FormGroup>
                          </>
                        )}
                        {(customerIbans.length === 0 || useNewIban) && (
                          <>
                            <StripeSepa />
                            <Typography fontSize="0.8rem">
                              En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez
                              (A) VENTUS et Stripe, notre prestataire de services de paiement et/ou PPRO, son
                              prestataire de services local, à envoyer des instructions à votre banque pour débiter
                              votre compte et (B) votre banque à débiter votre compte conformément à ces instructions.
                              Vous avez, entre autres, le droit de vous faire rembourser par votre banque selon les
                              modalités et conditions du contrat conclu avec votre banque. La demande de remboursement
                              doit être soumise dans un délai de 8 semaines à compter de la date à laquelle votre compte
                              a été débité. Vos droits sont expliqués dans une déclaration disponible auprès de votre
                              banque. Vous acceptez de recevoir des notifications des débits à venir dans les 2 jours
                              précédant leur réalisation.
                            </Typography>
                          </>
                        )}
                      </Box>
                      {/* submit form with id */}
                    </Box>
                  )}
                </Paper>
              </>
            )}
          </Box>
        </FormProvider>
      </Card>
    </Box>
  );
};

export default CheckoutPayment;
