import React, { useState } from "react";
import { TextInput } from "react-admin";
import { count } from "sms-length";
import { useRecordContext } from "react-admin";
import { Typography } from "@mui/material";

const getHelperFromSms = (Sms: string) => {
  const {length, messages, remaining} = count(Sms || '')
  let warning = "";
  if((length+remaining)/messages <=70) {
    warning = "Attention : votre texte contient au moins un caractère unicode, la longueur d'un SMS est donc réduite et la quantité de crédits utilisée peut être augmentée"
  }
  return <Typography fontSize={"0.8rem"} width={"350px"}>{length}/{length+remaining} = {messages} SMS <br/>{warning ? <span style={{color: "orange", fontWeight: "bold"}}>{warning}</span> : ""}</Typography>
}

const SmsTextInput = ( {source, label, validator, ...props}: any ) => {
  const record = useRecordContext(props);
  const [helper, setHelper] = useState(getHelperFromSms(record ? record[source] : ''));

  const handleChange = (event: any) => {
    setHelper(getHelperFromSms(event.target.value))
  };
  
  return (
    <TextInput source={source} label={label} onChange={handleChange} helperText={helper} multiline {...props}/>
  );
};

export default SmsTextInput