import * as React from "react";
import { AppBar, Logout, useGetIdentity, UserMenu } from "react-admin";
import { Box, Typography, useMediaQuery, Theme, IconButton, Badge, useTheme } from "@mui/material";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import "./Topbar.css";
import MyAccount from "./MyAccount";
import stc from "string-to-color";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import Notifications from "./Notifications";
import HelpPanel from "./HelpPanel";

const CustomUserMenu = () => {
  const { identity } = useGetIdentity();
  const theme = useTheme();

  return (
    <UserMenu
      icon={
        <div
          style={{
            fontSize: "1.1rem",
            width: "40px",
            height: "40px",
            borderRadius: "999px",
            backgroundColor: stc(`${identity?.firstName} ${identity?.lastName}`),
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            color: theme.palette.mode === "light" ? "white" : "black",
          }}
        >
          {`${identity?.firstName[0] || ""}${identity?.lastName[0] || ""}`.toUpperCase()}
        </div>
      }
    >
      <MyAccount />
      <Logout />
    </UserMenu>
  );
};

const TopBar = (props: any) => {
  const { identity } = useGetIdentity();
  const [cart, setCart] = useLocalStorage("cart", [] as any[]);
  const navigate = useNavigate();
  const logo = require("../../assets/logo.svg").default as string;
  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up("sm"));
  return (
    <>
      <AppBar
        {...props}
        sx={{
          borderBottom: "1px solid lightgray",
          boxShadow: 0,
          padding: 2,
          ".MuiButtonBase-root": {
            height: 50,
          },
          ".MuiSvgIcon-root": {
            color: "grey",
            fontSize: 35,
          },
        }}
        elevation={1}
        userMenu={<CustomUserMenu />}
      >
        {isLargeEnough && <img src={logo} alt="digisoft" className="digisoft-logo" />}
        <Box component="span" sx={{ flex: 1 }} />
        {identity?.status !== "Collaborateur" && <HelpPanel identity={identity} />}
        {identity?.status !== "Collaborateur" && (
          <IconButton onClick={() => navigate("/checkout")}>
            <Badge
              badgeContent={cart.length}
              color="primary"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <LocalMallIcon sx={{ fontSize: 35 }} />
            </Badge>
          </IconButton>
        )}
        {identity?.status === "Collaborateur" &&
          (identity?.showOrderNotifications || identity?.showOpportunityNotifications) && (
            <Notifications identity={identity} />
          )}
      </AppBar>
      <div style={{ height: "25px" }} />
    </>
  );
};

export default TopBar;
