import { Grid, Typography, Button } from '@mui/material';
import React, { useState, useCallback } from 'react';
import { Edit, TextInput, useDelete, SaveButton, Toolbar, useRecordContext, TabbedForm, FormTab, SelectInput, BooleanInput, Confirm, Button as RaButton, useGetIdentity, useNotify, useRedirect, useUpdate, PasswordInput, FormDataConsumer } from 'react-admin';
import { TITLES, SERVICES, COUNTRIES, CRM_ROLES, STATUSES } from '../../services/common-utils/constants';
import { getAuth, updatePassword } from "firebase/auth";
import PageTitle from '../../components/navigation/PageTitle';


const CollaboratorsToolBar = ({ ...props }) => (
  <Toolbar>
    <SaveButton label="Sauvegarder" />
    <div style={{ flex: 1 }} />
    <CustomDelete />
  </Toolbar>
)

const CustomDelete = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const redirect = useRedirect();
  const notify = useNotify();

  const [deleteOne, { isLoading, error }] = useDelete(
    'collaborators',
    { id: record.id, previousData: record }
  );
  if (error) { return <p>ERROR</p>; }

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    deleteOne();
    setOpen(false);
    notify("Collaborateur supprimé avec succès", {
      type: "success",
      messageArgs: { smart_count: 1 },
    });
    redirect("list", "collaborators");
  };

  return (
    <>
      <RaButton label="Supprimer" onClick={handleClick} sx={{color: 'red'}}/>
      <Confirm
          isOpen={open}
          loading={isLoading}
          title="Suppression collaborateur"
          content="Etes-vous sur de vouloir supprimer votre compte collaborateur ? Toutes les données liées à celui-ci seront supprimées."
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
      />
    </>
  );
};

const Form =  ({ ...props }) => {
  const [changePassword, setChangePassword] = useState(false)
  const { data: identity } = useGetIdentity()
  const redirect = useRedirect();
  const notify = useNotify();
  const record = useRecordContext();

  const [update] = useUpdate();
  const save = useCallback(
    async (values: any) => {
      const errors = {} as any;
      if(values.password) {
        const auth = getAuth()
        const user = auth.currentUser
        try {
          if(user) {
            await updatePassword(user, values.password)
          } else {
            errors.password = "...";
            return errors;
          }
        } catch(err) {
          errors.password = "Erreur lors de la mise à jour du mot de passe car session utilisateur trop longue. Déconnectez et reconnectez vous avant de refaire l'opération.";
          return errors;
        }
        delete values.password
      }
      try {
        await update(
          'collaborators',
          { id: record.id, data: values, previousData: record },
          { returnPromise: true }
        );
        notify('Utilisateur mis à jour avec succès', {
            type: 'success',
            messageArgs: { smart_count: 1 },
        });
        redirect('list', 'collaborators');
      } catch (error) {}
    },
    [update, notify, redirect, record]
  );

  return !identity ? null : 
    <TabbedForm toolbar={<CollaboratorsToolBar />} onSubmit={save}>
      <FormTab label="Géneral">
        <Typography variant="h6" mb={"20px"}>
          Informations générales
        </Typography>
        <Grid container direction={"column"}>
          <SelectInput source="title" label="Civilité" choices={TITLES.map((s) => ({ id: s, name: s}))}/>
          <TextInput source="firstName" label="Prénom"  />
          <TextInput source="lastName" label="Nom de famille" />
          <SelectInput source="role" label="Role CRM" choices={CRM_ROLES.map((s) => ({ id: s, name: s}))}/>
          <TextInput source="email" label="Adresse email" />
          <Typography variant="body2" sx={{ marginTop: '-20px', marginBottom: '16px', marginLeft: '5px', color: "gray" }}>
            Cet e-mail est utilisée pour se connecter au CRM
          </Typography>
          <SelectInput source="service" choices={SERVICES.map((s) => ({ id: s, name: s}))} />
          <TextInput defaultValue={null} source="proMobilePhone" label="Numéro mobile PRO" />
          <TextInput defaultValue={null} source="proPhone" label="Numéro de téléphone PRO" />
          <BooleanInput defaultValue={false} source="showOrderNotifications" label="Afficher les notifications de nouvelle commande" />
          <BooleanInput defaultValue={false} source="showOpportunityNotifications" label="Afficher les notifications relatifves aux opportunités" />
        </Grid>
      </FormTab>

      {/* Personnal infos form */}
      <FormTab label="Informations privées">
        <Typography variant="h6" mb={"20px"}>
          Contact personnel
        </Typography>
        <Grid container direction={"column"}>
          <TextInput defaultValue={null} source="personalDetails.mobilePhone" label="Numéro mobile PERSO"  />
          <TextInput defaultValue={null} source="personalDetails.phone" label="Numéro fix PERSO" />
        </Grid>
        <Typography variant="h6" mb={"20px"}>
          Adresse personnelle
        </Typography>
        <Grid container direction={"column"}>
          <TextInput defaultValue={null} source="personalDetails.address.address" label="Rue et numéro" />
          <TextInput defaultValue={null} source="personalDetails.address.additionalAddress" label="Complément d'adresse" />
          <TextInput defaultValue={null} source="personalDetails.address.city" label="Ville" />
          <SelectInput defaultValue="France" source="personalDetails.address.country" label="Pays" choices={COUNTRIES.map((s) => ({ id: s, name: s}))}/>
        </Grid>
      </FormTab>

      {record.id === identity?.id &&
      <FormTab label="Sécurité">
        {['Super Admin', 'Administrateur'].includes(identity?.role) && <SelectInput defaultValue="Actif" source="accountStatus" label="Statut" choices={STATUSES.map((s) => ({ id: s, name: s}))}/>}
        <Typography variant="h6" mb={"20px"}>
          Mot de passe
        </Typography>
        <Grid container direction={"column"}>
        <Button variant='outlined' onClick={() => {setChangePassword(!changePassword)}} sx={{marginLeft: 0, maxWidth: '300px'}}>
          Créer/modifier mot de passe
        </Button>
        <Typography variant="body1" mb={"20px"} maxWidth={"300px"} fontSize="12px">
          Pour pouvoir vous connecter avec un mot de passe en plus de la connexion par lien email ou pour modifier le mot de passe actuel
        </Typography>
        { changePassword && <PasswordInput defaultValue={null} source="password" label="Mot de passe"/>}
        </Grid>
      </FormTab>}
    </TabbedForm>
};

const CollaboratorsEdit =  ({ ...props }) => {

  return (
    <Edit {...props} hasShow={false}>
      <PageTitle text={(record: any) => `${record.firstName} ${record.lastName}`}/>
      <Form {...props} />
    </Edit>
  );
}

export default CollaboratorsEdit;

