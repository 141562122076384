import { Typography, Box } from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import WarningIcon from "@mui/icons-material/Warning";
import {
  DateField,
  FunctionField,
  useGetIdentity,
  Labeled,
  BooleanInput,
  ReferenceField,
  TextField,
  Toolbar,
  SaveButton,
  useRecordContext,
  Button as RaButton,
  Confirm,
  useDelete,
  useUpdate,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  useNotify,
} from "react-admin";
import { Edit, FormTab, IfCanAccess, TabbedForm } from "@react-admin/ra-rbac";
import PaymentMethodInput from "../../components/fields/PaymentMethodInput";
import api from "../../services/apiWithAuth";
import { MANUAL_PAYMENT_MODES } from "../../services/common-utils/constants";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import PaymentsField from "../../components/fields/PaymentsField";
import SubscriptionQuantityInput from "./SubscriptionQuantityInput";
import PageTitle from "../../components/navigation/PageTitle";

const CustomDelete = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const redirect = useCustomListRedirect();
  const notify = useNotify();

  const [deleteOne, { isLoading, error }] = useDelete("subscriptions", { id: record.id, previousData: record });
  if (error) {
    return <p>ERROR</p>;
  }

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    deleteOne();
    setOpen(false);
    notify("Abonnement supprimé avec succès", {
      type: "success",
      messageArgs: { smart_count: 1 },
    });
    redirect("subscriptions");
  };

  return (
    <>
      <RaButton label="Supprimer" onClick={handleClick} sx={{ color: "red" }} />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Suppression abonnement"
        content="Etes-vous sur de vouloir supprimer cet abonnement ? Les licences rattachées seront supprimées."
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const actions: any = {
  save: {
    buttonText: "Valider",
    buttonColor: "primary",
    modalTitle: "Modification abonnement",
    modalText: "Etes vous sûr de vouloir apporter des modifications à cet abonnement ?",
  },
  createStripeSubscription: {
    buttonText: "Passer en renouvellement automatique",
    buttonColor: "primary",
    modalTitle: "Passage en renouvellement automatique",
    modalText:
      "En passant cet abonnement en renouvellement automatique, vous serez désormais facturés automatiquement à chaque fin de période indiquée.",
  },
  replacePaymentMethod: {
    buttonText: "Remplacer moyen de paiement",
    buttonColor: "primary",
    modalTitle: "Remplacement moyen de paiement",
    modalText:
      "En remplaçant le mode de paiement précédent par un nouveau, vous acceptez que tous les prélèvements récurrents soient effectués sur celui-ci.",
  },
  cancel: {
    buttonText: "Annuler abonnement",
    buttonColor: "warning",
    modalTitle: "Annulation abonnement",
    modalText:
      "En annulant cet abonnement toutes les licences rattachées (s'il y en a) seront désactivées à la fin de la période de validité.",
  },
  uncancel: {
    buttonText: "Réactiver l'abonnement",
    buttonColor: "success",
    modalTitle: "Réactivation abonnement",
    modalText: "En réactivant cet abonnement vous reprendrez votre cycle normal de facturation",
  },
  nextPeriodPaid: {
    buttonText: "Prolonger l'abonnement",
    buttonColor: "success",
    modalTitle: "Prolongation abonnement",
    modalText:
      "En prolongeant l'abonnement, les licences associées seront prolongées d'une durée égale à la période de facturation de ce dernier.",
  },
  resetFromNow: {
    buttonText: "Reprendre l'abonnement à aujourd'hui",
    buttonColor: "success",
    modalTitle: "Reprise abonnement à compter d'aujourd'hui",
    modalText:
      "En reprenant votre abonnement à partir d'aujourd'hui, vous serez facturé immédiatement sur le mode de paiement renseigné et vos licences seront réactivées.",
  },
  solveDispute: {
    buttonText: "Résoudre litige et reprendre l'abonnement",
    buttonColor: "success",
    modalTitle: "Résolution litige et reprise d'abonnement à compter d'aujourd'hui",
    modalText: (
      <Typography>
        En choisissant de résoudre le litige portant sur votre abonnement dû à une erreur de paiement et de reprendre
        l'abonnement à partir d'aujourd'hui, vous serez facturé immédiatement sur le mode de paiement renseigné du
        montant de votre abonnement <b>+ 7.50€ TTC de frais de résolution de litige.</b> <br /> <br /> Vous aurez
        ensuite de nouveau accés à vos licences/crédits SMS. <br /> <br />
        <span style={{ color: "#ed6c02" }}>
          Attention il est préférable de vous assurez que le moyen de paiement choisi est toujours valable pour éviter
          une nouvelle fois les 7.50€ de frais de résolution.
        </span>
      </Typography>
    ),
  },
};

const CustomSave = ({ action = "save", identity }: any) => {
  const [update] = useUpdate();
  const { getValues } = useFormContext();
  const record = useRecordContext();
  const redirect = useCustomListRedirect();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const handleClick = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    const values = getValues();
    const licensesToDelete = values?.licensesToDelete || [];
    const quantityToAdd = values?.quantityToAdd || 0;
    const quantityAction = values?.quantityAction || "";
    values.licenses = values?.licenses?.map((license: any) => license.uuid) || [];
    delete values.quantityToAdd;
    delete values.quantityAction;
    if (action === "cancel") {
      if (values.stripe_id) {
        try {
          await api.post("/utils/cancelStripeSubscription", {
            subscriptionId: values.stripe_id,
          });
        } catch (err) {
          notify("Erreur lors l'annulation de l'abonnement", {
            type: "error",
            messageArgs: { smart_count: 1 },
          });
          setOpen(false);
          return;
        }
      }
      values.cancelled = true;
      values.cancellationDate = Date.now();
    }
    if (action === "uncancel") {
      if (values.stripe_id) {
        try {
          await api.post("/utils/uncancelStripeSubscription", {
            subscriptionId: values.stripe_id,
          });
        } catch (err) {
          notify("Erreur lors de la réactivation de l'abonnement", {
            type: "error",
            messageArgs: { smart_count: 1 },
          });
          setOpen(false);
          return;
        }
      }
      values.cancelled = false;
    }
    if (action === "resetFromNow") {
      try {
        if (values.autoRenewal) {
          // create stripe sub but instead of putting first bill at periodEnd, make it start from now
          // if stripe sub already exist we need to create another one because it is not possible to reset cancelled subscriptions
          try {
            const {
              data: { stripe_id, newPaymentMethod, resetPeriodStart, resetPeriodEnd },
            } = await api.post("/utils/createStripeSubscription", {
              subscription: values,
              paymentMethodId: values.paymentMethod.id,
              resetMode: true,
            });
            values.periodStart = resetPeriodStart;
            values.periodEnd = resetPeriodEnd;
            values.paymentMethod = newPaymentMethod;
            values.stripe_id = stripe_id;
            values.paymentStatus = "Paiement en cours";
          } catch (err) {
            notify("Erreur lors du passage à la facturation automatique lors de la reprise d'abonnement", {
              type: "error",
              messageArgs: { smart_count: 1 },
            });
            setOpen(false);
            return;
          }
        } else {
          // staying in manual mode, only collaborators can reset it from now : just change dates of subscription and licenses
          try {
            const {
              data: { periodStart, periodEnd },
            } = await api.post("/utils/setNextSubscriptionPeriodAsPaid", {
              subscription: values,
              resetMode: true,
            });
            values.periodStart = periodStart;
            values.periodEnd = periodEnd;
            delete values.paymentMethod;
          } catch (err) {
            notify("Erreur lors de la prolongation de l'abonnement.", {
              type: "error",
              messageArgs: { smart_count: 1 },
            });
            setOpen(false);
            return;
          }
        }
        values.cancelled = false;
      } catch (err) {
        notify("Erreur lors de la reprise de l'abonnement.", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
        setOpen(false);
        return;
      }
    }
    if (action === "solveDispute") {
      try {
        const {
          data: { stripe_id, newPaymentMethod, resetPeriodStart, resetPeriodEnd },
        } = await api.post("/utils/createStripeSubscription", {
          subscription: values,
          paymentMethodId: values.paymentMethod.id,
          resetMode: true,
          chargeDispute: true,
        });
        values.periodStart = resetPeriodStart;
        values.periodEnd = resetPeriodEnd;
        values.paymentMethod = newPaymentMethod;
        values.stripe_id = stripe_id;
        values.createdManuallyInAuto = false; //to not fall inside it in stripe webhook
      } catch (err) {
        notify("Erreur lors de la reprise de l'abonnement.", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
        setOpen(false);
        return;
      }
    }
    if (action === "nextPeriodPaid") {
      if (values.autoRenewal) {
        notify("Il n'est pas possible de prolonger manuellement un abonnement automatique.", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
        setOpen(false);
        return;
      }
      try {
        const {
          data: { periodStart, periodEnd },
        } = await api.post("/utils/setNextSubscriptionPeriodAsPaid", {
          subscription: values,
        });
        values.periodStart = periodStart;
        values.periodEnd = periodEnd;
        delete values.paymentMethod;
      } catch (err) {
        notify("Erreur lors de la prolongation de l'abonnement.", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
        setOpen(false);
        return;
      }
    }
    if (action === "createStripeSubscription") {
      try {
        const {
          data: { stripe_id, newPaymentMethod },
        } = await api.post("/utils/createStripeSubscription", {
          subscription: values,
          paymentMethodId: values.paymentMethod.id,
        });
        values.paymentMethod = newPaymentMethod;
        values.stripe_id = stripe_id;
      } catch (err) {
        notify("Erreur lors du passage à la facturation automatique", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
        setOpen(false);
        return;
      }
    }
    if (action === "replacePaymentMethod") {
      try {
        const { data: newPaymentMethod } = await api.post("/paymentMethods/replace", {
          subscriptionId: record.stripe_id,
          newPaymentMethodId: values.paymentMethod.id,
          company: record.company_id,
        });
        values.paymentMethod = newPaymentMethod;
      } catch (err) {
        notify("Erreur lors du changement de mode de paiement.", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
        setOpen(false);
      }
    }
    try {
      //change quantity of licenses
      if (quantityAction) {
        try {
          if (quantityAction === "add" && quantityToAdd <= 0) {
            notify(`Erreur rajout licences : la quantité à ajouter n'est pas valide`, {
              type: "error",
              messageArgs: { smart_count: 1 },
              autoHideDuration: 3000,
            });
            setOpen(false);
            return;
          }
          if (quantityAction === "delete" && licensesToDelete.length <= 0) {
            notify(`Erreur licences à supprimer : aucune licence n'a été renseignée`, {
              type: "error",
              messageArgs: { smart_count: 1 },
              autoHideDuration: 3000,
            });
            setOpen(false);
            return;
          }
          if (quantityAction === "delete" && licensesToDelete.length === record.quantity) {
            notify(`Vous ne pouvez pas supprimer toutes vos licences, pour cela vous devez annuler votre abonnement`, {
              type: "error",
              messageArgs: { smart_count: 1 },
              autoHideDuration: 5000,
            });
            setOpen(false);
            return;
          }
          values.licensesToDelete = quantityAction === "delete" ? licensesToDelete : [];
          let priceCoeff;
          if (quantityAction === "add") {
            priceCoeff = (record.quantity + quantityToAdd) / record.quantity;
          } else if (quantityAction === "delete") {
            priceCoeff =
              (record.quantity - (licensesToDelete?.length || 0)) /
              (record.quantity - (record?.licensesToDelete?.length || 0));
          } else {
            // cancel deletion
            priceCoeff = record.quantity / (record.quantity - (record?.licensesToDelete?.length || 0));
          }
          values.billingPrice = Math.round(values.billingPrice * priceCoeff * 100) / 100;
          values.billingPriceTtc = Math.round(values.billingPriceTtc * priceCoeff * 100) / 100;
          const {
            data: { newLicenses },
          } = await api.post("/utils/changeSubscriptionQuantity", {
            subscription: values,
            licensesToCreateNumber: quantityToAdd,
          });
          //? Be careful : quantity will not be updated immediately but after subscription payment
          values.licenses = newLicenses;
          values.quantity = newLicenses.length;
        } catch (err) {
          notify("Erreur lors de la modification du nombre de licences.", {
            type: "error",
            messageArgs: { smart_count: 1 },
          });
          setOpen(false);
          return;
        }
      }
      await update("subscriptions", { id: record.id, data: values, previousData: record }, { returnPromise: true });
      notify("Abonnement mis à jour avec succès", {
        type: "success",
        messageArgs: { smart_count: 1 },
      });
      redirect("subscriptions");
    } catch (error) {
      notify("Erreur lors de l'enregistrement.", {
        type: "error",
        messageArgs: { smart_count: 1 },
      });
    }
    setOpen(false);
  };

  return (
    <>
      <SaveButton
        onClick={handleClick}
        label={actions[action].buttonText}
        alwaysEnable={
          !["createStripeSubscription", "replacePaymentMethod", "save"].includes(action) ||
          (action === "resetFromNow" && identity?.status === "Collaborateur")
        }
        disabled={
          (action !== "save" && getValues().quantityAction) ||
          (["createStripeSubscription"].includes(action) &&
            !(getValues().autoRenewal && !!getValues().paymentMethod.id)) ||
          (action === "resetFromNow" &&
            identity?.status !== "Collaborateur" &&
            !record.autoRenewal &&
            !getValues().autoRenewal) || // only collab are able to reset manual subscription
          (action === "replacePaymentMethod" &&
            (!(getValues().autoRenewal && !!getValues().paymentMethod.id) ||
              getValues().paymentMethod.id === record.paymentMethod.id)) ||
          //! Do something to be able to cancel subscription only if payment method, autoRenewal or quantity have not changed
          // ((action === "cancel" || action === "uncancel") &&
          //   (getValues()?.paymentMethod?.id !== record?.paymentMethod?.id ||
          //     getValues().quantity !== record.quantity)) ||
          (action === "save" &&
            ((record.autoRenewal &&
              getValues().autoRenewal &&
              getValues().paymentMethod.id !== record.paymentMethod.id) || // replace payment method
              (getValues().autoRenewal && (!record.paymentMethod || Object.keys(record.paymentMethod).length === 0)))) // go from manual to auto renewal
        }
        color={action === "save" ? "primary" : actions[action].buttonColor}
      />
      <Confirm
        isOpen={open}
        title={
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
            <WarningIcon sx={{ color: "primary.main", fontSize: "48px", mr: 2 }} />
            <Typography variant="h5" color="primary.main" fontWeight={"bold"}>
              {actions[action].modalTitle}
            </Typography>
          </Box>
        }
        content={
          action === "save" && getValues().quantityAction ? (
            <Typography>
              Êtes vous sur de modifier les quantités de licences sur votre abonnement ? <br /> <br /> Cela entrainera
              une facturation immédiate si vous avez augmenté votre nombre de licences, ou une suppression des licences
              à la fin de la période en cours si vous avez l'avez diminué. Cela changera également le montant des
              prochaines factures.
            </Typography>
          ) : (
            actions[action].modalText
          )
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        sx={{
          "& .MuiButton-root": { color: "warning.main" },
          "& .RaConfirm-confirmPrimary": { color: "success.main" },
        }}
      />
    </>
  );
};

const EditToolbar = (props: any) => {
  const { isLoading, data: identity } = useGetIdentity();
  const record = useRecordContext();

  return isLoading || !(window.location.pathname.split("/").length === 3) ? null : (
    <Toolbar {...props} sx={{ flexDirection: "row", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 2 }}>
        <CustomSave />
        {identity?.status === "Collaborateur" &&
          !record.cancelled &&
          !record.autoRenewal &&
          Date.now() > record.periodStart * 1000 && <CustomSave identity={identity} action="nextPeriodPaid" />}
        {/* possible to convert to autorenewal if not expired/cancelled and obviously not yet in autorenewal mode */}
        {!record.cancelled &&
          !record.autoRenewal &&
          Date.now() > record.periodStart * 1000 &&
          Date.now() <= record.periodEnd * 1000 && <CustomSave identity={identity} action="createStripeSubscription" />}
        {/* possible replace payment mode if not cancelled/expired and in autorenewal mode */}
        {!record.cancelled && record.autoRenewal && Date.now() <= record.periodEnd * 1000 && (
          <CustomSave identity={identity} action="replacePaymentMethod" />
        )}
        {/* if subscription cancelled and expired we can reset it to today date */}
        {!record?.paymentStatus && record.cancelled && Date.now() > record.periodEnd * 1000 && (
          <CustomSave identity={identity} action="resetFromNow" />
        )}
        {!record?.paymentStatus && record.cancelled && Date.now() <= record.periodEnd * 1000 && (
          <CustomSave identity={identity} action="uncancel" />
        )}
        {record?.paymentStatus === "Litige" && <CustomSave identity={identity} action="solveDispute" />}
        {!record.cancelled && <CustomSave identity={identity} action="cancel" />}
      </Box>

      <IfCanAccess action="delete">
        <CustomDelete />
      </IfCanAccess>
    </Toolbar>
  );
};

const Form = () => {
  const record = useRecordContext();
  const { isLoading, data: identity } = useGetIdentity();

  return isLoading ? null : (
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="Géneral" name="general">
        <Typography variant="h6" mb={"20px"}>
          Informations générales
        </Typography>
        <Labeled sx={{ mb: 2 }} label="Référence">
          <TextField source="id" />
        </Labeled>
        <ReferenceField source="company_id" reference="companies">
          <Labeled label="Organisation">
            <TextField source="company" mb={"20px"} />
          </Labeled>
        </ReferenceField>
        <Labeled label="Date de souscription initiale">
          <DateField source="subscriptionDate" mb={"20px"} />
        </Labeled>
        <Labeled label="Statut abonnement">
          <FunctionField mb={"20px"} render={(record: any) => (record.cancelled ? "Annulé" : "actif")} />
        </Labeled>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !formData.cancelled ? null : formData.cancellationDate ? (
              <Labeled label="Date d'annulation">
                <DateField mb={"20px"} source="cancellationDate" label="Payé le" showTime />
              </Labeled>
            ) : (
              <Labeled label="Date d'annulation">
                <FunctionField mb={"20px"} render={(record: any) => "Inconnue"} />
              </Labeled>
            )
          }
        </FormDataConsumer>
        <Labeled label="Cycle de facturation">
          <TextField source="billingPeriod" mb={"20px"} />
        </Labeled>
        <Labeled label="Période de facturation actuelle">
          <FunctionField
            mb={"20px"}
            render={(record: any) =>
              record.cancelled
                ? "aucune"
                : !record.periodStart
                ? ""
                : "Du " +
                  new Date(record.periodStart * 1000).toLocaleDateString("fr") +
                  " au " +
                  new Date(record.periodEnd * 1000).toLocaleDateString("fr")
            }
          />
        </Labeled>
        <Labeled label="Prochaine facturation le">
          <FunctionField
            render={(record: any) => new Date(record.periodEnd * 1000).toLocaleDateString("fr")}
            mb={"20px"}
          />
        </Labeled>
        <Labeled label="Montant facturation">
          <FunctionField
            render={(record: any) =>
              `${Number(record.billingPrice).toFixed(2)}€ HT (${Number(record.billingPriceTtc).toFixed(2)}€ TTC)`
            }
            mb={"20px"}
          />
        </Labeled>
        {identity?.status === "Collaborateur" && (
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.discount ? null : (
                <Labeled sx={{ mb: "20px" }} label="Remise">
                  <ReferenceField source="discount" reference="discounts">
                    <TextField source="name" />
                  </ReferenceField>
                </Labeled>
              )
            }
          </FormDataConsumer>
        )}
        {(identity?.status === "Collaborateur" || record.autoRenewal === false) && (
          <BooleanInput
            defaultValue={false}
            disabled={record.autoRenewal}
            source="autoRenewal"
            label="Renouvellement automatique"
          />
        )}

        <div style={{ borderBottom: "1px solid lightgray", width: "100%", marginBottom: "28px", marginTop: "16px" }} />

        <Typography variant="h6" mb={"20px"}>
          Produit
        </Typography>
        <ReferenceField source="product_id" reference="products">
          <Labeled label="Produit">
            <TextField source="name" mb={"20px"} />
          </Labeled>
        </ReferenceField>
        {record.type === "Licence" &&
        (identity?.status === "Collaborateur" || (identity?.status !== "Collaborateur" && record.autoRenewal)) ? (
          <SubscriptionQuantityInput />
        ) : record.type === "Licence" ? (
          <Labeled label="Quantité licences">
            <TextField source="quantity" mb={"20px"} />
          </Labeled>
        ) : (
          <Labeled label="Quantité SMS">
            <TextField source="quantity" mb={"20px"} />
          </Labeled>
        )}

        <div style={{ borderBottom: "1px solid lightgray", width: "100%", marginBottom: "28px", marginTop: "16px" }} />

        <Typography variant="h6" mb={"20px"}>
          Moyen de paiement
        </Typography>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.autoRenewal && (
              <ReferenceInput
                label="Mode de paiement"
                source="paymentMethod.id"
                reference="paymentMethods"
                filter={{ group_id: record.company_id }}
                perPage={1000}
              >
                <PaymentMethodInput
                  style={{ marginBottom: "20px" }}
                  helperText="Pour utiliser un nouveau moyen de paiement, le créer au préalable dans votre organisation"
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !formData.autoRenewal && (
              <SelectInput
                source="manualPaymentMode"
                defaultValue={null}
                label="Mode de paiement manuel"
                choices={MANUAL_PAYMENT_MODES.map((s) => ({ id: s, name: s }))}
              />
            )
          }
        </FormDataConsumer>
      </FormTab>
      <FormTab
        label="Paiements"
        name="payments"
        sx={{
          "& .RaDatagrid-root": { width: 1 },
          "& .RaFileInput-root": { maxWidth: "300px" },
          "& .MuiTextField-root": { maxWidth: "300px" },
          "& .MuiInputBase-root": { width: 1 },
        }}
      >
        <PaymentsField target="subscription_id" />
      </FormTab>
    </TabbedForm>
  );
};

const SubscriptionsEdit = ({ ...props }) => {
  return (
    <Edit {...props} hasShow={false}>
      <PageTitle text={(record: any) => `Abonnement ${record.id}`} />
      <Form {...props} />
    </Edit>
  );
};

export default SubscriptionsEdit;
