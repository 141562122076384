import { Grid } from '@mui/material';
import React from 'react';
import { CreateButton, ExportButton, FunctionField, ReferenceField, TextField, Toolbar } from 'react-admin';
import { List } from '@react-admin/ra-rbac';
import { DigisoftDatagrid } from '../../components/react-admin/DigisoftDatagrid';
import { IAttribute } from './IAttribute';

const AttributesList = ({ ...props }) => {
  return (
    <List {...props} actions={
      <Toolbar>
        <CreateButton label="Nouvel attribut" />
        <ExportButton label="Exporter" />
      </Toolbar>
    }>
      <DigisoftDatagrid>
        <ReferenceField label="Nom" source="id" reference="attributes">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="description" label="Description" />
        
        <FunctionField
          label="Valeurs"
          render={(record: IAttribute) => {
            return (<Grid container direction={"row"} gap="10px">
              {record.values.map(v =>
                <div style={{
                  background: 'grey',
                  width: 'fit-content',
                  color: 'white',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  paddingTop: '4px',
                  paddingBottom: '4px',
                  borderRadius: '6px',
                  fontWeight: 'bold'
                }}>
                  {v.name}
                </div>
              )}
            </Grid>
            )
          }}
        />
      </DigisoftDatagrid>
    </List>
  );
}

export default AttributesList;
