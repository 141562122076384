import React, { Fragment, useCallback } from "react";
import {
  CreateButton,
  useGetIdentity,
  DateField,
  Filter,
  TextInput,
  TextField,
  Toolbar,
  FunctionField,
} from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import PageTitle from "../../components/navigation/PageTitle";

const GroupsList = ({ ...props }) => {
  const { isLoading, data: identity } = useGetIdentity();
  return isLoading ? null : (
    <>
      <PageTitle text="Groupes" backButton={false} />
      <List
        sort={{ field: "name", order: "ASC" }}
        filter={{ company: props.company || identity?.company }}
        filters={
          <Filter {...props}>
            <TextInput label="Rechercher" source="name" alwaysOn />
          </Filter>
        }
        {...props}
        exporter={false}
        empty={false}
        actions={
          <Toolbar>
            <CreateButton label="Nouveau Groupe" />
          </Toolbar>
        }
      >
        <DigisoftDatagrid>
          <TextField source="name" label="Nom du groupe" />
          <FunctionField
            label="Nombre d'utilisateurs"
            render={(record: any) => {
              return <div>{record?.users?.length || 0}</div>;
            }}
          />
          <DateField source="lastupdate" label="Mis à jour le" showTime />
        </DigisoftDatagrid>
      </List>
    </>
  );
};

export default GroupsList;
