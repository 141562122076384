import React from "react";
import {
  AutocompleteInput,
  CreateButton,
  DateField,
  ExportButton,
  Filter,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import { ICollaborator } from "./ICollaborator";
import PageTitle from "../../components/navigation/PageTitle";

const CollaboratorsList = ({ ...props }) => (
  <>
    <PageTitle text="Collaborateurs" backButton={false}/>
    <List
      {...props}
      filters={
        <Filter {...props}>
          <TextInput label="Prénom" source="firstName" alwaysOn />
          <TextInput label="Nom" source="lastName" alwaysOn />
        </Filter>
      }
      sort={{ field: "firstName", order: "ASC" }}
      actions={
        <Toolbar>
          <CreateButton label="Nouveau Collaborateur" />
        </Toolbar>
      }
    >
      <DigisoftDatagrid>
        <ReferenceField label="Nom complet" source="id" reference="collaborators">
          <FunctionField
            render={(record: ICollaborator) => {
              const formattedFirstName = record.firstName[0].toUpperCase() + record.firstName.slice(1);
              const formattedLastName = record.lastName.toUpperCase();
              return <b>{`${formattedFirstName} ${formattedLastName}`}</b>;
            }}
          />
        </ReferenceField>
        <TextField source="service" />
        <TextField source="role" />
        <FunctionField
          label="Statut"
          render={(record: ICollaborator) => {
            const isActive = record.accountStatus === "Actif";
            return (
              <div
                style={{
                  background: isActive ? "#58A14E" : "#D05031",
                  width: "fit-content",
                  color: "white",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  borderRadius: "6px",
                  fontWeight: "bold",
                }}
              >
                {record.accountStatus}
              </div>
            );
          }}
        />
        <DateField label="Modifié le" source="lastupdate" showTime={true} />
      </DigisoftDatagrid>
    </List>
  </>
);

export default CollaboratorsList;
