import { Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { Create, TextInput, SimpleForm, SelectInput, useNotify, useRedirect } from "react-admin";
import { CRM_ROLES, SERVICES, TITLES } from "../../services/common-utils/constants";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
import PageTitle from "../../components/navigation/PageTitle";

const CollaboratorsCreate = ({ ...props }) => {
  const notify = useNotify();
  const redirect = useRedirect();

  // Used to first signup the account
  const onTansform = async (data: any) => {
    return {
      ...data,
      id: data.email,
      accountStatus: "Actif",
    };
  };

  // Success override
  const onSuccess = (data: any) => {
    notify(`Compte créé avec succès`, { type: "success" });
    redirect("edit", "collaborators", data.id, data);
  };

  return (
    <Create {...props} transform={onTansform} mutationOptions={{ onSuccess }}>
      <SimpleForm>
        <PageTitle text="Création d'un nouveau client" divider />

        <Grid container direction={"column"}>
          <TextInput required source="email" label="Adresse email" />
          <Typography
            variant="body2"
            sx={{ marginTop: "-20px", marginBottom: "16px", marginLeft: "5px", color: "gray" }}
          >
            Cet e-mail sera utilisée pour se connecter au CRM. Le collaborateur devra procéder à une première connexion
            par lien magique.
          </Typography>
        </Grid>

        <div style={{ borderBottom: "1px solid lightgray", width: "100%", marginBottom: "28px", marginTop: "16px" }} />

        <Typography variant="body1" mb={"20px"}>
          Informations du compte
        </Typography>

        <Grid container direction={"column"}>
          <SelectInput required source="title" label="Civilité" choices={TITLES.map((s) => ({ id: s, name: s }))} />
          <TextInput required source="firstName" label="Prénom" />
          <TextInput required source="lastName" label="Nom de famille" />
          <SelectInput required source="role" label="Role CRM" choices={CRM_ROLES.map((s) => ({ id: s, name: s }))} />
          <SelectInput source="service" choices={SERVICES.map((s) => ({ id: s, name: s }))} />
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CollaboratorsCreate;
