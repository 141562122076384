import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Link as MuiLink,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  autocompleteClasses,
  useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FC, useState, useEffect, useContext } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "../../components/form/FormInput";
import styled from "@emotion/styled";
import React from "react";
import { useLocalStorage } from "usehooks-ts";
import "./LoginPage.css";
import { fbAuthProvider } from "../../services/firebase/firebase.providers";
import { getAuth, isSignInWithEmailLink, sendSignInLinkToEmail } from "firebase/auth";
import api from "../../services/api";
import { HistoryContext } from "../../services/historyProvider";

// Styled React Route Dom Link Component
export const LinkItem = styled(Link)`
  text-decoration: none;
  color: #3683dc;
  &:hover {
    text-decoration: underline;
    color: #5ea1b6;
  }
`;

// Login Schema with Zod
const loginSchema = object({
  email: string().min(1, "Adresse mail requise").email("Adresse mail invalide"),
  password: string(),
  signinType: string(),
})
  .refine((schema) => (schema.signinType === "password" ? schema.password.length > 0 : true), {
    message: "Mot de passe requis",
    path: ["password"],
  })
  .refine((schema) => (schema.signinType === "password" ? schema.password.length >= 8 : true), {
    message: "Le mot de passe doit contenir plus de 8 caractères",
    path: ["password"],
  });

// Infer the Schema to get the TS Type
type ILogin = TypeOf<typeof loginSchema>;

const LoginPage: FC = () => {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  const logoPath = require("../../assets/logo.svg").default as string;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [emailForSignIn, setEmailForSignIn] = useLocalStorage("emailForSignIn", "" as string);
  const [signinType, setSigninType] = useState("password");
  const [isEmailLinkPresent, setIsEmailLinkPresent] = useState(false);
  const [isEmailLinkChecked, setIsEmailLinkChecked] = useState(false);
  const history: string[] = useContext(HistoryContext);
  const [fromUrl, setFromUrl] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    //history from historyContext can be not already updated
    if (history.length && !fromUrl && history[0] === window.location.pathname) {
      const matches: any = history
        .map((path: string) => {
          if (path && (path.includes("login") || path.includes("sign-up") || path.includes("forgotten-password"))) {
            return null;
          } else {
            return path;
          }
        })
        .filter((match: string) => match !== null);
      setFromUrl(matches.length ? matches[0] : "none");
    }
  }, [setFromUrl, fromUrl, history]);

  // Get query parameter value
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const fromRegister = query.get("fromRegister");

  // Default Values
  const defaultValues: ILogin = {
    email: "",
    password: "",
    signinType: "password",
  };

  // The object returned from useForm Hook
  const methods = useForm<ILogin>({
    resolver: zodResolver(loginSchema),
    defaultValues,
  });

  // Submit Handler
  const onSubmitHandler: SubmitHandler<ILogin> = async (values: ILogin) => {
    setError("");

    // Login via firebase provider
    try {
      if (values.signinType === "password") {
        await fbAuthProvider.login({ username: values.email, password: values.password });
        let urlToRedirect: string;
        if (signinType === "password") {
          urlToRedirect = fromUrl && fromUrl !== "none" ? fromUrl : "/";
        } else {
          urlToRedirect = "/login?fromRegister=true";
        }
        navigate(urlToRedirect);
      } else {
        const auth = getAuth();
        let { data: userExists } = await api.post("/utils/checkUserExists", { email: values.email });
        if (userExists) {
          await sendSignInLinkToEmail(auth, values.email, {
            url: window.location.href.split("?")[0],
            handleCodeInApp: true,
          });
          setEmailForSignIn(values.email);
          navigate("/login?fromRegister=true");
        } else {
          setError("Identifiants incorrects");
        }
      }
    } catch (err) {
      setError("Identifiants incorrects");
    }
    setIsLoading(false);
  };

  const handleSigninTypeChange = (event: React.MouseEvent<HTMLElement>, newSigninType: string) => {
    if (newSigninType !== null) {
      methods.setValue("signinType", newSigninType);
      setSigninType(newSigninType);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    if (!isEmailLinkChecked && isSignInWithEmailLink(auth, window.location.href)) {
      setIsEmailLinkPresent(true);
    }
    setIsEmailLinkChecked(true);
  }, [isEmailLinkChecked]);

  useEffect(() => {
    if (isEmailLinkPresent) {
      let email = emailForSignIn || "";
      //! could be necessary if authentication with phone
      // if (!email) {
      //   email = window.prompt('Merci de confirmer votre adresse email.') as string;
      // }
      fbAuthProvider.login({ username: email }).then((res) => navigate("/"));
    }
  }, [emailForSignIn, setEmailForSignIn, navigate, isEmailLinkPresent]);

  // JSX to be rendered
  return (
    <Container maxWidth={false} sx={{ height: "100vh", backgroundColor: ["#fff", "#cff3bd"] }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%" }}>
        <Grid item sx={{ backgroundColor: "#fff", width: [1, 500], mt: -5 }}>
          <FormProvider {...methods}>
            <Grid
              container
              position="relative"
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                py: [2, 6],
                px: [3, 8],
              }}
            >
              {isEmailLinkPresent ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: [0.5, 1] }}
                >
                  <img
                    src={logoPath}
                    alt="digisoft"
                    className="digisoft-logo-login"
                    style={{ width: "100%", maxWidth: "260px", height: "auto" }}
                  />
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={methods.handleSubmit(onSubmitHandler)}
                  sx={{ width: 1, "& .MuiFormControl-root": { width: 1, mb: 2 }, "& .MuiInputBase-root": { width: 1 } }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: [0.5, 1] }}
                  >
                    <img
                      src={logoPath}
                      alt="digisoft"
                      className="digisoft-logo-login"
                      style={{ width: "100%", maxWidth: "260px", height: "auto" }}
                    />
                  </Box>

                  {!fromRegister && (
                    <>
                      <Typography
                        color="primary"
                        sx={{ textAlign: "center", fontWeight: "bold", fontSize: ["1.6rem", "2rem"] }}
                      >
                        Connectez-vous
                      </Typography>
                      <Typography sx={{ textAlign: "center", fontSize: "1rem", mt: -1 }}>
                        Choisissez votre méthode de connexion
                      </Typography>
                    </>
                  )}

                  <Typography variant="subtitle1" component="h1" sx={{ textAlign: "center", mb: 3, color: "#CD5C5C" }}>
                    {error}
                  </Typography>

                  {fromRegister && (
                    <Typography
                      variant="subtitle1"
                      component="h1"
                      sx={{ textAlign: "center", mb: "1.5rem", color: "#4BB543", maxWidth: "300px" }}
                    >
                      {"Un lien de connexion vous a été envoyé par mail. Vous pouvez fermer cet onglet."}
                    </Typography>
                  )}

                  {!fromRegister && (
                    <>
                      <ToggleButtonGroup
                        color="primary"
                        value={signinType}
                        exclusive
                        onChange={handleSigninTypeChange}
                        aria-label="Platform"
                        sx={{
                          mt: [0, 2],
                          mb: 2,
                          width: 1,
                        }}
                      >
                        <ToggleButton
                          value="password"
                          sx={{
                            width: 0.5,
                            fontSize: "1rem",
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "white",
                              bgcolor: "primary.main",
                            },
                          }}
                        >
                          Classique
                        </ToggleButton>
                        <ToggleButton
                          value="emailLink"
                          sx={{
                            width: 0.5,
                            fontSize: "1rem",
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "white",
                              bgcolor: "primary.main",
                            },
                          }}
                        >
                          Magique
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <FormInput label={"none"} type="input" name="signinType" required style={{ display: "none" }} />
                      <Box width={1} height={170}>
                        <FormInput
                          label="Saisissez votre adresse mail"
                          type="email"
                          name="email"
                          defaultValue=""
                          InputLabelProps={{ required: false, shrink: true }}
                          required
                          disabled={isLoading}
                        />
                        {signinType === "emailLink" && (
                          <Typography sx={{ fontSize: "0.9rem", mb: "1rem", color: "#9f9d9d" }}>
                            Vous allez recevoir un lien unique pour vous connecter
                          </Typography>
                        )}
                        {signinType === "password" && (
                          <FormInput
                            label="Mot de passe"
                            name="password"
                            defaultValue=""
                            password
                            required
                            InputLabelProps={{ required: false, shrink: true }}
                            disabled={isLoading}
                            helperText={<LinkItem to="/forgotten-password">Mot de passe oublié?</LinkItem>}
                          />
                        )}
                      </Box>

                      <LoadingButton
                        loading={methods.formState.isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          width: 1,
                          marginInline: "auto",
                        }}
                      >
                        Se connecter {">>"}
                      </LoadingButton>
                      <Stack sx={{ mt: "1rem", textAlign: "center" }}>
                        <Typography sx={{ fontSize: "0.9rem", mb: "1rem" }}>
                          Vous n'avez pas de compte ? <LinkItem to="/sign-up">Inscrivez-vous</LinkItem>
                        </Typography>
                      </Stack>
                    </>
                  )}
                </Box>
              )}
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginPage;
