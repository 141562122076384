import React from "react";
import {
  CreateButton,
  DateField,
  ReferenceField,
  Filter,
  TextField,
  TextInput,
  Toolbar,
  useGetIdentity,
  ReferenceInput,
  AutocompleteInput,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  FunctionField,
  ReferenceArrayField,
  CloneButton,
  SelectInput,
} from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import { Typography } from "@mui/material";
import PageTitle from "../../components/navigation/PageTitle";

const TemplatesList = ({ ...props }) => {
  const { isLoading, identity } = useGetIdentity();

  return isLoading ? (
    <></>
  ) : (
    <>
      <PageTitle text="Modèles" backButton={false} />
      <List
        {...props}
        filter={
          props.group_id
            ? { group_id: props.group_id }
            : identity?.status === "Client"
            ? { group_id: identity.company }
            : {}
        }
        filters={
          <Filter {...props}>
            {!props.group_id && identity?.status === "Collaborateur" && (
              <ReferenceInput
                source="group_id"
                reference="companies"
                alwaysOn
                sort={{ field: "company", order: "ASC" }}
                perPage={1000}
              >
                <AutocompleteInput
                  label="Organisation"
                  optionText="company"
                  filterToQuery={(search: any) => ({ company: search })}
                />
              </ReferenceInput>
            )}
            <TextInput label="Rechercher par nom du modèle" source="name" alwaysOn />
            <ReferenceInput
              source="crmGroupId"
              reference="groups"
              alwaysOn
              filter={
                props.group_id
                  ? { company: props.group_id }
                  : identity?.status !== "Collaborateur"
                  ? { company: identity?.company }
                  : {}
              }
              sort={{ field: "name", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput
                label="Rechercher par groupe"
                optionText="name"
                filterToQuery={(search: any) => ({ name: search })}
              />
            </ReferenceInput>
            <SelectInput
              source="group_access"
              label="Visibilité"
              alwaysOn
              choices={[
                { id: true, name: "Tout le monde" },
                { id: false, name: "Restreint par groupe" },
              ]}
            />
          </Filter>
        }
        actions={
          <Toolbar>
            <CreateButton label="Nouveau Modèle" />
          </Toolbar>
        }
        sort={{ field: "name", order: "ASC" }}
        exporter={false}
        empty={false}
      >
        <DigisoftDatagrid>
          <TextField label="Nom du modèle" source="name" />
          {!props.group_id && (
            <ReferenceField label="Organisation" source="group_id" reference="companies" link={false}>
              <TextField source="company" />
            </ReferenceField>
          )}
          <FunctionField
            label="Accès"
            render={(record: any) => {
              if (record.group_access) {
                return "Tout le monde";
              } else {
                return !record.crmGroupId ? (
                  "Personne"
                ) : (
                  <ReferenceField source="crmGroupId" reference="groups" link={false}>
                    <FunctionField
                      render={(groupRecord: any) => {
                        return <Typography fontSize={"0.9rem"}>Groupe "{groupRecord.name}"</Typography>;
                      }}
                    />
                  </ReferenceField>
                );
              }
            }}
          />
          <DateField label="Mis à jour le" source="updatedAt" showTime />
          <CloneButton />
        </DigisoftDatagrid>
      </List>
    </>
  );
};

export default TemplatesList;
