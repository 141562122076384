import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, MenuItem } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import GroupIcon from "@mui/icons-material/Group";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState } from "react";
import LinearProgressBar from "../../components/others/LinearProgressBar";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(6),
  },
}));

export default function FirstStepsDialog({ completedSteps = [1] }: any) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const menuItems = [
    {
      id: 1,
      startIcon: <HowToRegIcon />,
      mainText: "Inscrivez-vous maintenant",
      secondaryText: "Tout est prêt, poursuivons",
      completed: completedSteps.includes(1),
      onClick: () => {},
    },
    {
      id: 2,
      startIcon: <LocalMallIcon />,
      mainText: "Achetez une licence SMS Meeting",
      secondaryText: "Ou activez votre version d'essai",
      completed: completedSteps.includes(2),
      onClick: () => {
        navigate("/shop");
      },
    },
    {
      id: 3,
      startIcon: <GroupIcon />,
      mainText: "Créer un utilisateur final",
      secondaryText: "Celui qui va utiliser SMS Meeting",
      completed: completedSteps.includes(3),
      onClick: () => {
        navigate("/users");
      },
    },
    {
      id: 4,
      startIcon: <ContactPageIcon />,
      mainText: "Affectez une licence",
      secondaryText: "Lier un utilisateur avec une licence",
      completed: completedSteps.includes(4),
      onClick: () => {
        navigate("/licenses");
      },
    },
    {
      id: 5,
      startIcon: <FormatAlignLeftIcon />,
      mainText: "Créer un modèle",
      secondaryText: "Rendez vous dans la section Modèles SMS",
      completed: completedSteps.includes(5),
      onClick: () => {
        navigate("/templates");
      },
    },
    {
      id: 6,
      startIcon: <DownloadForOfflineIcon />,
      mainText: "Télécharger & installer un connecteur SMS Meeting",
      secondaryText: "Choisissez entre la version Outlook ou Gmail",
      completed: completedSteps.includes(6),
      onClick: () => {
        navigate("/download");
      },
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        sx={{
          p: 0.5,
          px: 1,
          ml: 2,
          my: 1,
          color: "neutral.lighter",
          borderColor: "neutral.main",
          "&:hover": {
            borderColor: "neutral.lighter",
          },
        }}
        variant="outlined"
        onClick={handleClickOpen}
      >
        <Typography fontWeight="400">Montrez-moi</Typography>
      </Button>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h5">Découvrer My SMS Meeting</Typography>
            <Typography sx={{ color: "neutral.dark", fontSize: "0.8rem" }}>
              Ce guide vous aidera à tirer le meilleur parti de votre espace My SMS meeting en 6 étapes faciles
            </Typography>
          </Box>
          <Typography fontWeight="400" mb={1}>
            {completedSteps.length}/6 terminé
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{
              width: 1,
              mb: 2,
            }}
          >
            <LinearProgressBar currentStep={completedSteps.length} stepsNumber={6} />
          </Box>
          {menuItems.map((menuItem: any) => (
            <MenuItem
              key={menuItem.id}
              onClick={(e) => {
                if (!menuItem.completed) {
                  menuItem.onClick();
                }
              }}
              sx={{
                px: 0,
                py: 0.5,
                color: "neutral.darker",
                ":hover": { cursor: menuItem.completed ? "default" : "pointer" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  bgcolor: menuItem.completed ? "neutral.light" : "none",
                  width: 1,
                  px: 1,
                  py: 1,
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {menuItem.startIcon}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      ml: 3,
                    }}
                  >
                    <Typography sx={{ fontSize: "0.9rem" }}>{menuItem.mainText}</Typography>
                    <Typography sx={{ color: "neutral.dark", fontSize: "0.7rem" }}>{menuItem.secondaryText}</Typography>
                  </Box>
                </Box>
                {menuItem.completed ? (
                  <CheckCircleIcon sx={{ color: "green", mr: 4 }} />
                ) : (
                  <ChevronRightIcon sx={{ color: "neutral.dark", mr: 4 }} />
                )}
              </Box>
            </MenuItem>
          ))}
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
