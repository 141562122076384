import React from "react";
import { CreateButton, DateField, Filter, TextField, TextInput, Toolbar } from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";

const ProvidersList = ({ ...props }) => {
  return (
    <List
      {...props}
      filters={
        <Filter {...props}>
          <TextInput label="Nom" source="name" alwaysOn />
        </Filter>
      }
      actions={
        <Toolbar>
          <CreateButton label="Nouveau Fournisseur" />
        </Toolbar>
      }
      exporter={false}
      empty={false}
    >
      <DigisoftDatagrid bulkActionButtons={false} >
        <TextField label="Identifiant" source="id" />
        <TextField label="Nom" source="name" />
        <TextField label="Url d'entrée" source="entry_point" />
        <DateField label="Mis à jour le" source="updatedAt" showTime />
      </DigisoftDatagrid>
    </List>
  );
};

export default ProvidersList;
