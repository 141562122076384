import React, { useCallback } from "react";
import {
  DateField,
  FileField,
  FileInput,
  FunctionField,
  ReferenceManyField,
  useGetIdentity,
  Datagrid as RaDatagrid,
  useNotify,
  useRecordContext,
  useUpdate,
  useRefresh,
  useRedirect,
} from "react-admin";
import { Typography } from "@mui/material";
import { EditableDatagrid, RowForm, useRowContext } from "@react-admin/ra-editable-datagrid";
import { useFormContext } from "react-hook-form";
import api from "../../services/apiWithAuth";

const Datagrid = ({ children }: any) => {
  const { isLoading, data: identity } = useGetIdentity();
  if (isLoading) {
    return null;
  }
  return identity?.status === "Collaborateur" ? (
    <EditableDatagrid bulkActionButtons={false} mutationMode="undoable" editForm={<PaymentForm />} noDelete>
      {children}
    </EditableDatagrid>
  ) : (
    <RaDatagrid bulkActionButtons={false}>{children}</RaDatagrid>
  );
};
const PaymentsField = ({ target }: any) => {
  return (
    <ReferenceManyField fullWidth label="Paiements" reference="payments" target={target} sort={{ field: "paymentDate", order: "DESC" }}>
      <Datagrid>
        <DateField source="paymentDate" label="Date de paiement" showTime />
        <FunctionField
          label="Début periode facturée"
          render={(record: any) =>
            !record.periodStart ? "" : new Date(record.periodStart * 1000).toLocaleDateString("fr")
          }
        />
        <FunctionField
          label="Fin période facturée"
          render={(record: any) =>
            !record.periodEnd ? "" : new Date(record.periodEnd * 1000).toLocaleDateString("fr")
          }
        />
        <FunctionField label="Montant TTC" render={(record: any) => Number(record.amount).toFixed(2) + " €"} />
        <FunctionField
          label="Type"
          render={({ type }: any) => {
            return (
              <Typography
                sx={{
                  color: "white",
                  bgcolor: type === "auto" ? "success.light" : "error.main",
                  px: 1,
                  py: 0.5,
                  borderRadius: 2,
                  width: "fit-content",
                  fontSize: "0.9rem",
                  opacity: 0.8,
                }}
              >
                {type === "auto" ? type : "manuel"}
              </Typography>
            );
          }}
        />
        <FileField label="Facture" source="pdfInvoice.src" title="pdfInvoice.title" target="_blank" />
      </Datagrid>
    </ReferenceManyField>
  );
};

const PaymentForm = ({ target }: any) => {
  const { getValues } = useFormContext();

  return (
    <RowForm defaultValues={{ [target]: getValues("id") }}>
      <DateField source="paymentDate" label="Payé le" showTime />
      <FunctionField
        label="Montant TTC"
        render={(record: any) => {
          return Number(record.amount).toFixed(2) + " €";
        }}
      />
      <FunctionField
        label="Type"
        render={({ type }: any) => {
          return (
            <Typography
              sx={{
                color: "white",
                bgcolor: type === "auto" ? "success.light" : "warning.main",
                px: 1,
                py: 0.5,
                borderRadius: 2,
                width: "fit-content",
                fontSize: "0.9rem",
                opacity: 0.8,
              }}
            >
              {type === "auto" ? type : "manuel"}
            </Typography>
          );
        }}
      />
      <FileInput label="Facture" source="pdfInvoice" placeholder="sélectionnez votre fichier">
        <FileField source="src" title="title" />
      </FileInput>
    </RowForm>
  );
};

export default PaymentsField;
