import { Grid, Typography, InputAdornment } from "@mui/material";
import React from "react";
import {
  Edit,
  FormDataConsumer,
  Labeled,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  ReferenceField,
  TextField,
  useGetIdentity,
  useNotify,
} from "react-admin";
import { DualListInput } from "@react-admin/ra-relationships";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import AutocompleteDualListInput from "../../components/fields/AutocompleteDualListInput";
import { CONTACT_VISIBILITIES } from "../../services/common-utils/constants";
import PageTitle from "../../components/navigation/PageTitle";

const ContactListsEdit = ({ ...props }) => {
  const { isLoading, data: identity } = useGetIdentity();
  const notify = useNotify();
  const redirect = useCustomListRedirect();

  // Transform override
  const onTransform = (data: any, { previousData }: any) => {
    delete data.itemToAdd;
    delete data.itemRemove;
    return {
      oldContacts: previousData.contacts,
      ...data,
    };
  };

  const onSuccess = (data: any) => {
    notify(`Liste de contact modifié avec succès`, { type: "success" });
    redirect("contactsOrContactLists");
  };

  return (
    <Edit {...props} transform={onTransform} mutationMode="optimistic" mutationOptions={{ onSuccess }} hasShow={false}>
      <SimpleForm>
        <PageTitle text={(record: any) => record.name} />
        <TextInput required source="name" label="Nom de la liste de contacts" />
        {["Collaborateur", "Revendeur"].includes(identity?.status) && (
          <ReferenceField source="group_id" reference="companies">
            <Labeled label="Organisation">
              <TextField source="company" mb={"20px"} />
            </Labeled>
          </ReferenceField>
        )}
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceArrayInput
              label="Contacts"
              reference="contacts"
              source="contacts"
              filter={{
                group_id: ["Collaborateur", "Revendeur"].includes(identity?.status)
                  ? formData.group_id
                  : identity?.company,
              }}
              perPage={1000}
            >
              <AutocompleteDualListInput
                label="Contacts"
                optionText={(contact: any) => `${contact.firstname} ${contact.lastname}`}
                source="contacts"
                queryField="lastname"
              />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
        {/* <SelectInput
          validate={required()}
          defaultValue={CONTACT_VISIBILITIES[0]}
          source="visibility"
          label="Visibilité"
          choices={CONTACT_VISIBILITIES.map((s) => ({ id: s, name: s }))}
        /> */}

        {/* <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.visibility === "Cloisonné à un groupe d'utilisateurs" && (
              <ReferenceInput required source="crmGroupId" reference="groups" filter={{ company: formData.group_id }}>
                <AutocompleteInput
                  label="Groupe d'accès à la liste de contacts"
                  optionText="name"
                  filterToQuery={(search: any) => ({ name: search })}
                  helperText="Seuls les utilisateurs du groupe pourront accéder à la liste de contacts"
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer> */}
      </SimpleForm>
    </Edit>
  );
};

export default ContactListsEdit;
