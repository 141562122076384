import { Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import {
  Create,
  ReferenceInput,
  FormDataConsumer,
  ReferenceArrayInput,
  SimpleForm,
  useGetIdentity,
  TextInput,
  AutocompleteInput,
  useNotify,
} from "react-admin";
import { HistoryContext } from "../../services/historyProvider";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import AutocompleteDualListInput from "../../components/fields/AutocompleteDualListInput";
import PageTitle from "../../components/navigation/PageTitle";

const GroupsCreate = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  const history: string[] = useContext(HistoryContext);
  const [fromCompany, setFromCompany] = useState("");
  const notify = useNotify();
  const redirect = useCustomListRedirect();

  // Transform override
  const onTransform = (data: any) => {
    if (["Client", "Indirect"].includes(identity?.status)) {
      data.company = identity?.company;
    }
    delete data.itemToAdd;
    delete data.itemToRemove;
    return {
      ...data,
    };
  };

  const onSuccess = (data: any) => {
    notify(`Groupe créé avec succès`, { type: "success" });
    redirect("groups");
  };

  useEffect(() => {
    //route from routerContext can be not already updated
    if (history.length && !fromCompany && history[0] === window.location.pathname) {
      const match = history[1].match(/(?:companies\/)(.*)(?:\/)/);
      setFromCompany(match ? match[1] : "none");
    }
  }, [history, fromCompany]);

  return !fromCompany ? (
    <></>
  ) : (
    <Create {...props} transform={onTransform} mutationOptions={{ onSuccess }} hasShow={false}>
      <SimpleForm defaultValues={() => (fromCompany !== "none" ? { company: fromCompany } : {})}>
        <PageTitle text="Création d'un groupe" divider />
        <TextInput required label="Nom du groupe" source="name" />
        {["Collaborateur", "Revendeur"].includes(identity?.status) && (
          <ReferenceInput
            required
            source="company"
            reference="companies"
            filter={identity?.status !== "Collaborateur" ? { id: identity?.company } : {}}
            sort={{ field: "company", order: "ASC" }}
            perPage={1000}
          >
            <AutocompleteInput
              label="Organisation"
              optionText="company"
              filterToQuery={(search: any) => ({ company: search })}
            />
          </ReferenceInput>
        )}
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceArrayInput
              label="Utilisateurs"
              reference="users"
              source="users"
              filter={{
                company: ["Collaborateur", "Revendeur"].includes(identity?.status)
                  ? formData.company
                  : identity?.company,
                crmGroupId: "none",
              }}
              perPage={1000}
              sort={{ field: "email", order: "ASC" }}
            >
              <AutocompleteDualListInput label="Utilisateurs" optionText="email" queryField="email" source="users" />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default GroupsCreate;
