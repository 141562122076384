import React from "react";
import { CreateButton, DateField, Filter, TextField, TextInput, Toolbar } from "react-admin";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import { List } from "@react-admin/ra-rbac";
import PageTitle from "../../components/navigation/PageTitle";

const ProductsList = ({ ...props }) => {
  return (
    <>
      <PageTitle text="Produits" backButton={false} />
      <List
        {...props}
        filters={
          <Filter {...props}>
            <TextInput label="Rechercher" source="name" alwaysOn />
          </Filter>
        }
        actions={
          <Toolbar>
            <CreateButton label="Nouveau produit" />
          </Toolbar>
        }
        sort={{ field: "order", order: "ASC" }}
      >
        <DigisoftDatagrid>
          <TextField source="name" label="Nom" />
          <TextField source="type" label="Type" />
          <TextField source="status" label="Statut" />
          <TextField source="order" label="Ordre d'affichage" />
          <DateField label="Actualisé le" source="lastupdate" showTime />
        </DigisoftDatagrid>
      </List>
    </>
  );
};

export default ProductsList;
