import React, { useState, useEffect, createContext } from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { RESOURCES_LIST } from "./common-utils/constants";

export const HistoryContext = createContext([] as string[]);

const resetFilters = () => {
  RESOURCES_LIST.forEach((resource: string) => {
    const resourceListParamsString = localStorage.getItem(`RaStore.${resource}.listParams`);
    if (resourceListParamsString) {
      const resourceListParams = JSON.parse(resourceListParamsString);
      delete resourceListParams.displayedFilters;
      delete resourceListParams.filter;
      const modifiedresourceListParamsString = JSON.stringify(resourceListParams);
      localStorage.setItem(`RaStore.${resource}.listParams`, modifiedresourceListParamsString);
    }
  });
};

// history with 3 levels
const HistoryProvider = ({ ...props }) => {
  const location = useLocation();
  const [history, setHistory] = useLocalStorage("history", [location.pathname]);
  const [lastCompany, setLastCompany] = useLocalStorage("lastCompany", "");

  useEffect(() => {
    setHistory((prev) => {
      const match = location.pathname.match(/(?:companies\/)(.*)(?:\/)/) || location.pathname.match(/(?:companies\/)([^/]*$)/);
      if (match && match[1] !== lastCompany) {
        setLastCompany(match[1]);
        resetFilters();
      }
      if (prev[0] === location.pathname) {
        return prev;
      }
      const newHistory: string[] = [location.pathname];
      for (let index = 1; index < 3; index++) {
        newHistory[index] = prev[index - 1];
      }
      return newHistory;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setHistory]);

  return <HistoryContext.Provider value={history}>{props.children}</HistoryContext.Provider>;
};

export default HistoryProvider;
