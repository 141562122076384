import {
  Box,
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useListContext } from "react-admin";
import Stats from "../../components/charts/Stats";
import api from "../../services/apiWithAuth";

const periodToLabel: { [key: string]: string } = {
  currentMonth: "sur le mois en cours",
  lastMonth: "le mois précédent",
  currentYear: "sur l'année en cours",
  lastYear: "l'année précédente",
};
const SmsStats = ({ widthComputer = 0.8, showLegend = true, groupId }: any) => {
  const { filterValues } = useListContext();
  const period = filterValues?.period || "currentMonth";
  const startDate = filterValues?.startDate;
  const endDate = filterValues?.endDate;
  const group_id = filterValues?.group_id || groupId;
  const userId = filterValues?.userId;
  const crmGroupId = filterValues?.crmGroupId;
  const countType = filterValues?.countType || "SMS";
  const filterType = filterValues?.filterType;
  const viewType = filterValues?.viewType || "statistics";
  const [displayDetails, setDisplayDetails] = useState(false);
  const [datesTotal, setDatesTotal] = useState<any>(null);
  const [timeUnit, setTimeUnit] = useState("day");
  const [totalOnPeriod, setTotalOnPeriod] = useState<any>(0);
  const [totalByCrmGroupOnPeriod, setTotalByCrmGroupOnPeriod] = useState({} as any);

  useEffect(() => {
    if (viewType === "statistics" && period && (period !== "custom" || (period === "custom" && startDate && endDate))) {
      const asyncCall = async () => {
        const timeUnit =
          period === "custom"
            ? (new Date(endDate).getTime() - new Date(startDate).getTime()) / 86400000 > 31 // if more than 1 month between dates
              ? "month"
              : "day"
            : period.includes("Year")
            ? "month"
            : "day";
        setTimeUnit(timeUnit);
        const params = {
          ...(group_id && { group_id }),
          ...(period && { period }),
          ...(period && { timeUnit }),
          ...(period === "custom" && { startDate, endDate }),
          ...(countType && { countType }),
          ...(filterType === "user" && userId && { userId }),
          ...((crmGroupId || filterType === "allCrmGroups") && {
            crmGroupId: filterType === "allCrmGroups" ? "all" : crmGroupId,
          }),
        };
        try {
          const { data } = await api.get("/sms/statistics", { params });
          setDatesTotal(data);
          if (Object.keys(data).length) {
            if (filterType === "allCrmGroups") {
              const totalsByGroup = {} as any;
              Object.keys(data).forEach((crmGroup: string) => {
                totalsByGroup[crmGroup] = Object.values(data[crmGroup]).reduce(
                  (smsNumber: number, acc: number) => smsNumber + acc
                );
              });
              setTotalByCrmGroupOnPeriod(totalsByGroup);
              setTotalOnPeriod(
                Object.values(totalsByGroup).reduce((smsNumber: number, acc: number) => smsNumber + acc)
              );
            } else {
              const totals: { [key: string]: number } = data;
              setTotalByCrmGroupOnPeriod({});
              setTotalOnPeriod(Object.values(totals).reduce((smsNumber: number, acc: number) => smsNumber + acc));
            }
          }
        } catch (error) {
          console.log("error", error);
        }
      };
      asyncCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_id, period, userId, crmGroupId, startDate, endDate, filterType, viewType, countType]);

  return viewType !== "statistics" ? null : (
    <Box
      sx={{
        width: 1,
        mb: 4,
        display: "flex",
        flexDirection: ["column", "column"],
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          bgcolor: "primary.main",
          borderRadius: "10px",
          padding: 2,
          my: 2,
          width: 0.8,
        }}
      >
        <Typography fontWeight={"semibold"} color={"white"}>
          <b>
            {totalOnPeriod} {countType === "SMS" ? "SMS" : "crédits SMS"}
          </b>{" "}
          {countType === "SMS" ? "envoyés" : "consommés"} {periodToLabel[period]}.{" "}
          {filterType !== "allCrmGroups" ? null : (
            <Button
              sx={{ color: "white", ml: 4 }}
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => setDisplayDetails(!displayDetails)}
            >
              {displayDetails ? "Cacher le détail par groupe" : "Afficher le détail par groupe"}
            </Button>
          )}
        </Typography>
        {Object.keys(totalByCrmGroupOnPeriod).length > 0 && displayDetails && (
          <TableContainer component={Paper} sx={{ width: 0.8, my: 4 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Groupe</TableCell>
                  <TableCell align="right">
                    {countType === "SMS" ? "SMS envoyés" : "Crédits SMS consommés"} {periodToLabel[period]}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(totalByCrmGroupOnPeriod).map((crmGroup: string) => (
                  <TableRow key={crmGroup} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {crmGroup.split("$")[1]}
                    </TableCell>
                    <TableCell align="right">{totalByCrmGroupOnPeriod[crmGroup]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Box sx={{ width: [1, widthComputer], maxWidth: 1200, mt: 2 }}>
        {datesTotal === null ? (
          <Skeleton variant="rectangular" sx={{ width: 1, height: 0, paddingBottom: "50%" }} />
        ) : (
          <Stats
            datesTotal={datesTotal}
            showLegend={showLegend}
            timeUnit={timeUnit}
            label={countType === "SMS" ? "SMS envoyés" : "Crédits consommés"}
          />
        )}
      </Box>
    </Box>
  );
};

export default SmsStats;
