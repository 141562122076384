import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import React, { useState } from "react";
import {
  useListContext,
  useUpdateMany,
  useNotify,
  useRefresh,
  useUnselectAll,
  SimpleForm,
  AutocompleteInput,
  ReferenceInput,
  Button,
  Toolbar,
  SaveButton,
} from "react-admin";
import MoveDownIcon from "@mui/icons-material/MoveDown";

const TransferToolBar = () => (
  <Toolbar>
    <SaveButton label="Transferer" icon={<MoveDownIcon />} />
  </Toolbar>
);

const LicensesBulkTransfer = ({ identity }: any) => {
  const { selectedIds, data: licenses } = useListContext();
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("licenses");
  const [updateMany] = useUpdateMany();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const transferLicenses = (data: any) => {
    const someMainLicenseSelected = selectedIds.some((id: any) => {
      const license = licenses.find((license) => id === license.id);
      return license?.max_connections > 1;
    });
    if (someMainLicenseSelected) {
      setOpen(false);
      notify("Transfert impossible : une des licences à fusionner a des licences rattachées", { type: "error" });
      return;
    }
    updateMany(
      "licenses",
      { ids: selectedIds, data: { group_id: data.group_id, companyTransfer: true } },
      {
        onSuccess: () => {
          refresh();
          notify("Licences transférées avec succès");
          unselectAll();
        },
        onError: (error) => notify("Erreur lors du transfert des licences", { type: "error" }),
      }
    );
    setOpen(false);
  };

  return (
    <>
      <Button label="Transférer à une organisation" onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Transfert de licences à une organisation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Si certaines licences sont affectées à des utilisateurs, changer d'organisation supprimera les affectations
          </DialogContentText>
          <SimpleForm onSubmit={transferLicenses} toolbar={<TransferToolBar />}>
            <ReferenceInput
              required
              source="group_id"
              reference="companies"
              filter={identity?.status === "Revendeur" ? { parentCompany: identity?.company } : {}}
              sort={{ field: "company", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput
                label="Organisation"
                optionText="company"
                filterToQuery={(search: any) => ({ company: search })}
              />
            </ReferenceInput>
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LicensesBulkTransfer;
