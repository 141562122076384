import * as React from "react";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import GoBackButton from "./GoBackButton";
import { useRecordContext } from "ra-core";

export default function PageTitle({
  text,
  backButton = true,
  backUrl = "",
  divider = false,
}: {
  text: any;
  backButton?: boolean;
  backUrl?: string;
  divider?: boolean;
}) {
  const record = useRecordContext();
  const theme = useTheme();
  const [title, setTitle] = React.useState("");
  React.useEffect(() => {
    if (!title) {
      if (typeof text === "string") {
        setTitle(text);
      }
      if (record && typeof text === "function") {
        setTitle(text(record));
      }
    }
  }, [record, text, title]);

  return (
    <Box sx={{ width: 1 }}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", p: 2, pl: 1 }}>
        {backButton && <GoBackButton backUrl={backUrl} />}
        <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.mode === "light" ? "black" : "white" }}>
          {title}&nbsp;
        </Typography>
      </Box>
      {divider && <Divider sx={{ mx: 2, mt: -1, mb: 2 }} />}
    </Box>
  );
}
