import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CheckoutSuccess = ({ paidWith }: any) => {
  const navigate = useNavigate();
  const orderReference = localStorage.getItem("orderReference")

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Typography variant="h5" sx={{ my: 2 }}>
        Merci de votre confiance.
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }}>
        {paidWith === "sepa"
          ? "Votre commande a bien prise en compte et votre moyen de paiement enregistré. Une fois le prélèvement SEPA autorisé par votre banque, vous recevrez un e-mail de confirmation de commande et vous pourrez commencer à utiliser les produits achetés."
          : "Votre commande a bien prise en compte et votre paiement accepté. Vous recevrez rapidement un mail de confirmation de commande."}
      </Typography>
      <Typography variant="h6" sx={{ my: 2 }}>
        Le numéro de suivi de votre commande est {orderReference}, vous vouvez dès à présent la consulter en cliquant sur <span style={{fontWeight: "bold", cursor: "pointer"}} onClick={() => navigate("/orders/"+orderReference)}>ce lien.</span>
      </Typography>
    </Box>
  );
};

export default CheckoutSuccess;
