import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ImportButton } from "react-admin-import-csv";
import { Box } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import PublishIcon from "@mui/icons-material/Publish";

export default function ImportButtons({ config, headersMap, xlsxTemplate, csvTemplate }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const parserConfig = {
    logging: true,
    disableCreateMany: true,
    disableUpdateMany: true,
    disableGetMany: true,
    parseConfig: {
      header: true,
      transformHeader: (header: string) => {
        console.log(header, "-->", headersMap[header]);
        return headersMap[header];
      },
      encoding: "utf-8",
      delimiter: "",
      skipEmptyLines: "greedy",
    },
    ...config,
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
      <Box sx={{ ml: 2 }}>
        <ImportButton label="Importer" {...parserConfig} />
      </Box>
      <Button
        id="positioned-button"
        aria-controls={open ? "positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        startIcon={<DownloadForOfflineIcon />}
        onClick={handleClick}
        sx={{ ml: 2 }}
      >
        Modèle
      </Button>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Button
            onClick={() => {
              window.open(xlsxTemplate);
            }}
          >
            Modèle Excel
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button
            onClick={() => {
              window.open(csvTemplate);
            }}
          >
            Modèle csv
          </Button>
        </MenuItem>
      </Menu>
    </Box>
  );
}
