import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { Create, TextInput, SimpleForm, SelectInput, useNotify, useRedirect, useGetIdentity, BooleanInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import { COMPANIES_SECTORS, COMPANIES_STATUSES, COUNTRIES, PRODUCTS_PAYMENT_MODELS, PRODUCTS_STATUSES, PRODUCTS_TYPES, SERVICES, TITLES, TYPES } from '../../services/common-utils/constants';

const AttributesCreate =  ({ ...props }) => {
  const notify = useNotify()
  const redirect = useRedirect();
  const { identity } = useGetIdentity();

  // Success override
  const onSuccess = (data: any) => {
    notify(`Attribut créé avec succès`, { type: 'success' });
    redirect('list', 'attributes', data.id, data);
  };

  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm>
        <Typography variant="h6" mb={"20px"}>
          Création d'un nouvel <b>Attribut</b>
        </Typography>

        <div style={{ borderBottom: '1px solid lightgray', width: '100%', marginBottom: '28px', marginTop: '0px' }} />

        <Typography variant="body1" mb={"20px"}>
          Nouvel attribut
        </Typography>
        <Grid container direction={"column"}>
          <TextInput required defaultValue={null} source="name" label="Nom de l'attribut" />
          <TextInput required defaultValue={null} source="description" label="Description de l'attribut" />
          <ArrayInput required source="values" label="Valeurs possibles">
            <SimpleFormIterator>
              <TextInput sx={{ mt: '10px'}} source="name" label="Nom" />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </SimpleForm>
    </Create>
  );
}

export default AttributesCreate;
