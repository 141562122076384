import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { Box } from "@mui/material";

const cardStyle = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "blue",
      color: "grey",
      fontSize: "18px",
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: "antialiased",
      border: "solid grey 1px",
      "::placeholder": {
        color: "grey",
      },
    },
    invalid: {
      color: "red",
      ":focus": {
        color: "blue",
      },
    },
  },
};

export default function StripeCardSection() {
  return (
    <Box sx={{border: "solid rgba(0,0,0,0.3) 1px", p: 1, borderRadius: 2}}>
      <CardElement options={cardStyle} />
    </Box>
  );
}
