import { Box, Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import FormInput from "../../../components/form/FormInput";
import { useForm, FormProvider } from "react-hook-form";
import api from "../../../services/apiWithAuth";
import { LoadingButton } from "@mui/lab";
import { collection, getDocs, query, where } from "firebase/firestore";
import { fbFirestore } from "../../../services/firebase/firebase.services";

const PromoCode = ({ discount, setDiscount, cart, setCart, identity }: any) => {
  const methods = useForm({ defaultValues: { promoCode: discount.promoCode } });
  const [message, setMessage] = useState({} as any);

  const onSubmit = async ({ promoCode }: any) => {
    if (promoCode) {
      try {
        const { data } = await api.post("/utils/getDiscount", { promoCode });
        if (data.scope === "Produits spécifiques" && data.type !== "Produit offert") {
          if (!cart.filter((i: any) => data.products.includes(i.product.id)).length) {
            setMessage({ content: "Le code entré n'est pas applicable sur votre panier.", success: false });
            setDiscount({});
            return;
          }
        }
        if (data.type === "Produit offert") {
          const q = query(
            collection(fbFirestore, "products"),
            where("id", "in", data.products),
            where("status", "==", "Invisible")
          );
          let productsSnap = await getDocs(q);
          let products = productsSnap.docs.map((p) => p.data());
          products = products
            .map((product: any) => {
              const availableTarifs = product.tarifs.filter((tarif: any) => {
                return (
                  tarif.countries.includes(identity?.companyCountry) &&
                  tarif.companyStatus.includes(identity?.status) &&
                  tarif.companyTypes.includes(identity?.type) &&
                  (!tarif?.companyIds?.length || tarif.companyIds.includes(identity?.company)) &&
                  !(tarif?.blackListedCompanyIds || []).includes(identity?.company)
                );
              });
              const productAlreadyInCart = cart.some((item: any) => item.product.id === product.id);
              return availableTarifs.length > 0 && product.status === "Invisible" && !productAlreadyInCart
                ? {
                    ...product,
                    tarifs: availableTarifs,
                  }
                : null;
            })
            .filter((product: any) => !!product);
          const newCartItems = products.map((product) => {
            return {
              product,
              tarifId: 0,
              quantity: data.freeProductQuantity,
            };
          });
          setCart(cart.concat(newCartItems));
        }
        setMessage({ content: "", success: true });
        setDiscount(data);
      } catch (err) {
        setMessage({ content: "Le code entré n'est pas applicable sur votre panier.", success: false });
        setDiscount({});
      }
    } else {
      setDiscount({});
      setMessage({});
    }
  };

  useEffect(() => {
    const asyncCall = async () => {
      if (identity && !Object.keys(discount).length && cart) {
        try {
          const { data } = await api.post("/utils/getDiscount", { promoCode: "auto" });
          if (data) {
            if (data.scope === "Produits spécifiques") {
              if (!cart.filter((i: any) => data.products.includes(i.product.id)).length) {
                return;
              }
            }
            setDiscount(data);
          }
        } catch (err) {
          console.log(err);
        }
      }
    };
    asyncCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity, discount, setDiscount]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: 1,
        mt: 3,
        mb: 1,
        "& .MuiInputBase-root": { padding: "5px", width: "1" },
        "& .MuiInputLabel-root": { fontSize: "1.2rem" },
        "& .MuiFormControl-root": { margin: 0, p: 0, width: 1 },
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          autoComplete="off"
          display="flex"
          alignItems="center"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <FormInput type="input" name="promoCode" label="Code promo" />
          <LoadingButton
            sx={{ ml: 2, py: 1, px: 2, height: 50 }}
            variant="outlined"
            type="submit"
            loading={methods.formState.isSubmitting}
          >
            Appliquer
          </LoadingButton>
        </Box>
      </FormProvider>
      {message && !methods.formState.isSubmitting && (
        <Typography
          variant="subtitle1"
          component="h3"
          sx={{ textAlign: "center", color: message.success ? "green" : "red" }}
        >
          {message.content}
        </Typography>
      )}
    </Box>
  );
};

export default PromoCode;
