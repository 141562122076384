import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BooleanInput,
  TextField,
  Labeled,
  useGetIdentity,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  AutocompleteInput,
  ReferenceInput,
  DateField,
  useNotify,
  Button as RaButton,
  useRecordContext,
  NumberInput,
  FunctionField,
  ReferenceField,
  useDelete,
  Confirm,
  useUpdate,
} from "react-admin";
import WarningIcon from "@mui/icons-material/Warning";
import { Edit, IfCanAccess, TabbedForm, FormTab } from "@react-admin/ra-rbac";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import { useFormContext } from "react-hook-form";
import api from "../../services/apiWithAuth";
import PageTitle from "../../components/navigation/PageTitle";
import { query, where } from "firebase/firestore";

const UserInput = ({ company, disabled = false, ...props }: any) => {
  const record = useRecordContext();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (record.group_id !== company) {
      setValue("userId", "");
    } else {
      setValue("userId", record.userId);
    }
  }, [company, record.group_id, record.userId, setValue]);

  return (
    <ReferenceInput
      source="userId"
      reference="users"
      filter={{
        collectionQuery: (c: any) => query(c, where("company", "==", company), where("licenseId", "==", null)),
      }}
      {...props}
      sort={{ field: "email", order: "ASC" }}
      perPage={1000}
    >
      <AutocompleteInput
        label="Utilisateur"
        optionText="email"
        filterToQuery={(search: any) => ({ email: search })}
        //strange : disabled do not accept false value
        {...((disabled || record.group_id !== company) && { disabled: true })}
        helperText={
          disabled ? (
            <Typography fontSize="0.8rem" color="warning.main">
              Pour affecter cette licence à un utilisateur merci de la détacher au préalable de sa licence principale
            </Typography>
          ) : record.group_id !== company ? (
            <Typography fontSize="0.8rem" color="warning.main">
              Merci d'enregistrer avant de pouvoir changer l'utilisateur de la licence
            </Typography>
          ) : (
            <Typography fontSize="0.8rem">La licence est affectée à l'utilisateur sélectionné</Typography>
          )
        }
      />
    </ReferenceInput>
  );
};

const MaxConnectionsInput = () => {
  const record = useRecordContext();
  const [freeLicensesNumber, setFreeLicensesNumber] = useState(null);

  useEffect(() => {
    if (!freeLicensesNumber) {
      const asyncCall = async () => {
        const { data } = await api.post("/licenses/getFreeLicenses", {
          company: record.group_id,
        });
        setFreeLicensesNumber(data.freeLicensesNumber);
      };
      asyncCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return freeLicensesNumber === null ? null : (
    <NumberInput
      required
      source="max_connections"
      label="Nombre de connexions maximum"
      helperText={
        <Typography sx={{ fontSize: "0.8rem" }}>
          Permet de se connecter à N postes avec une même licence (mais désactive N-1 licences non affectées en
          contrepartie) <br /> <b>Vous avez actuellement {freeLicensesNumber} licences libres.</b>
        </Typography>
      }
      step={1}
      min={1}
      max={freeLicensesNumber + 1}
      defaultValue={1}
      sx={{ mt: 2, "& .MuiFormHelperText-root": { ml: 0 } }}
    />
  );
};

const CustomDelete = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const redirect = useCustomListRedirect();
  const notify = useNotify();

  const [deleteOne, { isLoading, error }] = useDelete("licenses", { id: record.id, previousData: record });
  if (error) {
    return <p>ERROR</p>;
  }

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    deleteOne();
    setOpen(false);
    notify("Licence supprimée avec succès", {
      type: "success",
      messageArgs: { smart_count: 1 },
    });
    redirect("licenses");
  };

  return (
    <>
      <RaButton label="Supprimer" onClick={handleClick} sx={{ color: "red" }} />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Suppression licence"
        content="Etes-vous sur de vouloir supprimer cette licence ? Ceci peut poser des problèmes de cohérence dans le CRM."
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const CustomSave = () => {
  const [update] = useUpdate();
  const { getValues } = useFormContext();
  const record = useRecordContext();
  const redirect = useCustomListRedirect();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const handleClick = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    const values = getValues();
    try {
      //change quantity of licenses
      if (values.max_connections !== (record?.max_connections || 1)) {
        const { data } = await api.post("/licenses/getFreeLicenses", {
          company: record.group_id,
        });
        if (
          values.max_connections < 1 ||
          (values.max_connections > (record?.max_connections || 1) &&
            values.max_connections > data.freeLicensesNumber + 1)
        ) {
          setOpen(false);
          notify(
            `Changement de la quantité de connexion impossible : le nombre doit être compris entre 1 et ${
              data.freeLicensesNumber + 1
            }`,
            { type: "error" }
          );
          return;
        }
        try {
          if (values.max_connections > (record?.max_connections || 1)) {
            const {
              data: { freeLicenseIds },
            } = await api.post("/licenses/getFreeLicenses", {
              number: values.max_connections - (record?.max_connections || 1),
              company: record.group_id,
            });
            await api.post("/licenses/mergeLicenses", {
              mainLicenseId: values.id,
              secondaryLicenseIds: freeLicenseIds,
            });
          } else {
            await api.post("/licenses/detachSecondaryLicenses", {
              mainLicenseId: values.id,
              number: record.max_connections - values.max_connections,
            });
          }
          //if data is modified in mergeLicense, it have not to be modified by update License
          delete values.max_connections;
          delete values.crmMetaData;
        } catch (err) {
          notify("Erreur lors de la modification du nombre de connexion maximal.", {
            type: "error",
            messageArgs: { smart_count: 1 },
          });
          setOpen(false);
          return;
        }
      }
      await update("licenses", { id: record.id, data: values, previousData: record }, { returnPromise: true });
      notify("Licence mise à jour avec succès", {
        type: "success",
        messageArgs: { smart_count: 1 },
      });
      redirect("licenses");
    } catch (error) {
      notify("Erreur lors de la modification de licence.", {
        type: "error",
        messageArgs: { smart_count: 1 },
      });
    }
    setOpen(false);
  };

  return (
    <>
      <SaveButton onClick={handleClick} label={"Valider"} />
      <Confirm
        isOpen={open}
        title={
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
            <Typography variant="h5" color="primary.main" fontWeight={"bold"}>
              Validation modification licence
            </Typography>
          </Box>
        }
        content={
          getValues().max_connections !== record.max_connections ? (
            <Typography>
              {getValues().max_connections > record.max_connections
                ? "Etes-vous sur d'ajouter des nouvelles connexions pour cette licence ? Des licences non affectées seront rattachées à cette licence et ne seront plus utilisables"
                : "Etes-vous sur de supprimer des connexions pour cette licence ? Les licences précédemment rattachées à cette licence seront désormais utilisables"}
            </Typography>
          ) : (
            <Typography>Etes vous sur de vouloir appliquer ces changements sur cette licence ?</Typography>
          )
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        sx={{
          "& .MuiButton-root": { color: "warning.main" },
          "& .RaConfirm-confirmPrimary": { color: "success.main" },
        }}
      />
    </>
  );
};

const EditToolbar = (props: any) => {
  const { isLoading, data: identity } = useGetIdentity();

  return isLoading ? null : (
    <Toolbar {...props} sx={{ flexDirection: "row", justifyContent: "space-between" }}>
      <CustomSave />
      {identity?.status === "Collaborateur" && <CustomDelete />}
    </Toolbar>
  );
};

const LicensesEdit = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  const notify = useNotify();
  const redirect = useCustomListRedirect();

  const onSuccess = (data: any) => {
    notify(`Licence modifiée avec succès`, { type: "success" });
    redirect("licenses");
  };

  return (
    <Edit {...props} mutationMode="optimistic" mutationOptions={{ onSuccess }} hasShow={false}>
      <PageTitle text={(record: any) => record.email || "Édition d'une licence"} />
      <TabbedForm toolbar={<EditToolbar />}>
        <FormTab label="Licence" name="license">
          {identity?.status === "Collaborateur" && (
            <Labeled label="Identifiant">
              <TextField source="id" mb={"20px"} />
            </Labeled>
          )}
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return ["Collaborateur", "Revendeur"].includes(identity?.status) &&
                !(formData?.mainLicenseId || formData?.secondaryLicenseIds?.length > 0) ? (
                <ReferenceInput
                  required
                  source="group_id"
                  reference="companies"
                  filter={identity?.status === "Revendeur" ? { parentCompany: identity?.company } : {}}
                  sort={{ field: "company", order: "ASC" }}
                  perPage={1000}
                >
                  <AutocompleteInput
                    label="Organisation"
                    helperText="Si la licence est affectée à un utilisateur, changer d'organisation supprimera l'affectation"
                    optionText="company"
                    filterToQuery={(search: any) => ({ company: search })}
                    sx={{ marginBottom: "20px" }}
                  />
                </ReferenceInput>
              ) : (
                <ReferenceField source="group_id" reference="companies">
                  <Labeled label="Organisation">
                    <TextField source="company" mb={"20px"} />
                  </Labeled>
                </ReferenceField>
              );
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                ["Super Admin", "Administrateur"].includes(identity?.role) && (
                  <UserInput company={formData.group_id} disabled={!!formData?.mainLicenseId} />
                )
              );
            }}
          </FormDataConsumer>
          <Labeled label="Mis en service le" mt={2}>
            <DateField source="createdAt" />
          </Labeled>
          <Labeled label="Expire le" mt={2}>
            <DateField source="expiration_date" />
          </Labeled>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return formData.email ? (
                <MaxConnectionsInput />
              ) : (
                <Labeled label="Nombre de connexions simultanées" mt={2}>
                  <FunctionField
                    sx={{ width: "100%" }}
                    render={({ max_connections }: any) => <Typography>{max_connections || 1}</Typography>}
                  />
                </Labeled>
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return !formData?.connections?.length ? (
                <Labeled label="Dernières connexions" mt={2}>
                  <FunctionField sx={{ width: "100%" }} render={() => <Typography>Aucune connexion</Typography>} />
                </Labeled>
              ) : (
                <Labeled label="Dernières connexions" mt={2}>
                  <TableContainer
                    // component={Paper}
                    sx={{ width: 1, "& .MuiTableCell-root": { py: 1, px: 2 } }}
                  >
                    <Table sx={{ width: 1 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Statut</TableCell>
                          <TableCell align="right">Date connexion</TableCell>
                          <TableCell align="right">Dernière mise à jour</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formData.connections
                          .filter((notif: any, index: number) => index < 10)
                          .map((connection: any) => (
                            <TableRow
                              key={connection}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {connection.active ? "Active" : "Inactive"}
                              </TableCell>
                              <TableCell align="right">{new Date(connection.createdAt).toLocaleString()}</TableCell>
                              <TableCell align="right">{new Date(connection.updatedAt).toLocaleString()}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Labeled>
              );
            }}
          </FormDataConsumer>
          {identity?.status === "Collaborateur" && ["Super Admin", "Administrateur"].includes(identity?.role) ? (
            <>
              <div
                style={{ borderBottom: "1px solid lightgray", width: "100%", marginBottom: "28px", marginTop: "16px" }}
              />
              <Typography variant="body1" mb={"20px"}>
                Contrôle
              </Typography>
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <BooleanInput
                      defaultValue={false}
                      source="disabled"
                      label="Désactivée"
                      disabled={formData?.mainLicenseId?.length > 1}
                      helperText={
                        formData?.mainLicenseId?.length > 1
                          ? "Une licence rattachée à une licence principale n'est pas désactivable"
                          : ""
                      }
                    />
                  );
                }}
              </FormDataConsumer>
              <BooleanInput defaultValue={false} source="isAdmin" label="Licence administrateur" sx={{ mt: 2 }} />
            </>
          ) : null}
        </FormTab>
        {/* <FormTab label="Préférences" name="settings"></FormTab> */}
      </TabbedForm>
    </Edit>
  );
};

export default LicensesEdit;
