import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  Edit,
  Labeled,
  FunctionField,
  TextField,
  DateField,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRecordContext,
  useGetIdentity,
  BooleanField,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import PageTitle from "../../components/navigation/PageTitle";

const SmsToolBar = () => {
  const record = useRecordContext();
  return (
    <Toolbar>
      <SaveButton label="Sauvegarder" />
      <div style={{ flex: 1 }} />
      {!record.sent && <DeleteButton label="Annuler" />}
    </Toolbar>
  );
};

const SmsEdit = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  return (
    <Edit {...props}>
      <PageTitle text="Aperçu du SMS" />
      <SimpleForm toolbar={<SmsToolBar />}>
        <Grid container direction={"column"}>
          <Labeled label="Date d'envoi">
            <DateField source="send_date" showTime />
          </Labeled>
          {identity?.status === "Collaborateur" && (
            <Labeled label="Nom externe">
              <TextField source="external_name" />
            </Labeled>
          )}
          <Labeled label="Destinataire">
            <TextField source="phonenumber" />
          </Labeled>
          <Labeled label="Émetteur">
            <TextField source="expeditor" />
          </Labeled>
          <Labeled label="Contenu du sms">
            <TextField source="content" />
          </Labeled>
          <Labeled label="Côut en crédits du sms">
            <TextField source="length" />
          </Labeled>
          <Labeled label="Utilisation de caractère(s) unicode">
            <FunctionField
              render={(record: any) => {
                return <div> {record.isUnicode ? "Oui" : "Non"}</div>;
              }}
            />
          </Labeled>
          <Labeled label="Type">
            <FunctionField
              render={(record: any) => {
                return <div> {record.type === "reminder" ? "rappel" : "confirmation"}</div>;
              }}
            />
          </Labeled>
          <Labeled label="Envoyé">
            <BooleanField source="sent" />
          </Labeled>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default SmsEdit;
