import UsersList from './UsersList';
import UsersCreate from './UsersCreate';
import UsersEdit from './UsersEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: UsersList,
  create: UsersCreate,
  edit: UsersEdit
};
