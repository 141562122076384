import React, { useCallback, useEffect, useState } from "react";
import { SimpleForm, useRefresh, useNotify, useCreate, TextInput, useRedirect, SaveButton, Toolbar } from "react-admin";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import SmsTextInput from "../../components/inputs/SmsInput";
import SmsIcon from "@mui/icons-material/Sms";
import api from "../../services/apiWithAuth";
import { Typography } from "@mui/material";

const SendSmsToolbar = () => (
  <Toolbar>
    <SaveButton label="Envoyer" icon={<SmsIcon />} />
  </Toolbar>
);

const SendSmsAction = ({ record, identity }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  //using key for the react-admin dialog component is a hack to close the dialog after submitting
  //without interfering with the isOpen property.
  const [key, setKey] = useState(0);
  const [companyCredits, setCompanyCredits] = useState(null);

  const [create] = useCreate();
  const save = useCallback(
    async (values: any) => {
      try {
        await create("sms", { data: values }, { returnPromise: true });
        notify("Sms envoyé avec succès", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
      } catch (error) {
        notify("Erreur lors de l'envoi du sms", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
      }
      setKey((key) => key + 1);
      refresh();
    },
    [create, notify, refresh]
  );

  useEffect(() => {
    const asyncCall = async () => {
      const getCompanyCreditsRes = await api.get("/utils/getCredits/" + identity?.company);
      setCompanyCredits(getCompanyCreditsRes.data.credits);
    };
    asyncCall();
  }, [identity?.company, identity.status]);

  return (
    <CreateInDialogButton
      title={<div>{"contacts" in record ? `Envoi d'un sms à la liste de contact "${record.name}" (${record.contacts.length} contacts)` : `Envoi d'un SMS à ${record.firstname} ${record.lastname}`}</div>}
      label=""
      icon={<SmsIcon sx={{ color: "neutral.dark", fontSize: 36 }} />}
      resource="sms"
      record={{ phonenumbers: "contacts" in record ? record.contacts.map((contact: any) => contact.phonenumber) : [record.phonenumber] }}
      key={key}
    >
      {companyCredits && companyCredits >= ("contacts" in record ? record.contacts.length : 1) ? (
        <SimpleForm onSubmit={save} toolbar={<SendSmsToolbar />}>
          <Typography>Il vous reste {companyCredits} sms disponibles</Typography>
          <TextInput required source="expeditor" label="Émetteur" helperText="11 caractères au maximum" />
          <SmsTextInput source="content" defaultValue={null} label="SMS à envoyer" />
        </SimpleForm>
      ) : (
        <Typography sx={{ p: 4 }} color={"error"}>
          Vous n'avez pas assez de SMS disponibles, merci d'en racheter dans la boutique pour pouvoir continuer à utiliser
          vos services
        </Typography>
      )}
    </CreateInDialogButton>
  );
};

export default SendSmsAction;
