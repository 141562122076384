import { Container, Grid, Box, Typography, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import React from "react";
import "./DownloadPage.css";
import { fbFirestore } from "../../services/firebase/firebase.services";
import { getDocs, collection as fbCollection } from "firebase/firestore";
import axios from "axios";
import PageTitle from "../../components/navigation/PageTitle";

const DownloadCard = ({ download }: any) => {
  const [isHovered, setHovered] = useState(false);

  const downloadFile = () => {
    axios({
      url: download.software.src,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", download.software.title);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        position: "relative",
        border: "1px solid lightgrey",
        borderRadius: "10px",
        width: "400px",
        padding: "28px",
        transition: "all 0.2s",
        cursor: "pointer",
        ":hover": { backgroundColor: "rgba(0, 0, 0, 0.3)", "& img": { opacity: "0.3" } },
      }}
      onClick={() => {
        downloadFile(); //window.open(download.software.src, "_blank", "noreferrer");
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Typography variant="h6" component="h1" fontWeight={"bold"} sx={{ textAlign: "center", mb: "1rem" }}>
        {download.name}
      </Typography>

      <img
        src={download.pictures.src}
        alt={download.name}
        style={{
          height: "300px",
          objectFit: "cover",
          borderRadius: "10px",
          marginBottom: "24px",
        }}
      />

      <Typography variant="subtitle1" component="h1" sx={{ textAlign: "center", mt: "1rem", mb: "1.5rem" }}>
        {download.description}
      </Typography>

      <Typography
        variant="h5"
        component="h3"
        fontWeight={"bold"}
        sx={{
          borderRadius: "5px",
          textAlign: "center",
          bgcolor: "rgb(0 179 225)",
          padding: "15px",
          mb: "1rem",
          color: "white",
          display: isHovered ? "block" : "none",
          fontSize: "bold",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translateX(-50%) translateY(-50%)",
        }}
      >
        Télécharger
      </Typography>
    </Box>
  );
};

const DownloadPage: FC = () => {
  const [downloads, setDownloads] = useState([] as any[]);
  const theme = useTheme();

  useEffect(() => {
    const asyncCall = async () => {
      const downloads = await getDocs(fbCollection(fbFirestore, "downloads"));
      setDownloads(downloads.docs.map((p) => p.data()));
    };

    asyncCall();
  }, []);

  // JSX to be rendered
  return (
    <Container
      maxWidth={false}
      sx={{
        height: "100vh",
        backgroundColor: theme.palette.mode === "light" ? "white" : "inherit",
        color: theme.palette.mode === "light" ? "black" : "white",
      }}
    >
      <PageTitle text="Téléchargements" backButton={false} />

      <Typography variant="h6" sx={{ mt: "30px", mb: "30px" }}>
        Vous trouverez ici tout les logiciels auquels vous avez accès.
      </Typography>

      <Grid container justifyContent="start" alignItems="center" gap="25px">
        {downloads.map((p) => (
          <DownloadCard key={p.id} download={p} />
        ))}
      </Grid>
    </Container>
  );
};

export default DownloadPage;
