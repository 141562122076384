import { Typography } from "@mui/material";
import React from "react";
import {
  Edit,
  TextInput,
  SelectInput,
  required,
  ReferenceInput,
  useGetIdentity,
  Labeled,
  TextField,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import { COUNTRIES, OPPORTUNITIES_STEPS, OPPORTUNITIES_STATUSES } from "../../services/common-utils/constants";
import PageTitle from "../../components/navigation/PageTitle";

const OpportunitiesEdit = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  const onTransform = (data: any, { previousData }: any) => {
    if (data.status !== previousData.status) {
      data.seenBy = [identity?.id];
    }
    return {
      ...data,
    };
  };
  return (
    <Edit transform={onTransform} {...props}>
      <SimpleForm>
        <PageTitle text={(record: any) => record.company} />
        <TextInput defaultValue={null} source="company" label="Organisation" />
        <SelectInput
          validate={required()}
          defaultValue="France"
          source="country"
          label="Pays"
          choices={COUNTRIES.map((s) => ({ id: s, name: s }))}
        />
        <TextInput defaultValue={null} source="mainContact" label="Contact principal" />
        <TextInput defaultValue={null} source="phone" label="Téléphone" />
        <TextInput defaultValue={null} source="email" label="Email" />
        <TextInput defaultValue={null} source="note" label="Note" multiline rows={5} />
        <ReferenceInput
          label="Collaborateur responsable"
          source="collaborator_id"
          reference="collaborators"
          perPage={1000}
        >
          <SelectInput
            label="Collaborateur responsable"
            optionText={(record) => {
              return `${record.firstName} ${record.lastName} `;
            }}
          />
        </ReferenceInput>
        <SelectInput
          defaultValue={null}
          source="step"
          label="Etape"
          choices={OPPORTUNITIES_STEPS.map((s: any) => ({ id: s, name: s }))}
        />
        <SelectInput
          defaultValue={null}
          source="status"
          label="Statut"
          choices={OPPORTUNITIES_STATUSES.map((s: any) => ({ id: s, name: s }))}
        />
        <Labeled label="Source">
          <TextField source="origin" mb={2} />
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};

export default OpportunitiesEdit;
