import { Container, Grid, Box, Typography, Stack, useMediaQuery } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FC, useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "../../components/form/FormInput";
import styled from "@emotion/styled";
import React from "react";
import { useInterval } from "usehooks-ts";
import "./ForgottenPasswordPage.css";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

// Styled React Route Dom Link Component
export const LinkItem = styled(Link)`
  text-decoration: none;
  color: #3683dc;
  &:hover {
    text-decoration: underline;
    color: #5ea1b6;
  }
`;

// Forgotten password schema with Zod
const forgottenPasswordSchema = object({
  email: string().min(1, "Adresse mail requise").email("Adresse mail invalide"),
});

// Infer the Schema to get the TS Type
type IForgottenPassword = TypeOf<typeof forgottenPasswordSchema>;

const ForgottenPasswordPage: FC = () => {
  const logoPath = require("../../assets/logo.svg").default as string;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Default Values
  const defaultValues: IForgottenPassword = {
    email: "",
  };

  // The object returned from useForm Hook
  const methods = useForm<IForgottenPassword>({
    resolver: zodResolver(forgottenPasswordSchema),
    defaultValues,
  });

  // Submit Handler
  const onSubmitHandler: SubmitHandler<IForgottenPassword> = async (values: IForgottenPassword) => {
    // Initialize progress bar styles
    setError("");

    // Send email
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, values.email);
      setSuccess("Un mail vient de vous être envoyé.");
    } catch (err) {
      setError("Email introuvable");
    }
  };

  // JSX to be rendered
  return (
    <Container maxWidth={false} sx={{ height: "100vh", backgroundColor: ["#fff", "#cff3bd"] }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ width: 1, height: 1 }}>
        <Grid item sx={{ backgroundColor: "#fff", width: [1, 500], mt: -5 }}>
          <FormProvider {...methods}>
            <Grid
              container
              position="relative"
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                py: [2, 6],
                px: [3, 8],
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={methods.handleSubmit(onSubmitHandler)}
                sx={{ width: 1, "& .MuiFormControl-root": { width: 1, mb: 2 }, "& .MuiInputBase-root": { width: 1 } }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: [0.5, 1] }}
                >
                  <img
                    src={logoPath}
                    alt="digisoft"
                    className="digisoft-logo-login"
                    style={{ width: "100%", maxWidth: "260px", height: "auto", margin: 0 }}
                  />
                </Box>
                <Typography
                  color="primary"
                  sx={{ textAlign: "center", fontWeight: "bold", fontSize: ["1.6rem", "2rem"] }}
                >
                  Réinitialisation
                </Typography>
                <Typography sx={{ textAlign: "center", fontSize: "1rem", mt: -1 }}>
                  Récupérez votre mot de passe
                </Typography>

                <Typography
                  variant="subtitle1"
                  component="h1"
                  sx={{ textAlign: "center", mb: "1.5rem", color: "#CD5C5C" }}
                >
                  {error}
                </Typography>

                <Typography
                  variant="subtitle1"
                  component="h1"
                  sx={{
                    textAlign: "center",
                    mb: "1.5rem",
                    bgcolor: "secondary.light",
                    color: "black",
                    borderRadius: 1,
                  }}
                >
                  {success}
                </Typography>
                {success && (
                  <Typography
                    variant="subtitle1"
                    component="h1"
                    sx={{ textAlign: "center", mb: "1.5rem", color: "success", fontSize: "0.8rem" }}
                  >
                    Un e-mail de réinitialisation du mot de passe a été envoyé sur votre adresse, mais il peut mettre
                    plusieurs minutes à s'afficher dans votre boite de réception. Veuillez attendre au moins 10 minutes
                    avant de tenter une autre réinitialisation.
                  </Typography>
                )}

                <FormInput
                  label="Saisissez votre adresse email"
                  type="email"
                  name="email"
                  required
                  InputLabelProps={{ required: false }}
                  disabled={methods.formState.isSubmitting}
                />

                <LoadingButton
                  loading={methods.formState.isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    width: 1,
                    marginInline: "auto",
                  }}
                >
                  Envoyer {">>"}
                </LoadingButton>
                <Stack sx={{ mt: "1rem", textAlign: "center" }}>
                  Vous vous souvenez de votre mot de passe ? <LinkItem to="/login">Connectez-vous</LinkItem>
                </Stack>
              </Box>
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgottenPasswordPage;
