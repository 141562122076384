import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  Divider,
  InputAdornment,
  FormControlLabel,
  FormGroup,
  Checkbox,
  useTheme,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import "./CreditsPage.css";
import {
  useGetIdentity,
  useDataProvider,
  List,
  TextField,
  DateField,
  FunctionField,
  ReferenceField,
  Count,
  useListContext,
} from "react-admin";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import api from "../../services/apiWithAuth";
import FormInput from "../../components/form/FormInput";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import PageTitle from "../../components/navigation/PageTitle";
import { query, where } from "firebase/firestore";

const TransferCard = ({ handleUpdate, setCompany }: any) => {
  const { isLoading, data: identity } = useGetIdentity();
  const theme = useTheme();
  const [companyCredits, setCompanyCredits] = useState("");
  const [companyToTransferCredits, setCompanyToTransferCredits] = useState("");
  const [message, setMessage] = useState("");
  const [companies, setCompanies] = useState([] as any);
  const dataProvider = useDataProvider();
  const methods = useForm();
  const [companyToTransfer] = useWatch({ control: methods.control, name: ["company"] });
  const [action] = useWatch({ control: methods.control, name: ["action"] });

  useEffect(() => {
    if (!isLoading) {
      const asyncCall = async () => {
        if (["Revendeur", "Client", "Indirect"].includes(identity?.status)) {
          const getCreditsRes = await api.get("/utils/getCredits/" + identity?.company);
          setCompanyCredits(getCreditsRes.data.credits);
        }
        if (["Collaborateur", "Revendeur"].includes(identity?.status)) {
          const companiesRes = await dataProvider.getList("companies", {
            filter:
              identity?.status === "Collaborateur"
                ? {}
                : {
                    collectionQuery: (c: any) =>
                      query(c, where("id", "!=", identity?.company), where("parentCompany", "==", identity?.company)),
                  },
            pagination: { page: 1, perPage: 100 },
            sort: { field: "company", order: "ASC" },
          });
          if (companiesRes.data.length) {
            setCompanies(
              companiesRes.data.map((company: any) => {
                return {
                  id: company.id,
                  label: company.company,
                };
              })
            );
          }
        }
      };
      asyncCall();
    }
  }, [isLoading, identity, dataProvider, methods]);

  useEffect(() => {
    if (!isLoading && companyToTransfer && ["Collaborateur", "Revendeur"].includes(identity?.status)) {
      const asyncCall = async () => {
        const getCompanyToTransferCreditsRes = await api.get("/utils/getCredits/" + companyToTransfer);
        setCompanyToTransferCredits(getCompanyToTransferCreditsRes.data.credits);
      };
      asyncCall();
    }
  }, [companyToTransfer, action, isLoading, identity?.company, identity?.status]);

  const onSubmit = async ({ action, company, quantity }: any) => {
    try {
      if (identity?.status === "Revendeur") {
        const companyToDebit = action === "add" ? identity?.company : company;
        await api.post("/utils/transferSMS", {
          groupId: companyToDebit,
          action: "remove",
          amount: quantity,
        });
        const companyToCredit = action === "add" ? company : identity?.company;
        await api.post("/utils/transferSMS", {
          groupId: companyToCredit,
          action: "add",
          amount: quantity,
        });
      } else {
        //Collaborateur
        await api.post("/utils/transferSMS", {
          groupId: company,
          action,
          amount: quantity,
        });
      }
      const getCompanyToTransferCreditsRes = await api.get("/utils/getCredits/" + companyToTransfer);
      setCompanyToTransferCredits(getCompanyToTransferCreditsRes.data.credits);
      if (identity?.status === "Revendeur") {
        const getCompanyCreditsRes = await api.get("/utils/getCredits/" + identity?.company);
        setCompanyCredits(getCompanyCreditsRes.data.credits);
      }
      setMessage(quantity + " SMS ont été " + (action === "add" ? "crédités à" : "débités de") + " l'organisation.");
      handleUpdate();
    } catch (err) {
      setMessage("Erreur lors du transfert de SMS");
    }
  };

  useEffect(() => {
    if (companyToTransfer) {
      setCompany(companyToTransfer);
    }
  }, [companyToTransfer, setCompany]);

  return isLoading ? null : (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        bgcolor: "primary.main",
        borderRadius: "10px",
        padding: "40px 28px",
        maxWidth: [1, "inherit"],
        "& .MuiInputBase-root": {
          backgroundColor: theme.palette.mode === "light" ? "white" : "inherit",
          color: "black",
          borderColor: theme.palette.mode === "light" ? "white" : "inherit",
          mb: "0",
          width: [300, 350],
        },
        "& .MuiInputLabel-root": {
          color: "black",
        },
        "& .MuiFormControl-root": { mb: "0", mt: "0" },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: ["column", "row"] }} gap="50px" mb="15px">
        {["Revendeur", "Client", "Indirect"].includes(identity?.status) && (
          <div
            style={{
              padding: "10px",
              backgroundColor: theme.palette.mode === "light" ? "white" : "inherit",
              width: "fit-content",
              height: "45px",
              borderRadius: "5px",
              alignSelf: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              component="h1"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Mes crédits : {companyCredits === "" ? "..." : companyCredits} SMS
            </Typography>
          </div>
        )}
        {["Collaborateur", "Revendeur"].includes(identity?.status) && companies.length > 0 && (
          <div
            style={{
              padding: "10px",
              color: "black",
              backgroundColor: theme.palette.mode === "light" ? "white" : "inherit",
              width: "fit-content",
              height: "45px",
              borderRadius: "5px",
              alignSelf: "center",
            }}
          >
            <Typography variant="subtitle1" component="h1" sx={{ textAlign: "center", fontWeight: "bold" }}>
              Crédits du client : {companyToTransferCredits === "" ? "..." : companyToTransferCredits} SMS
            </Typography>
          </div>
        )}
      </Box>

      {["Collaborateur", "Revendeur"].includes(identity?.status) && companies.length > 0 && (
        <>
          <FormProvider {...methods}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="32px"
              mt="20px"
              component="form"
              autoComplete="off"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: ["column", "row"],
                  color: theme.palette.mode === "light" ? "white" : "black",
                }}
                gap="32px"
              >
                <FormInput
                  type="input"
                  name="company"
                  values={companies}
                  label={!companyToTransfer ? "Organisation" : ""}
                  select
                  required
                  disabled={isLoading}
                />
                <FormInput
                  type="input"
                  name="action"
                  defaultValue={"add"}
                  values={[
                    { id: "add", label: "Créditer" },
                    { id: "remove", label: "Débiter" },
                  ]}
                  select
                  required
                  disabled={isLoading}
                />
                {identity?.status === "Collaborateur" ? (
                  <FormInput
                    defaultValue={100}
                    required
                    type="number"
                    name="quantity"
                    disabled={isLoading}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">SMS</InputAdornment>,
                    }}
                  />
                ) : (
                  <FormInput
                    type="input"
                    name="quantity"
                    defaultValue={"100"}
                    values={[
                      { id: "100", label: "100 SMS" },
                      { id: "200", label: "200 SMS" },
                      { id: "500", label: "500 SMS" },
                      { id: "1000", label: "1000 SMS" },
                      { id: "2000", label: "2000 SMS" },
                      { id: "5000", label: "5000 SMS" },
                      { id: "10000", label: "10000 SMS" },
                    ]}
                    select
                    required
                    disabled={isLoading}
                  />
                )}
                <LoadingButton
                  loading={methods.formState.isSubmitting}
                  type="submit"
                  disabled={identity?.status === "Revendeur" && !companyCredits}
                  variant="contained"
                  sx={{
                    backgroundColor: "green",
                    height: "38px",
                  }}
                >
                  Transférer
                </LoadingButton>
              </Box>
            </Box>
          </FormProvider>
          {message && !methods.formState.isSubmitting && (
            <Typography
              variant="subtitle1"
              component="h3"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: theme.palette.mode === "light" ? "white" : "inherit",
                mt: "20px",
              }}
            >
              {message}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

const CreditsList = ({ company }: any) => {
  return (
    <List
      filter={company ? { group_id: company } : {}}
      sort={{ field: "createdAt", order: "DESC" }}
      hasCreate={false}
      exporter={false}
      actions={false}
      resource={"transfers"}
      empty={<span>Aucun transfert de Sms</span>}
      sx={{ width: "100%" }}
      filterDefaultValues={{ tabFilter: "add" }}
    >
      <TabbedDatagrid permanentFilter={company ? { group_id: company } : {}} />
    </List>
  );
};

const tabs = [
  { id: "add", name: "Crédits" },
  { id: "remove", name: "Débits" },
];

const TabbedDatagrid = ({ permanentFilter }: any) => {
  const { isLoading, data: identity } = useGetIdentity();
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;
  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters(
          { tabFilter: value },
          displayedFilters,
          false // no debounce, we want the filter to fire immediately
        );
    },
    [displayedFilters, setFilters]
  );

  return isLoading ? null : (
    <>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.tabFilter}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => {
          return (
            <Tab
              key={choice.id}
              label={
                <span>
                  {choice.name} (
                  <Count
                    filter={{
                      ...filterValues,
                      ...permanentFilter,
                      tabFilter: choice.id,
                    }}
                    sx={{ lineHeight: "inherit" }}
                  />
                  )
                </span>
              }
              value={choice.id}
            />
          );
        })}
      </Tabs>
      <Divider />
      <DigisoftDatagrid bulkActionButtons={false} rowClick={false}>
        <DateField label="Date d'opération" source="createdAt" showTime={true} />
        {identity?.status === "Collaborateur" && (
          <FunctionField
            label="Source"
            render={({ fromOrder, fromSubscription }: any) => (
              <Typography fontSize="0.85rem">
                {fromOrder || fromSubscription ? "Commande / Abonnement" : "Interne"}
              </Typography>
            )}
          />
        )}
        {["Collaborateur", "Revendeur"].includes(identity?.status) && (
          <ReferenceField label="Organisation" source="group_id" reference="companies" link={false}>
            <TextField source="company" />
          </ReferenceField>
        )}
        <FunctionField
          label="SMS"
          render={(record: any) => (
            <div style={{ color: record.type === "add" ? "#58A14E" : "#CD5C5C" }}>
              {record.type === "add" ? "+" : "-"}
              {record.amount}
            </div>
          )}
        />
      </DigisoftDatagrid>
    </>
  );
};

const CreditsPage = () => {
  const [instanceKey, setInstanceKey] = useState(0);
  const theme = useTheme();
  const [company, setCompany] = useState<string | null>(null);
  const [showTransfers, setShowTransfers] = useState(false);
  const handleUpdate = () => setInstanceKey((i) => i + 1);

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundColor: theme.palette.mode === "light" ? "white" : "inherit",
        color: theme.palette.mode === "light" ? "black" : "white",
      }}
    >
      <PageTitle text="Crédits SMS" backButton={false} />
      <TransferCard handleUpdate={handleUpdate} setCompany={setCompany} />
      <FormGroup sx={{ width: 1, mt: 4, ml: 4 }}>
        <FormControlLabel
          control={
            <Checkbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              color="primary"
              checked={showTransfers}
              onClick={() => setShowTransfers(!showTransfers)}
            />
          }
          label="Afficher les transferts de SMS"
        />
      </FormGroup>
      {showTransfers && <CreditsList key={instanceKey} company={company} />}
    </Box>
  );
};

export default CreditsPage;
