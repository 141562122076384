import * as React from "react";
import Card from "@mui/material/Card";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { useGetIdentity, useGetList } from "react-admin";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Skeleton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FormInput from "../../components/form/FormInput";
import api from "../../services/apiWithAuth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { fbFirestore } from "../../services/firebase/firebase.services";
import SmsStats from "../sms/SmsStats";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import PaymentIcon from "@mui/icons-material/Payment";
import LinearProgressBar from "../../components/others/LinearProgressBar";
import FirstStepsDialog from "./FirstStepsDialog";

const TryItCard = ({ identity, refetchIdentity, daysTillFreeTryEnd, ...props }: any) => {
  const [software, setSoftware] = useState({} as any);
  const [error, setError] = useState("");
  const [step, setStep] = useState(identity?.freeTry.licenseId ? 1 : 0);
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      email: identity?.email,
    },
  });
  const [email] = useWatch({ control: methods.control, name: ["email"] });

  useEffect(() => {
    const asyncCall = async () => {
      //temporary fix, tryitcard will disappear very soon
      const download = await getDoc(doc(fbFirestore, "downloads", "FgNwSf0qhkNzw5KDdhSt"));
      setSoftware(download?.data());
    };

    asyncCall();
  }, []);

  const downloadFile = (download: any) => {
    axios({
      url: download.software.src,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", download.software.title);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  const onSubmit = async ({ email }: any) => {
    try {
      await api.post("/utils/beginTryPeriod", { email });
      setStep(1);
      refetchIdentity();
    } catch (err) {
      if (err.response && err.response.status === 409) {
        setError("Un utilisateur existe déjà avec cette adresse e-mail. Merci d'en saisir une autre.");
      } else {
        setError("Création de la licence d'essai impossible, merci de contacter un administrateur");
      }
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        width: 0.95,
        bgcolor: "primary.main",
        borderRadius: "10px",
        padding: "14px",
        "& .MuiInputBase-root": { backgroundColor: "white", color: "black", borderColor: "white", mb: "0" },
        "& .MuiFormControl-root": { mb: "0", mt: "0" },
      }}
    >
      {step === 0 ? (
        <>
          <Typography variant="h5" color="white" sx={{ mb: 3 }}>
            Découvrer gratuitement et sans engagement notre solution pour une durée de 14 jours !
          </Typography>
          <FormProvider {...methods}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="32px"
              mt="20px"
              component="form"
              autoComplete="off"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <FormInput required name="email" placeholder="Votre email" />
              {!methods.formState.isSubmitting && error && (
                <Typography
                  color="error"
                  sx={{
                    color: "white",
                    bgcolor: "error.main",
                    px: 1,
                    py: 0.5,
                    borderRadius: 2,
                    width: "fit-content",
                    fontSize: "0.9rem",
                  }}
                >
                  {error}
                </Typography>
              )}
              <LoadingButton
                loading={methods.formState.isSubmitting}
                type="submit"
                disabled={!email}
                variant="contained"
                sx={{
                  backgroundColor: "secondary.main",
                  height: "38px",
                }}
              >
                Commencer l'essai gratuit
              </LoadingButton>
            </Box>
          </FormProvider>
        </>
      ) : (
        <Box sx={{ color: "white" }}>
          <Typography variant={"h5"} sx={{ mb: 3 }} textAlign="center">
            Félicitation, votre licence d'essai à bien été créée sur l'adresse e-mail renseignée.
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography
              textAlign="center"
              sx={{
                color: "white",
                bgcolor: "warning.dark",
                px: 3,
                py: 0.6,
                mb: 2,
                borderRadius: 2,
                width: "fit-content",
                fontSize: "1.1rem",
                opacity: "0.95",
              }}
            >
              Jours restants avant expiration :{" "}
              <b>
                {Math.floor(
                  (new Date(identity?.freeTry.expirationDate).valueOf() -
                    new Date(new Date().toISOString().split("T")[0]).valueOf()) /
                    86400000
                )}
              </b>
            </Typography>
          </Box>
          <Typography sx={{ mb: 2, fontSize: "1rem" }}>
            Vous pouvez dès à présent tester les fonctionnalités de notre module SMS Meeting, pour cela veuillez suivre
            les étapes décrites ci-dessous :
          </Typography>
          <Typography sx={{ mb: 1, fontSize: "1rem" }}>
            1) Télécharger l’application SMS Meeting en cliquant sur{" "}
            <span style={{ fontWeight: "bold", cursor: "pointer" }} onClick={() => downloadFile(software)}>
              ce lien
            </span>
          </Typography>
          <Typography sx={{ mb: 1, fontSize: "1rem" }}>
            2) Fermer Outlook et Installer l’application SMS Meeting sur votre ordinateur
          </Typography>
          <Typography sx={{ mb: 1, fontSize: "1rem" }}>
            3) Réouvrer Outlook, dans le ruban « section SMS Meeting » cliquer sur le bouton « Paramètres » puis sur
            l’onglet « Sécurité »
          </Typography>
          <Typography sx={{ mb: 1, fontSize: "1rem" }}>
            4) Cliquer sur le bouton « Envoyer code magique » puis fermer la fenêtre « Paramètres »
          </Typography>
          <Typography sx={{ mb: 1, fontSize: "1rem" }}>
            5) Consulter votre boite email, copier le code magique, répéter l’étape 3 puis renseigner le code et activer
            votre licence
          </Typography>
          <Typography sx={{ mb: 3, fontSize: "1rem" }}>
            A l’issue de votre période d’essai gratuite, Si vous souhaitez continuer à utiliser les services d’envoi de
            SMS depuis Outlook, vous devrez vous rendre dans la boutique pour acheter une licence SMS Meeting.
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Button
              sx={{ p: 1.3, mt: 2 }}
              variant="contained"
              color="secondary"
              onClick={() => {
                navigate("/shop");
              }}
              startIcon={<LocalMallIcon />}
            >
              <Typography fontWeight="400">Boutique</Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const ShopCard = ({ identity, freeTryAvailable, ...props }: any) => {
  const [companyCredits, setCompanyCredits] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!companyCredits) {
      const asyncCall = async () => {
        const getCreditsRes = await api.get("/utils/getCredits/" + identity?.company);
        setCompanyCredits(getCreditsRes.data.credits);
      };
      asyncCall();
    }
  }, [companyCredits, identity?.company]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        height: ["auto", 0.43],
        bgcolor: "primary.main",
        borderRadius: "10px",
        padding: "14px",
        textAlign: "center",
      }}
    >
      <Typography variant="h5" color="white" sx={{ mb: 3 }}>
        Solde SMS
      </Typography>
      <Typography color="white" sx={{ mb: 1, width: 1 }}>
        Il vous reste <b>{companyCredits}</b> crédits SMS disponibles.
      </Typography>
      <Typography color="white" sx={{ mb: 1 }}>
        Pour en racheter afin d'être sur de ne pas en manquer, n'hésitez pas à vous rendre dans la boutique.
      </Typography>
      <Button
        sx={{ p: 1.3, mt: 2 }}
        variant="contained"
        color="secondary"
        onClick={() => {
          navigate("/shop");
        }}
        startIcon={<LocalMallIcon />}
      >
        <Typography fontWeight="400">Acheter SMS</Typography>
      </Button>
    </Box>
  );
};

const SubscriptionsCard = ({ identity, ...props }: any) => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetList("subscriptions", {
    pagination: { page: 1, perPage: 4 },
    filter: {
      collectionQuery: (c: any) => query(c, where("company_id", "==", identity?.company)),
    },
    sort: { field: "subscriptionDate", order: "DESC" },
  });
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        height: ["auto", 0.55],
        bgcolor: "primary.main",
        borderRadius: "10px",
        paddingTop: "14px",
      }}
    >
      <Typography variant="h5" color="white">
        Mes abonnements
      </Typography>
      {isLoading ? null : (
        <TableContainer component={Paper} sx={{ width: 0.9, mt: 2, "& .MuiTableCell-root": { py: 1, px: 1 } }}>
          <Table sx={{ width: 1 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Référence</TableCell>
                <TableCell align="right">Montant TTC</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Statut</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((subscription: any) => (
                <TableRow
                  key={subscription}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    ":hover": { cursor: "pointer", bgcolor: "secondary.main" },
                  }}
                  onClick={() => {
                    navigate("/subscriptions/" + subscription.id);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {subscription.id}
                  </TableCell>
                  <TableCell align="right">{subscription.billingPriceTtc}€</TableCell>
                  <TableCell align="right">{subscription.type}</TableCell>
                  <TableCell align="right">{subscription.cancelled ? "Annulé" : "En cours"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Button
        sx={{ p: 1.3, my: 3 }}
        variant="contained"
        color="secondary"
        onClick={() => {
          navigate("/subscriptions");
        }}
        startIcon={<PaymentIcon />}
      >
        <Typography fontWeight="400">Tous mes abonnements</Typography>
      </Button>
    </Box>
  );
};

const StatsCard = ({ identity, refetchIdentity, daysTillFreeTryEnd, ...props }: any) => {
  const [companyCredits, setCompanyCredits] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!companyCredits) {
      const asyncCall = async () => {
        const getCreditsRes = await api.get("/utils/getCredits/" + identity?.company);
        setCompanyCredits(getCreditsRes.data.credits);
      };
      asyncCall();
    }
  }, [companyCredits, identity?.company]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        width: [1, 0.48],
        border: "solid 2px",
        borderColor: "primary.main",
        borderRadius: "10px",
        padding: "14px",
        height: ["auto", 0.85],
        mb: [2, 0],
      }}
    >
      <Typography variant="h5">Statistiques</Typography>
      <SmsStats widthComputer={0.8} showLegend={false} />
      <Button
        sx={{ p: 1.3, mt: 2 }}
        variant="contained"
        color="secondary"
        onClick={() => {
          navigate("/sms");
        }}
        startIcon={<VisibilityIcon />}
      >
        <Typography fontWeight="400">Statistiques détaillées</Typography>
      </Button>
    </Box>
  );
};

const FirstStepsHeader = ({ identity }: any) => {
  const [completedSteps, setCompletedSteps] = useState([]);

  useEffect(() => {
    if (!completedSteps.length || (completedSteps.length === 1 && !identity?.freeTry.available)) {
      const asyncCall = async () => {
        const getCompletedStepsRes = await api.get("/utils/getCompletedSteps/" + identity?.company);
        setCompletedSteps(getCompletedStepsRes.data);
      };
      asyncCall();
    }
  }, [completedSteps, identity?.company, identity?.freeTry.available]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: 1,
        bgcolor: "neutral.darker",
        color: "white",
        padding: 0,
        mb: [1, 0],
      }}
    >
      <Typography fontWeight="bold">Premiers pas</Typography>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={{
          width: 0.4,
          px: 2,
        }}
      >
        {completedSteps.length > 0 ? (
          <LinearProgressBar currentStep={completedSteps.length} stepsNumber={6} />
        ) : (
          <Skeleton variant="rectangular" sx={{ width: 1 }} />
        )}
      </Box>
      {completedSteps.length > 0 ? (
        <Typography fontWeight="400">{completedSteps.length}/6 terminé</Typography>
      ) : (
        <Skeleton variant="rectangular" sx={{ width: "100px" }} />
      )}
      <FirstStepsDialog completedSteps={completedSteps} />
    </Box>
  );
};

const DashboardPage = () => {
  const { isLoading, data: identity, refetch: refetchIdentity } = useGetIdentity();
  let daysTillFreeTryEnd = 0;
  if (identity?.status !== "Collaborateur") {
    daysTillFreeTryEnd = Math.floor(
      (new Date(identity?.freeTry.expirationDate).valueOf() -
        new Date(new Date().toISOString().split("T")[0]).valueOf()) /
        86400000
    );
  }

  return isLoading ? null : (
    <Card
      sx={{
        height: ["auto", "85vh"],
      }}
    >
      {/* {identity?.status !== "Collaborateur" && <FirstStepsHeader identity={identity} />} */}
      <Typography variant="h4" textAlign={"center"} mt={2} mb={3}>
        Bienvenue dans votre espace My SMS Meeting
      </Typography>
      {identity?.status !== "Collaborateur" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "space-around",
            alignContent: "center",
            height: ["auto", 1],
          }}
        >
          {!identity?.freeTry?.licenseConverted &&
          (identity?.freeTry.available || (identity?.freeTry.expirationDate && daysTillFreeTryEnd >= 1)) ? (
            <TryItCard identity={identity} refetchIdentity={refetchIdentity} daysTillFreeTryEnd={daysTillFreeTryEnd} />
          ) : (
            <>
              <StatsCard identity={identity} />
              <Box
                sx={{
                  width: [1, 0.48],
                  height: ["auto", 0.85],
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: [2, 0],
                }}
              >
                <SubscriptionsCard identity={identity} />
                <ShopCard identity={identity} />
              </Box>
            </>
          )}
        </Box>
      )}
    </Card>
  );
};

export default DashboardPage;
