import { Box, Typography } from "@mui/material";
import { DualListInput } from "@react-admin/ra-relationships";
import React, { useEffect } from "react";
import { AutocompleteInput, useChoicesContext } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

const AutocompleteDualListInput = ({ ...props }: any) => {
  const { allChoices } = useChoicesContext();
  const itemToAdd = useWatch({ name: "itemToAdd" });
  const itemToRemove = useWatch({ name: "itemToRemove" });
  const items = useWatch({ name: props.source });
  const { setValue } = useFormContext();

  useEffect(() => {
    if (itemToAdd) {
      setValue("itemToAdd", undefined);
      setValue(props.source, items.concat(itemToAdd));
    }
    if (itemToRemove) {
      setValue("itemToRemove", undefined);
      setValue(
        props.source,
        items.filter((item: any) => item !== itemToRemove)
      );
    }
  }, [itemToAdd, itemToRemove, setValue, items, props.source]);

  useEffect(() => {
    if (!items) {
      setValue(props.source, []);
    }
  }, [items, props.source, setValue]);

  return (
    <Box>
      <Typography color="neutral.dark" fontSize={13} mb={0}>
        {props.label}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <AutocompleteInput
          sx={{ mt: 0, pt: 0, "& .MuiTextField-root": { marginBottom: 0 } }}
          choices={allChoices?.filter((choice) => (items ? !items.some((item: any) => item === choice.id) : []))}
          optionText={props.optionText}
          label="Ajout rapide"
          filterToQuery={(search: any) => ({ [props.queryField]: search })}
          debounce={500}
          helperText={false}
          source="itemToAdd"
        />
        <AutocompleteInput
          sx={{ mt: 0, pt: 0, "& .MuiTextField-root": { marginBottom: 0 } }}
          choices={allChoices?.filter((choice) => items?.some((item: any) => item === choice.id)) || []}
          optionText={props.optionText}
          label="Retrait rapide"
          filterToQuery={(search: any) => ({ [props.queryField]: search })}
          debounce={500}
          helperText={false}
          source="itemToRemove"
        />
      </Box>

      <DualListInput
        sx={{
          mt: 0,
          pt: 0,
          "& .MuiListSubheader-root": { display: "none" },
          "& .MuiList-root": { width: 350 },
        }}
        {...props}
        label=""
      />
    </Box>
  );
};

export default AutocompleteDualListInput;
