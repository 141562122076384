import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

const CartHeader = ({ widths, labels }: any) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        border: "1px solid lightgrey",
        width: "100%",
        p: [1, 2],
        "& .MuiTypography-root": { fontSize: ["0.8rem", "inherit"] },
        backgroundColor: theme.palette.mode === "light" ? "white" : "inherit",
        color: theme.palette.mode === "light" ? "black" : "white",
      }}
    >
      {widths.map((width: [], index: number) => (
        <Box
          key={labels[index]}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{ width }}
          borderRight={index !== widths.length - 1 ? "1px solid lightgrey" : "none"}
        >
          <Typography variant="h6" component="h1" fontWeight={"bold"} sx={{ textAlign: "center" }}>
            {labels[index]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default CartHeader;
