import {
  Container,
  Grid,
  Typography,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  useTheme,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import React from "react";
import { useLocalStorage } from "usehooks-ts";
import { fbFirestore } from "../../../services/firebase/firebase.services";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import AddIcon from "@mui/icons-material/Add";
import { useGetIdentity } from "react-admin";
import priceCalculator from "../../../services/common-utils/priceCalculator";
import PageTitle from "../../../components/navigation/PageTitle";

const Price = ({ totals }: any) => {
  return (
    <>
      <span style={{ textDecorationLine: totals.totalHt < totals.withoutDiscount.totalHt ? "line-through" : "none" }}>
        {totals.withoutDiscount.totalHt.toFixed(2)}€
      </span>
      {totals.totalHt < totals.withoutDiscount.totalHt && (
        <span style={{ color: "green" }}> {totals.totalHt.toFixed(2)}€</span>
      )}
    </>
  );
};

const ProductPage: FC = () => {
  const theme = useTheme();
  const [product, setProduct] = useState<any | null>();
  const [discount, setDiscount] = useLocalStorage("discount", ({} as any) || null);
  const [value, setValue] = useState(0 as number);
  //const [varsRaw, setVarsRaw] = useState<any[]>([])
  const [quantity, setQuantity] = useState(1);
  const location = useLocation();
  const productId = location.pathname.split("/").at(-1);
  const [cart, setCart] = useLocalStorage("cart", [] as any[]);
  const navigate = useNavigate();
  const { isLoading, identity } = useGetIdentity();

  useEffect(() => {
    const asyncCall = async () => {
      const product = (await getDoc(doc(fbFirestore, "products", productId as string))).data();
      const periodLabels: any = {
        Quotidienne: "/ jour",
        Mensuelle: "/ mois",
        Annuelle: "/ an",
      };
      if (product) {
        product.tarifs = product?.tarifs
          .filter(
            (tarif: any) =>
              tarif.countries.includes(identity?.companyCountry) &&
              tarif.companyStatus.includes(identity?.status) &&
              tarif.companyTypes.includes(identity?.type) &&
              (!tarif?.companyIds?.length || tarif.companyIds.includes(identity?.company)) &&
              !(tarif?.blackListedCompanyIds || []).includes(identity?.company)
          )
          .map((tarif: any) => {
            return {
              ...tarif,
              priceHT: tarif.price / (tarif.includeTaxes ? 1 + tarif.taxRate / 100 : 1),
              periodLabel: tarif.isRecurring ? periodLabels[tarif.billingPeriod] : "",
              choiceLabel:
                tarif.paymentModel === "Forfait"
                  ? `${tarif.packageSize} ${tarif.packUnitLabel}`
                  : "" + tarif.tarifDescription,
            };
          });
      }
      setProduct(product);
    };

    asyncCall();
  }, [identity?.companyCountry, identity?.status, identity?.type, productId]);

  return isLoading || !product?.name ? null : (
    <Container
      maxWidth={false}
      sx={{
        height: "100vh",
        backgroundColor: theme.palette.mode === "light" ? "white" : "inherit",
        color: theme.palette.mode === "light" ? "black" : "white",
      }}
    >
      <PageTitle text={`Détail du produit - ${product.name}`} backUrl="/shop" divider />
      <Grid container sx={{ flexDirection: { xs: "column", sm: "row" }, mt: 4 }} gap={"50px"}>
        <Stack gap={"10px"} sx={{ width: { xs: "90%", sm: "30%" } }}>
          <img
            src={product.pictures[0].src}
            alt={product.name}
            style={{
              height: "350px",
              objectFit: "cover",
              borderRadius: "5px",
              border: "1px solid lightgrey",
            }}
          />

          <Typography variant="h5" sx={{ mb: "0px" }}>
            Description
          </Typography>

          <div style={{ backgroundColor: "grey", height: "1px", width: "50%", marginBottom: "15px" }} />

          <Typography variant="body1" sx={{ mb: "15px" }}>
            {product?.description}
          </Typography>
        </Stack>

        <Stack gap={"10px"} sx={{ width: { xs: "90%", sm: "30%" } }}>
          <Typography variant="h5">Options disponibles</Typography>
          <div style={{ backgroundColor: "grey", height: "1px", width: "50%", marginBottom: "15px" }} />

          <RadioGroup value={value}>
            {product?.tarifs.map((tarif: any, index: number) => (
              <div
                key={index}
                style={{
                  padding: "5px 20px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <FormControlLabel
                  value={index}
                  control={<Radio />}
                  label={tarif.choiceLabel}
                  onClick={() => setValue(index)}
                />
                <div style={{ flexGrow: 1 }} />
                <span>
                  <b>
                    <Price
                      totals={priceCalculator(
                        [{ product, tarifId: index, quantity: 1 }],
                        discount,
                        identity?.companyCountry
                      )}
                    />{" "}
                    HT {tarif.periodLabel}{" "}
                  </b>
                </span>
              </div>
            ))}
          </RadioGroup>

          <Typography variant="h5" sx={{ mt: "10px" }}>
            Quantité
          </Typography>

          <div style={{ backgroundColor: "grey", height: "1px", width: "50%", marginBottom: "15px" }} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "10px",
              gap: "30px",
              border: "1px solid lightgrey",
              width: "fit-content",
              borderRadius: "5px",
            }}
          >
            <button
              style={{
                cursor: "pointer",
                border: 0,
                fontSize: "25px",
                backgroundColor: theme.palette.mode === "light" ? "lightgray" : "inherit",
                width: "40px",
                height: "40px",
              }}
              onClick={() => setQuantity(Math.max(quantity - 1, 1))}
            >
              -
            </button>
            <span style={{ fontSize: "23px" }}>
              {quantity} {product && product.unitLabel && product.unitLabel + (quantity > 1 ? "s" : "")}
            </span>
            <button
              style={{
                cursor: "pointer",
                border: 0,
                fontSize: "25px",
                backgroundColor: theme.palette.mode === "light" ? "lightgray" : "inherit",
                width: "40px",
                height: "40px",
              }}
              onClick={() => setQuantity(quantity + 1)}
            >
              +
            </button>
          </div>
        </Stack>

        <Stack gap={"10px"} sx={{ width: { xs: "90%", sm: "30%" } }}>
          <Typography variant="h5">Récapitulatif</Typography>
          <div style={{ backgroundColor: "grey", height: "1px", width: "50%", marginBottom: "15px" }} />

          {product &&
            priceCalculator([{ product, tarifId: value, quantity }], discount, identity?.companyCountry).totalHt <
              priceCalculator([{ product, tarifId: value, quantity }], discount, identity?.companyCountry)
                .withoutDiscount.totalHt && <Typography variant="h6">Promo : {discount.name}</Typography>}
          <Typography variant="h6" sx={{ mb: "15px" }}>
            Prix total:{" "}
            {/* <b>
              {((product?.tarifs[value].priceHT || 0) * quantity).toFixed(2)} &euro; HT{" "}
              {product?.tarifs[value]?.periodLabel}
            </b> */}
            <b>
              {product ? (
                <Price
                  totals={priceCalculator([{ product, tarifId: value, quantity }], discount, identity?.companyCountry)}
                />
              ) : (
                "0.00"
              )}{" "}
              HT {product?.tarifs[value]?.periodLabel}
            </b>
          </Typography>

          <Button
            sx={{ mt: 2, p: "15px", color: "white" }}
            variant="contained"
            onClick={() => {
              let cartUpdated = false;
              let newCart = cart.map((cartItem: any) => {
                if (cartItem.product.id === product.id && cartItem.tarifId === value) {
                  cartUpdated = true;
                  return {
                    ...cartItem,
                    quantity: cartItem.quantity + quantity,
                  };
                } else {
                  return cartItem;
                }
              });
              if (!cartUpdated) {
                newCart = cart.concat([
                  {
                    product: product,
                    tarifId: value,
                    quantity: quantity,
                  },
                ]);
              }
              setCart(newCart);
              navigate("/checkout");
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <AddIcon />
              Ajouter au panier
            </div>
          </Button>
        </Stack>
      </Grid>
    </Container>
  );
};

export default ProductPage;
