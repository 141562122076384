import { Grid, Typography } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import {
  Create,
  TextInput,
  useNotify,
  useRedirect,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  useDataProvider,
  useGetIdentity,
  FormDataConsumer,
  SelectInput,
  required,
  useUpdate,
  SaveButton,
  Toolbar,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import { DELAYS } from "../../services/common-utils/constants";
import SmsTextInput from "../../components/inputs/SmsInput";
import { count } from "sms-length";
import { HistoryContext } from "../../services/historyProvider";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import { useFormContext, useWatch } from "react-hook-form";
import PageTitle from "../../components/navigation/PageTitle";

const NameInput = ({ ...props }: any) => {
  const { setValue } = useFormContext();
  const name = useWatch({ name: "name" });
  const [copySetted, setCopySetted] = useState(false);

  useEffect(() => {
    // quite strange without timeout value is not properly set
    setTimeout(() => {
      if (name && window.location.href.includes("source") && !copySetted) {
        setValue("name", name + " (copie)");
        setCopySetted(true);
      }
    }, 0);
  }, [copySetted, name, setValue]);

  return <TextInput {...props} />;
};

const validateTemplateCreate = (values: any) => {
  let errors = {} as any;
  ["confirmation_message", "reminder_message", "alert_message"].forEach((input: string) => {
    if (values[input]) {
      const smsInfos = count(values[input]);
      if (smsInfos.messages > 9) errors[input] = "Maximum 9 SMS";
    }
  });
  if (values.customExpeditor) {
    if (values.expeditor.length > 11) {
      errors["expeditor"] = "Maximum 11 caractères";
    }
    if (values.expeditor.indexOf(" ") !== -1) {
      errors["expeditor"] = "Espaces interdits";
    }
  }
  return errors;
};

const MyToolbar = () => (
  <Toolbar>
    <SaveButton alwaysEnable />
  </Toolbar>
);

const TemplatesCreate = ({ ...props }) => {
  const notify = useNotify();
  const redirect = useCustomListRedirect();
  const { isLoading, data: identity } = useGetIdentity();
  const history: string[] = useContext(HistoryContext);
  const [fromCompany, setFromCompany] = useState("");

  // Transform override
  const onTransform = async (data: any) => {
    delete data.customExpeditor;
    if (["Client", "Indirect"].includes(identity?.status)) {
      data.group_id = identity?.company;
    }
    return data;
  };

  // Success override
  const onSuccess = async (data: any) => {
    notify(`Modèle créé avec succès`, { type: "success" });
    redirect("templates");
  };

  useEffect(() => {
    //route from routerContext can be not already updated
    if (history.length && !fromCompany && history[0] === window.location.pathname) {
      const match = history[1].match(/(?:companies\/)(.*)(?:\/)/);
      setFromCompany(match ? match[1] : "none");
    }
  }, [history, fromCompany]);

  return isLoading || !fromCompany ? (
    <></>
  ) : (
    <Create {...props} transform={onTransform} mutationOptions={{ onSuccess }}>
      <SimpleForm
        defaultValues={() => (fromCompany !== "none" ? { group_id: fromCompany } : {})}
        validate={validateTemplateCreate}
        toolbar={<MyToolbar />}
      >
        <PageTitle text="Création d'un nouveau modèle" divider />

        <Grid container direction={"column"}>
          {["Collaborateur", "Revendeur"].includes(identity?.status) && (
            <ReferenceInput
              required
              source="group_id"
              reference="companies"
              filter={identity?.status === "Revendeur" ? { parentCompany: identity?.company } : {}}
              sort={{ field: "company", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput
                label="Organisation"
                optionText="company"
                filterToQuery={(search: any) => ({ company: search })}
              />
            </ReferenceInput>
          )}
          <NameInput required source="name" label="Nom du modèle" />
          <BooleanInput defaultValue={true} source="group_access" label="Visible par tout les utilisateurs" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.group_access === false && (
                <ReferenceInput
                  required
                  source="crmGroupId"
                  reference="groups"
                  filter={{
                    company: ["Collaborateur", "Revendeur"].includes(identity?.status)
                      ? formData.group_id
                      : identity?.company,
                  }}
                  sort={{ field: "name", order: "ASC" }}
                  perPage={1000}
                >
                  <AutocompleteInput
                    label="Groupe d'accès au modèle"
                    optionText="name"
                    filterToQuery={(search: any) => ({ name: search })}
                    helperText="Seuls les utilisateurs du groupe pourront accéder au modèle"
                  />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
          <div style={{ borderBottom: "1px solid lightgray", width: "100%", marginBottom: "28px", marginTop: "0px" }} />
          <BooleanInput defaultValue={false} source="customExpeditor" label="Personnaliser l'émetteur" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.customExpeditor && (
                <TextInput
                  required
                  source="expeditor"
                  label="Émetteur"
                  {...rest}
                  helperText="11 caractères au maximum"
                />
              )
            }
          </FormDataConsumer>

          <SmsTextInput source="confirmation_message" defaultValue={null} label="Message de confirmation" />

          <SmsTextInput source="reminder_message" label="Message de rappel" />
          <SelectInput
            defaultValue={1440}
            validate={required()}
            source="reminder_delay"
            label="Délai avant rappel"
            choices={DELAYS}
          />

          <BooleanInput source="showAlert" label="Envoyer un message d'alerte" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.showAlert && <SmsTextInput source="alert_message" label="Message d'alerte" />
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.showAlert && (
                <SelectInput
                  defaultValue={1440}
                  validate={required()}
                  source="alert_delay"
                  label="Délai avant alerte"
                  choices={DELAYS}
                />
              )
            }
          </FormDataConsumer>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default TemplatesCreate;
