import { Grid, Typography, InputAdornment } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  Edit,
  TextInput,
  required,
  NumberInput,
  SaveButton,
  Toolbar,
  DeleteButton,
  SelectInput,
  BooleanInput,
  SelectArrayInput,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  DateInput,
  Labeled,
  TextField,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import {
  DISCOUNT_APPLICATION_TYPES,
  DISCOUNT_TYPES,
  DISCOUNT_SCOPES,
  TYPES,
  COMPANIES_STATUSES,
} from "../../services/common-utils/constants";
import { useFormContext } from "react-hook-form";
import PageTitle from "../../components/navigation/PageTitle";

const ScopeInput = ({ type, ...props }: any) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (type === "Produit offert") {
      setValue("scope", "Produits spécifiques");
    }
  }, [type, setValue]);

  return type === "Produit offert" ? (
    <Labeled label="Champ d'application">
      <TextField source="scope" mb={"20px"} />
    </Labeled>
  ) : (
    <SelectInput
      validate={required()}
      defaultValue="Panier"
      source="scope"
      label="Champ d'application"
      choices={DISCOUNT_SCOPES.map((s) => ({ id: s, name: s }))}
      {...props}
    />
  );
};

const DiscountsToolBar = ({ ...props }) => (
  <Toolbar>
    <SaveButton label="Sauvegarder" />
    <div style={{ flex: 1 }} />
    <DeleteButton label="Supprimer" />
  </Toolbar>
);

const DiscountEdit = ({ ...props }) => {
  const [type, setType] = useState({} as any);

  const onTransform = (data: any) => {
    return data;
  };

  const onTypeChange = (e: any) => {
    setType(e.target.value);
  };

  return (
    <Edit {...props} transform={onTransform} hasShow={false}>
      <PageTitle text={(record: any) => record.name}/>
      <SimpleForm toolbar={<DiscountsToolBar />}>
        <Typography variant="h6" mb={"20px"}>
          Informations générales
        </Typography>
        <Grid container direction={"column"}>
          <TextInput required defaultValue={null} source="name" label="Nom de la remise" />
          <DateInput required defaultValue={null} source="startDate" label="Date de début" />
          <BooleanInput defaultValue={false} source="expire" label="Expiration" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.expire && <DateInput required defaultValue={null} source="endDate" label="Date de fin" />
            }
          </FormDataConsumer>

          <SelectInput
            validate={required()}
            defaultValue="Automatique"
            source="applicationType"
            label="Type de remise"
            choices={DISCOUNT_APPLICATION_TYPES.map((s) => ({ id: s, name: s }))}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.applicationType === "Code promo" && (
                <TextInput required defaultValue={null} source="promoCode" label="Code promo" />
              )
            }
          </FormDataConsumer>
          <BooleanInput defaultValue={false} source="onlyOnceByCompany" label="Utilisable une seule fois par client" />
          <SelectInput
            validate={required()}
            defaultValue="Pourcentage"
            source="type"
            label="Type de réduction"
            choices={DISCOUNT_TYPES.map((s) => ({ id: s, name: s }))}
            onChange={onTypeChange}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.type === "Pourcentage" && (
                <NumberInput
                  defaultValue={0}
                  required
                  source="rate"
                  label="Pourcentage"
                  step={1}
                  {...rest}
                  InputProps={{ endAdornment: <InputAdornment position="start">%</InputAdornment> }}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.type === "Montant" && (
                <NumberInput
                  defaultValue={0}
                  required
                  source="amount"
                  label="Montant"
                  step={0.01}
                  {...rest}
                  InputProps={{ endAdornment: <InputAdornment position="start">€ HT</InputAdornment> }}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.type === "Produit offert" && (
                <NumberInput
                  defaultValue={1}
                  required
                  source="freeProductQuantity"
                  label="Quantité offerte"
                  step={1}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <ScopeInput type={type} />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.scope === "Produits spécifiques" && (
                <ReferenceArrayInput source="products" reference="products">
                  <AutocompleteArrayInput label="Produits concernées" optionText="name" />
                </ReferenceArrayInput>
              )
            }
          </FormDataConsumer>
          <BooleanInput defaultValue={false} source="scopedByCompanyType" label="Restreindre selon nature du client" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.scopedByCompanyType && (
                <SelectArrayInput
                  validate={required()}
                  defaultValue={null}
                  source="companyTypes"
                  label="Nature du client"
                  choices={TYPES.map((s) => ({ id: s, name: s }))}
                />
              )
            }
          </FormDataConsumer>
          <BooleanInput
            defaultValue={false}
            source="scopedByCompanyStatus"
            label="Restreindre selon statut du client"
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.scopedByCompanyStatus && (
                <SelectArrayInput
                  validate={required()}
                  defaultValue={null}
                  source="companyStatus"
                  label="Type"
                  choices={COMPANIES_STATUSES.map((s) => ({ id: s, name: s }))}
                />
              )
            }
          </FormDataConsumer>
          <BooleanInput
            defaultValue={false}
            source="scopedByCompanyIds"
            label="Restreindre à un ou des clients spécifiques"
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.scopedByCompanyIds && (
                <ReferenceArrayInput source="companyIds" reference="companies">
                  <AutocompleteArrayInput label="Clients éligibles" optionText="company" filterToQuery={(search: any) => ({ company: search })}/>
                </ReferenceArrayInput>
              )
            }
          </FormDataConsumer>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default DiscountEdit;
