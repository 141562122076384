import { Datagrid } from '@react-admin/ra-rbac';
import React, { useEffect } from 'react';
import { useListContext, useUnselectAll } from 'react-admin';
export const DigisoftDatagrid = ({ ...props }) => {
  const { resource } = useListContext();
  const unselectAll = useUnselectAll(resource);

  useEffect(() => {
    unselectAll()
  }, [unselectAll])
  
  return (
    <Datagrid
      {...props}
      sx={{
        '& .RaDatagrid-row': { height: '56px', borderLeft: '3px solid #58A14E' },
      }}
    />
)};
