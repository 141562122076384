import React from "react";
import "./App.css";
import { Admin, CustomRoutes, combineDataProviders } from "react-admin";
import { BrowserRouter, Route } from "react-router-dom";
import { Resource } from "@react-admin/ra-rbac";
import { QueryClient, QueryClientProvider } from "react-query";
import HistoryProvider from "./services/historyProvider";

import GroupIcon from "@mui/icons-material/Group";
import Layout from "./components/layout/Layout";
import { fbDataProvider, fbAuthProvider } from "./services/firebase/firebase.providers";
import apiSmsMeetingProvider from "./services/firebase/api.dataprovider";
import LoginPage from "./pages/login/LoginPage";
import SignUpPage from "./pages/sign-up/SignUpPage";
import ForgottenPasswordPage from "./pages/forgotten-password/ForgottenPasswordPage";
import { Theme } from "./components/Theme";

// Pages
import CollaboratorsPages from "./pages/collaborators";
import CompaniesPages from "./pages/companies";
import UsersPages from "./pages/users";
import OrdersPages from "./pages/orders";
import ProductsPages from "./pages/products";
import DiscountsPages from "./pages/discounts";
import AttributesPages from "./pages/attributes";
import SubscriptionsPages from "./pages/subscriptions";
import LicensesPages from "./pages/licenses";
import TemplatesPages from "./pages/templates";
import ContactsPages from "./pages/contacts";
import ContactListsPages from "./pages/contactLists";
import ContactsOrContactListsPage from "./pages/contactsOrContactLists/ContactsOrContactLists";
import SmsPages from "./pages/sms";
import ProvidersPages from "./pages/providers";
import PaymentMethodsPages from "./pages/paymentMethods";
import GroupsPages from "./pages/groups";
import OpportunitiesPages from "./pages/opportunities";
import DownloadsPages from "./pages/downloads";
import { AppLocationContext } from "@react-admin/ra-navigation";
import ShopPage from "./pages/shop/ShopPage";
import ProductPage from "./pages/shop/product/ProductPage";
import CheckoutPage from "./pages/checkout/CheckoutPage";
import CreditsPage from "./pages/credits/CreditsPage";
import DownloadPage from "./pages/download/DownloadPage";
import DigisoftEdit from "./pages/admin/DigisoftEdit";
import polyglotI18nProvider from "ra-i18n-polyglot";
import fr from "ra-language-french";
import { fr as csvFr } from "react-admin-import-csv/lib/i18n";
import { raRelationshipsLanguageFrench } from "@react-admin/ra-relationships";
import DashboardPage from "./pages/dashboard/DashboardPage";

// for client side caching
const queryClient = new QueryClient({
  // defaultOptions: {
  //     queries: {
  //         staleTime: 10 * 60 * 1000,
  //     },
  // },
});

fr.ra.page.dashboard = "Accueil";
csvFr.csv.buttonMain.tooltip = "Si excel faire 'enregistrer sous' et sélectionner comme type de fichier 'CSV UTF8'";
export const i18nProvider = polyglotI18nProvider(
  (locale) => ({ ...fr, ...csvFr, ...raRelationshipsLanguageFrench }),
  "fr", // default locale
  [{ locale: "fr", name: "Français", allowMissing: true }]
);

const dataProvider = combineDataProviders((resource: string) => {
  if (
    ["licenses", "templates", "contacts", "contactLists", "transfers", "sms", "providers", "paymentMethods"].includes(
      resource
    )
  ) {
    return apiSmsMeetingProvider;
  } else {
    return fbDataProvider;
  }
});

const lightTheme = Theme("light");
// const darkTheme = Theme("dark");

function App() {
  return (
    <BrowserRouter>
      <HistoryProvider>
        <QueryClientProvider client={queryClient}>
          <AppLocationContext>
            <Admin
              i18nProvider={i18nProvider}
              dataProvider={dataProvider}
              authProvider={fbAuthProvider}
              queryClient={queryClient}
              loginPage={LoginPage}
              layout={Layout}
              theme={lightTheme}
              // darkTheme={darkTheme}
              dashboard={DashboardPage}
              requireAuth
            >
              <Resource name="admin" icon={GroupIcon} edit={DigisoftEdit} />
              <Resource name="collaborators" icon={GroupIcon} {...CollaboratorsPages} />
              <Resource name="companies" icon={GroupIcon} {...CompaniesPages} />
              <Resource name="users" icon={GroupIcon} {...UsersPages} />
              <Resource name="orders" icon={GroupIcon} {...OrdersPages} />
              <Resource name="products" icon={GroupIcon} {...ProductsPages} />
              <Resource name="discounts" icon={GroupIcon} {...DiscountsPages} />
              <Resource name="attributes" icon={GroupIcon} {...AttributesPages} />
              <Resource name="subscriptions" icon={GroupIcon} {...SubscriptionsPages} />
              <Resource name="licenses" icon={GroupIcon} {...LicensesPages} />
              <Resource name="templates" icon={GroupIcon} {...TemplatesPages} />
              <Resource name="contacts" icon={GroupIcon} {...ContactsPages} />
              <Resource name="contactLists" icon={GroupIcon} {...ContactListsPages} />
              <Resource name="sms" icon={GroupIcon} {...SmsPages} />
              <Resource name="providers" icon={GroupIcon} {...ProvidersPages} />
              <Resource name="paymentMethods" icon={GroupIcon} {...PaymentMethodsPages} />
              <Resource name="groups" icon={GroupIcon} {...GroupsPages} />
              <Resource name="downloads" icon={GroupIcon} {...DownloadsPages} />
              <Resource name="opportunities" icon={GroupIcon} {...OpportunitiesPages} />

              {/* Custom routes */}
              <CustomRoutes noLayout>
                <Route path="/sign-up" element={<SignUpPage />} />
                <Route path="/forgotten-password" element={<ForgottenPasswordPage />} />
              </CustomRoutes>
              <CustomRoutes>
                <Route path="/shop" element={<ShopPage />} />
                <Route path="/download" element={<DownloadPage />} />
                <Route path="/credits" element={<CreditsPage />} />
                <Route path="/contactsOrContactLists" element={<ContactsOrContactListsPage />} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path="/shop/*" element={<ProductPage />} />
              </CustomRoutes>
            </Admin>
          </AppLocationContext>
        </QueryClientProvider>
      </HistoryProvider>
    </BrowserRouter>
  );
}

export default App;
