import { stringify } from "query-string";
import { sort } from "fast-sort";
import api from "../apiWithAuth";
import { collection, where, getDocs, query, getDoc, doc } from "firebase/firestore";
import { fbFirestore } from "./firebase.services";

const normalizeString = (str: string): string =>
  str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

const DataProvider = {
  getList: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const path = window.location.pathname;
    if (path.includes("companies")) {
      const match = path.match(/(?:companies\/)(.*)(?:\/)/);
      const company = match ? match[1] : null;
      params.filter.group_id = params.filter.group_id || company;
    }
    let filters = params.filter;
    let groupFilter = filters.group_id || "";
    let crmGroupFilter = filters.crmGroupId || "";
    let fullNameFilter = filters.fullName || "";
    // do not result anything for sms if period is custom and start and end are not specified
    if (resource === "sms" && filters?.viewType !== "historic") {
      return Promise.resolve({
        data: [],
        total: 0,
      });
    }
    const getParams = {
      ...(groupFilter && { group_id: groupFilter }),
      ...(crmGroupFilter && { crmGroupId: crmGroupFilter }),
      ...(filters?.period && { period: filters.period }),
      ...(filters?.period && {
        timeUnit:
          filters?.period === "custom"
            ? (new Date(filters?.endDate).getTime() - new Date(filters?.startDate).getTime()) / 86400000 > 31 // if more than 1 month between dates
              ? "month"
              : "day"
            : filters.period.includes("Year")
            ? "month"
            : "day",
      }),
      ...(filters?.period === "custom" && { startDate: filters?.startDate, endDate: filters?.endDate }),
      ...(filters?.viewType === "statistics" && filters?.countType && { countType: filters?.countType }),
      ...(filters?.filterType === "user" && filters?.userId && { userId: filters?.userId }),
      ...(filters?.filterType === "crmGroup" &&
        filters?.crmGroupId &&
        filters?.filterType !== "allCrmGroups" && { crmGroupId: filters?.crmGroupId }),
    };
    delete filters.group_id;
    delete filters.crmGroupId;
    delete filters.paymentMethodId;
    delete filters.period;
    delete filters.startDate;
    delete filters.endDate;
    delete filters.timeUnit;
    delete filters.filterType;
    delete filters.viewType;
    delete filters.countType;
    delete filters.fullName;
    //already filtered on backend
    if (resource === "sms") {
      delete filters.userId;
    }
    const range = [(page - 1) * perPage, page * perPage];
    return api.get(`${resource}`, { params: getParams }).then(async ({ data, headers }) => {
      if (data.length === 0 || !Array.isArray(data)) return { data: [], total: 0 };
      if (filters) {
        for (const filteredField in filters) {
          if (resource === "transfers" && filteredField === "tabFilter") {
            data = data.filter((item: any) => {
              return filters[filteredField] === item.type;
            });
          } else if (resource === "sms" && filteredField === "tabFilter") {
            data = data.filter((item: any) => {
              return filters[filteredField] === "sent" ? item.sent : !item.sent;
            });
          } else if (resource === "templates" && filteredField === "group_access") {
            data = data.filter((item: any) => {
              return filters[filteredField] === item.group_access;
            });
          } else if (resource === "licenses" && filteredField === "connected") {
            data = data.filter((item: any) => {
              return filters[filteredField] ? item.connections.length > 0 : item.connections.length === 0;
            });
          } else if (resource === "licenses" && filteredField === "assigned") {
            data = data.filter((item: any) => {
              return filters[filteredField] ? !!item.email : !item.email;
            });
          } else {
            data = data.filter((item: any) => {
              return item[filteredField]?.match(new RegExp(filters[filteredField], "gi"));
            });
          }
        }
      }
      const length: number = data.length;
      if (resource === "licenses") {
        data = data.map((raw: any) => {
          //maybe do it for all resource and before to be able to filter on some fields inside crmMetaData field
          const metaData: any = JSON.parse(raw?.crmMetaData || "{}");
          const lastConnection: any = raw?.connections
            ? sort(raw?.connections).desc((connection: any) => connection.createdAt)[0]
            : null;
          return { ...metaData, ...raw, id: raw.uuid, lastConnectionDate: lastConnection?.createdAt || null };
        });
      }
      if (resource === "contacts") {
        if (fullNameFilter) {
          data = data.filter((contact: any) =>
            `${normalizeString(contact.firstname)} ${normalizeString(contact.lastname)}`.includes(
              normalizeString(fullNameFilter)
            )
          );
        }
      }
      if (["ASC", "DESC"].includes(order)) {
        data =
          order === "ASC" ? sort(data).asc((item: any) => item[field]) : sort(data).desc((item: any) => item[field]);
      }
      // we need all SMS to be able to make statistics from data
      // if (resource !== "sms") {
      data = data.filter((raw: any, index: number) => index >= range[0] && index < range[1]);
      // }
      if (resource === "templates") {
        for (let template of data) {
          const q = query(collection(fbFirestore, "groups"), where("templates", "array-contains", template.id));
          const querySnapshot = await getDocs(q);
          template.crmGroupId = querySnapshot.empty ? "" : querySnapshot.docs[0].data().id;
        }
        //maybe problem here for calculating length because filter applied after length is computed
        if (crmGroupFilter) {
          data = data.filter((template: any) => {
            return crmGroupFilter === template.crmGroupId;
          });
        }
      }
      if (resource === "contacts") {
        for (let contact of data) {
          const q = query(collection(fbFirestore, "groups"), where("contacts", "array-contains", contact.id));
          const querySnapshot = await getDocs(q);
          contact.crmGroupId = querySnapshot.empty ? "" : querySnapshot.docs[0].data().id;
        }
      }
      if (resource === "contactLists") {
        for (let contactList of data) {
          const q = query(collection(fbFirestore, "groups"), where("contactLists", "array-contains", contactList.id));
          const querySnapshot = await getDocs(q);
          contactList.crmGroupId = querySnapshot.empty ? "" : querySnapshot.docs[0].data().id;
        }
      }
      if (resource === "sms") {
        for (let sms of data) {
          let q = query(collection(fbFirestore, "users"), where("licenseId", "==", sms.license_uuid));
          let querySnapshot = await getDocs(q);
          sms.userId = querySnapshot.empty ? "" : querySnapshot.docs[0].id;
          sms.group_id = querySnapshot.empty ? "" : querySnapshot.docs[0].data().company;
          q = query(collection(fbFirestore, "groups"), where("users", "array-contains", sms.userId));
          querySnapshot = await getDocs(q);
          sms.crmGroupId = querySnapshot.empty ? "" : querySnapshot.docs[0].data().id;
        }
        // if (crmGroupFilter) {
        //   data = data.filter((sms: any) => {
        //     return crmGroupFilter === sms.crmGroupId;
        //   });
        // }
      }
      return {
        data,
        total: length,
      };
    });
  },

  getOne: (resource: string, params: any) => {
    return api.get(`${resource}/${params.id}`).then(async ({ data }) => {
      if (resource === "licenses") {
        const metaData: any = JSON.parse(data?.crmMetaData || "{}");
        data = {
          ...metaData,
          ...data,
        };
        if (data.email) {
          const q = query(collection(fbFirestore, "users"), where("email", "==", data.email));
          const querySnapshot = await getDocs(q);
          data.userId = querySnapshot.docs[0].id;
        }
        data.id = data.uuid;
      }
      if (resource === "templates") {
        const q = query(collection(fbFirestore, "groups"), where("templates", "array-contains", data.id));
        const querySnapshot = await getDocs(q);
        data.crmGroupId = querySnapshot.empty ? "" : querySnapshot.docs[0].data().id;
      }
      // if (resource === "contacts") {
      //   const q = query(collection(fbFirestore, "groups"), where("contacts", "array-contains", data.id));
      //   const querySnapshot = await getDocs(q);
      //   data.crmGroupId = querySnapshot.empty ? "" : querySnapshot.docs[0].data().id;
      // }
      if (resource === "sms") {
        const q = query(collection(fbFirestore, "users"), where("licenseId", "==", data.license_uuid));
        const querySnapshot = await getDocs(q);
        data.userId = querySnapshot.empty ? "" : querySnapshot.docs[0].id;
        data.group_id = querySnapshot.empty ? "" : querySnapshot.docs[0].data().company;
      }
      return {
        data,
      };
    });
  },
  //! à implémenter
  getMany: (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${resource}?${stringify(query)}`;
    return api.get(url).then(({ data }) => ({ data }));
  },

  getManyReference: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${resource}?${stringify(query)}`;

    return api.get(url).then(({ data }) => ({
      data,
      //total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  create: (resource: string, params: any) => {
    return api.post(`${resource}`, params.data).then(({ data }) => {
      return {
        data: { ...params.data, id: data.id },
      };
    });
  },

  update: async (resource: string, params: any) => {
    if (resource === "licenses") {
      if (params.previousData.userId) {
        const oldUser = await getDoc(doc(fbFirestore, "users", params.previousData.userId as string));
        params.data.oldEmail = oldUser?.data()?.email || "";
      } else {
        params.data.oldEmail = null;
      }
      if (params.data.userId) {
        const user = await getDoc(doc(fbFirestore, "users", params.data.userId as string));
        params.data.email = user?.data()?.email || "";
      } else {
        params.data.email = null;
      }
    }
    return api.patch(`${resource}/${params.id}`, params.data).then(async ({ data }) => {
      if (resource === "licenses") {
        data.id = data.uuid;
      }
      return { data };
    });
  },
  //! for the while available only for licenses
  updateMany: (resource: string, params: any) => {
    return api.patch(`${resource}/${params.ids}`, params.data).then(({ data }) => {
      if (resource === "licenses") {
        data = data.map((raw: any) => ({ ...raw, id: raw.uuid }));
      }
      return { data };
    });
  },

  delete: (resource: string, params: any) => {
    return api.delete(`${resource}/${params.id}`).then(({ data }) => {
      if (resource === "paymentMethods") {
        let customerPaymentMethods = JSON.parse(localStorage.getItem("customerPaymentMethods") || "[]");
        if (customerPaymentMethods.length) {
          customerPaymentMethods = customerPaymentMethods.filter(
            (paymentMethod: any) => paymentMethod.id !== params.id
          );
          localStorage.setItem("customerPaymentMethods", JSON.stringify(customerPaymentMethods));
        }
      }
      return { data };
    });
  },

  deleteMany: (resource: string, params: any) => {
    return api.delete(`${resource}/${params.ids}`).then(({ data }) => ({ data }));
  },
};

export default DataProvider;
