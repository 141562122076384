import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Typography, Button } from "@mui/material";
import FormInput from "../../../components/form/FormInput";
import { useGetIdentity, useGetOne, useUpdate } from "react-admin";

const CheckoutForm = ({ handleStep, handleData, customerInfos, checkoutStep, setCustomerInfos }: any) => {
  const [update] = useUpdate();
  const { data: identity } = useGetIdentity();
  const { data: company } = useGetOne("companies", { id: identity?.company });

  const methods = useForm({
    defaultValues: {
      ...customerInfos,
      //to keep it up to date if refresh or navigating outside checkout and come back
      billingAddress: company?.address?.address || "",
      billingAdditionnalAddress: company?.address?.additionnalAddress || "",
      billingZipCode: company?.address?.zipCode || "",
      billingCity: company?.address?.city || "",
    },
  });

  const onSubmit = async (data: any) => {
    // if (
    //   data.address !== company?.address?.address ||
    //   data.additionalAddress !== company?.address?.additionalAddress ||
    //   data.zipCode !== company?.address?.zipCode ||
    //   data.city !== company?.address?.city
    // ) {
      try {

        await update("companies", {
          id: identity?.company,
          data: {
            address: {
              address: data.billingAddress,
              additionalAddress: data.billingAdditionalAddress,
              zipCode: data.billingZipCode,
              city: data.billingCity,
            },
          },
          previousData: company,
        });
      } catch (error) {
        console.log("error saving company billing Address", error);
      }
    // }
    // setCustomerInfos({...data, billingCountry: customerInfos.billingCountry});
    setCustomerInfos(data);
    handleStep(1);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      sx={{
        "& .MuiInputBase-root": { width: "100%", padding: "5px" },
        "& .MuiInputLabel-root": { fontSize: "1.2rem" },
        "& .MuiFormControl-root": { margin: 0.5 },
      }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        Veuillez vérifier les informations liées à votre commande.
      </Typography>
      <FormProvider {...methods}>
        <Box component="form" autoComplete="off" id="customer-infos-form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Box
            display="flex"
            sx={{ flexDirection: ["column", "row"] }}
            alignItems="center"
            justifyContent="space-around"
            mt="20px"
            width="100%"
          >
            <Box display="flex" flexDirection="column" gap={2} sx={{ width: [1, 0.45] }}>
              <Typography variant="h6" sx={{ my: 2 }}>
                Coordonnées de contact
              </Typography>
              <FormInput type="input" name="firstName" label="Prénom" required />
              <FormInput type="input" name="lastName" label="Nom" required />
              <FormInput type="input" name="email" label="Adresse email" required />
              <FormInput type="input" name="contactPhone" label="Téléphone" />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} sx={{ width: [1, 0.45] }}>
              <Typography variant="h6" sx={{ my: 2 }}>
                Adresse de facturation
              </Typography>
              <FormInput type="input" name="billingAddress" label="Adresse" required />
              <FormInput type="input" name="billingAdditionalAddress" label="Complément d'adresse" />
              <FormInput type="input" name="billingZipCode" label="Code postal" required />
              <FormInput type="input" name="billingCity" label="Ville" required />
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default CheckoutForm;
