import { Container, Grid, Box, Typography, Stack, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { Button } from "react-admin";
import Delete from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ item, widths, totals }: any) => {
  const [cart, setCart] = useLocalStorage("cart", [] as any[]);
  const navigate = useNavigate();
  const [discountPrice, setDiscountPrice] = useState<any>(null);

  const changeItemQuantity = (quantityToAdd: any) => {
    const newCart = cart.map((cartItem: any) => {
      if (cartItem.product.id === item.product.id && cartItem.tarifId === item.tarifId) {
        return {
          ...cartItem,
          quantity: cartItem.quantity + quantityToAdd,
        };
      } else {
        return cartItem;
      }
    });
    setCart(newCart);
  };

  useEffect(() => {
    if (
      totals.totalByProduct &&
      totals.totalByProduct[item.product.id].totalHt < totals.withoutDiscount.totalByProduct[item.product.id].totalHt
    ) {
      setDiscountPrice(totals.totalByProduct[item.product.id].totalHt/item.quantity);
    }
  }, [item.product.id, item.quantity, totals.totalByProduct, totals.withoutDiscount.totalByProduct]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems={"center"}
      sx={{
        border: "1px solid lightgrey",
        width: "100%",
        padding: [1, 2],
        "& .MuiTypography-root": { fontSize: ["0.8rem", "inherit"] },
      }}
    >
      <Box
        display="flex"
        alignItems={"center"}
        sx={{
          width: widths[0],
          flexDirection: ["column", "row"],
          justifyContent: "space-around",
          ":hover": { cursor: "pointer" },
        }}
        height="100%"
        onClick={() => navigate("/shop/" + item.product.id)}
      >
        <Box
          component="img"
          src={item.product.pictures[0].src}
          alt={item.product.name}
          sx={{ width: [0.7, 0.4], objectFit: "cover", borderRadius: 2 }}
        />

        <Typography variant="h6" fontWeight={"bold"} sx={{ textAlign: "center" }}>
          {item.product.name}
          <Typography variant="body2" sx={{ textAlign: "center", p: 1 }}>
            {item.product.tarifs[item.tarifId].paymentModel === "Forfait"
              ? `${item.product.tarifs[item.tarifId].packageSize} ${item.product.tarifs[item.tarifId].packUnitLabel}`
              : "" + (item.product.tarifs[item.tarifId].billingPeriod || "")}
          </Typography>
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ width: widths[1] }}
      >
        <Typography
          variant="h6"
          sx={{ textAlign: "right", textDecorationLine: discountPrice ? "line-through" : "none" }}
        >
          {Number(item.product.tarifs[item.tarifId].price).toFixed(2)} &euro;
        </Typography>
        {discountPrice && (
          <Typography variant="h6" sx={{ textAlign: "right", color: "green" }}>
            {(discountPrice).toFixed(2)} &euro;
          </Typography>
        )}
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems={"center"}
        justifyContent="flex-start"
        sx={{ width: widths[2], transform: ["translateY(20%)", "unset"] }}
      >
        <Box display="flex" flexDirection="row" alignItems={"center"} justifyContent={"center"}>
          <IconButton disabled={item.quantity === 1} onClick={() => changeItemQuantity(-1)}>
            <RemoveIcon />
          </IconButton>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            {item.quantity}
          </Typography>
          <IconButton onClick={() => changeItemQuantity(1)}>
            <AddIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: ["flex", "none"] }} flexDirection="column" alignItems={"center"} justifyContent={"center"}>
          <IconButton
            onClick={() => {
              const newCart = cart.filter((i) => {
                return !(i.product.id === item.product.id && i.tarifId === item.tarifId);
              });
              setCart(newCart);
            }}
          >
            <Delete />
          </IconButton>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ width: widths[3] }}
      >
        <Typography
          variant="h6"
          sx={{ textAlign: "right", textDecorationLine: discountPrice ? "line-through" : "none" }}
        >
          {(item.product.tarifs[item.tarifId].price * item.quantity).toFixed(2)} &euro;
        </Typography>
        {discountPrice && (
          <Typography variant="h6" sx={{ textAlign: "right", color: "green" }}>
            {(discountPrice*item.quantity).toFixed(2)} &euro;
          </Typography>
        )}
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ display: ["none", "initial"], width: widths[4], textAlign: "center" }}
      >
        <IconButton
          onClick={() => {
            const newCart = cart.filter((i) => {
              return !(i.product.id === item.product.id && i.tarifId === item.tarifId);
            });
            setCart(newCart);
          }}
        >
          <Delete sx={{ transform: "scale(1.3)", ml: 1 }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ProductCard;
