import React, { Fragment, useCallback } from "react";
import {
  CreateButton,
  useGetIdentity,
  DateField,
  Filter,
  TextInput,
  ReferenceField,
  TextField,
  Toolbar,
  FunctionField,
} from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import SendSmsAction from "../contacts/SendSmsAction";

const ContactListsList = ({ ...props }) => {
  const { isLoading, data: identity } = useGetIdentity();
  return isLoading ? null : (
    <List
      sort={{ field: "name", order: "ASC" }}
      filter={{ group_id: props.company || identity?.company }}
      filters={
        <Filter {...props}>
          <TextInput label="Rechercher" source="name" alwaysOn />
        </Filter>
      }
      {...props}
      exporter={false}
      empty={false}
      actions={
        <Toolbar>
          <CreateButton label="Nouvelle liste de contacts" />
        </Toolbar>
      }
    >
      <DigisoftDatagrid>
        <TextField source="name" label="Nom de la liste de contacts" />
        {!props.group_id && identity?.status === "Collaborateur" && (
          <ReferenceField label="Organisation" source="group_id" reference="companies" link={false}>
            <TextField source="company" />
          </ReferenceField>
        )}
        <DateField source="createdAt" label="Créé le" showTime />
        <DateField source="updatedAt" label="Mis à jour le" showTime />
        {identity?.licenseId && (
          <FunctionField
            label="Envoyer SMS"
            render={(record: any) => {
              // const formattedFirstName = record.firstname[0].toUpperCase() + record.firstname.slice(1);
              // const formattedLastName = record.lastname.toUpperCase();
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SendSmsAction record={record} identity={identity} />
                </div>
              );
            }}
          />
        )}
      </DigisoftDatagrid>
    </List>
  );
};

export default ContactListsList;
