import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import {
  TextInput,
  FormTab,
  SelectInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  email,
  FormDataConsumer,
} from "react-admin";
import { Edit, TabbedForm } from "@react-admin/ra-rbac";
import { COUNTRIES } from "../../services/common-utils/constants";
import CollaboratorsList from "../collaborators/CollaboratorsList";
import ProvidersList from "../providers/ProvidersList";
import PageTitle from "../../components/navigation/PageTitle";

const DigisoftEdit = ({ ...props }) => {
  const onTransform = (data: any) => {
    return {
      ...data,
      country: data?.address?.country || null,
      accountManager: data?.accountManager || null,
    };
  };

  return (
    <Edit {...props} transform={onTransform} hasShow={false}>
      <PageTitle text="Administration" backButton={false}/>
      <TabbedForm>
        {/* Personnal infos form */}
        <FormTab label="Organisation">
          <Grid container direction={"column"}>
            <TextInput defaultValue={null} source="name" label="Nom" />
            <TextInput defaultValue={null} source="siret" label="SIRET" />
            <TextInput defaultValue={null} source="vat" label="Numéro de TVA Intracommunautaire" />
            <TextInput defaultValue={null} source="phone" label="Numéro de téléphone" />
            <TextInput defaultValue={null} source="email" label="Adresse email" />
          </Grid>
        </FormTab>

        <FormTab label="Adresse">
          <Grid container direction={"column"}>
            <TextInput defaultValue={null} source="address.address" label="Rue et numéro" />
            <TextInput defaultValue={null} source="address.additionalAddress" label="Complément d'adresse" />
            <TextInput defaultValue={null} source="address.city" label="Ville" />
            <TextInput defaultValue={null} source="address.zipCode" label="Code postal" />
            <SelectInput
              defaultValue="France"
              source="address.country"
              label="Pays"
              choices={COUNTRIES.map((s) => ({ id: s, name: s }))}
            />
          </Grid>
        </FormTab>

        <FormTab label="Collaborateurs">
          <CollaboratorsList resource="collaborators" sx={{ width: "100%" }} />
        </FormTab>

        <FormTab label="Configuration">
          <Grid container direction={"column"}>
            <Typography variant="h5">Panneau d'aide</Typography>
            <Divider />
            <TextInput defaultValue={null} source="helpMenu.title" label="Titre" />
            <TextInput defaultValue={null} source="helpMenu.subtitle" label="Sous-titre" />
            <TextInput defaultValue={null} source="helpMenu.helpCenterUrl" label="Url centre d'aide" />
            <BooleanInput defaultValue={true} source="helpMenu.chatActivated" label="Chat activé" />
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.helpMenu.chatActivated && (
                  <TextInput
                    defaultValue={null}
                    source="helpMenu.scenarioId"
                    label="Id scénario chat"
                    helperText="laisser vide pour démarrer sans scénario"
                  />
                )
              }
            </FormDataConsumer>
            <TextInput defaultValue={null} source="helpMenu.meetingUrl" label="Url prise de rendez vous" />
            <TextInput defaultValue={null} source="helpMenu.phone" label="Numéro de téléphone" />
            <TextInput defaultValue={null} source="helpMenu.disponibility" label="Phrase disponibilité" />
            <Typography variant="h5" mt={2}>
              Notifications
            </Typography>
            <ArrayInput
              required
              source="notifications.emails"
              label="Email(s) réception notifications"
              sx={{ "& .RaSimpleFormIterator-list": { border: 1, borderColor: "rgba(0,0,0,0.2)", width: 450 } }}
            >
              <SimpleFormIterator>
                <TextInput
                  sx={{ mt: "10px" }}
                  source="email"
                  label=""
                  validate={email("merci d'entrer un email valide")}
                  helperText={false}
                />
              </SimpleFormIterator>
            </ArrayInput>
            <Typography variant="h5">Téléchargements</Typography>
            <Divider />
            <TextInput defaultValue={null} source="downloadDocSMSMeetingOutlook" label="Id doc exécutable SMS Meeting version Outlook" />
            <Divider />
          </Grid>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default DigisoftEdit;
