import { COUNTRIES_WITHOUT_VAT, PRODUCTS_TAXES } from "./constants";

const calculateOrderAmount = (cart: any, discount: any, country = "France") => {
  let totalHt = 0;
  let totalTtc = 0;
  const totalByProduct = {} as any;
  const taxes: any = {
    total: 0,
  };
  const withoutDiscount = {
    totalHt: 0,
    totalTtc: 0,
    totalByProduct: {},
    taxes: {
      total: 0,
    },
  } as any;
  let savings = { totalHt: 0, totalTtc: 0 };
  PRODUCTS_TAXES.forEach((taxRate: any) => {
    taxes[taxRate] = withoutDiscount.taxes[taxRate] = 0;
  });
  cart.forEach((i: any) => {
    const tarif = i.product.tarifs[i.tarifId];
    const priceHT = tarif.price / (tarif.includeTaxes ? 1 + tarif.taxRate / 100 : 1);
    const itemPrice = priceHT * i.quantity;
    withoutDiscount.totalHt += itemPrice;
    const itemTaxes = COUNTRIES_WITHOUT_VAT.includes(country) ? 0 : (itemPrice * tarif.taxRate) / 100;
    withoutDiscount.taxes[tarif.taxRate] += itemTaxes;
    withoutDiscount.totalByProduct[i.product.id] = {
      totalHt: itemPrice,
      totalTtc: itemPrice + itemTaxes,
    };
  });
  PRODUCTS_TAXES.forEach((taxRate: any) => {
    withoutDiscount.taxes.total += withoutDiscount.taxes[taxRate];
  });
  withoutDiscount.totalTtc = withoutDiscount.totalHt + withoutDiscount.taxes.total;
  if (!discount || !Object.keys(discount).length) {
    return {
      totalHt: withoutDiscount.totalHt,
      taxes: withoutDiscount.taxes,
      totalTtc: withoutDiscount.totalTtc,
      totalByProduct: withoutDiscount.totalByProduct,
      withoutDiscount,
      savings,
    };
  }
  cart.forEach((i: any) => {
    const tarif = i.product.tarifs[i.tarifId];
    const priceHT = tarif.price / (tarif.includeTaxes ? 1 + tarif.taxRate / 100 : 1);
    let discountRate = 0;
    let discountAmount = 0;
    let quantity = i.quantity;
    if (discount.type === "Pourcentage") {
      if (discount.scope === "Panier") {
        discountRate = discount.rate / 100;
      } else {
        discountRate = discount.products.includes(i.product.id) ? discount.rate / 100 : 0;
      }
    }
    if (discount.type === "Montant") {
      if (discount.scope === "Panier") {
        // to apply amount type discount on HT cart we have to consider it as a classic reduction for
        // beeing able to distribute the total amount on all products at the prorata of their price.
        // This allow us to calculate the taxes in the good way.
        discountRate = discount.amount / withoutDiscount.totalHt;
      } else {
        discountAmount = discount.products.includes(i.product.id) ? discount.amount : 0;
      }
    }
    if (discount.type === "Produit offert" && discount.products.includes(i.product.id)) {
      quantity = Math.max(0, quantity - discount.freeProductQuantity);
    }
    // max if discount amount greater than product price
    const itemPrice = Math.max(0, priceHT * quantity * (1 - discountRate) - discountAmount);
    totalHt += itemPrice;
    const itemTaxes = COUNTRIES_WITHOUT_VAT.includes(country) ? 0 : (itemPrice * tarif.taxRate) / 100;
    taxes[tarif.taxRate] += itemTaxes;
    totalByProduct[i.product.id] = {
      totalHt: itemPrice,
      totalTtc: itemPrice + itemTaxes,
    };
  });
  PRODUCTS_TAXES.forEach((taxRate: any) => {
    taxes.total += taxes[taxRate];
  });
  totalTtc = totalHt + taxes.total;
  savings = {
    totalHt: withoutDiscount.totalHt - totalHt,
    totalTtc: withoutDiscount.totalTtc - totalTtc,
  };
  return { totalHt, taxes, totalTtc, totalByProduct, withoutDiscount, savings };
};

export default calculateOrderAmount;
