import { Grid, Typography } from "@mui/material";
import React from "react";
import { Edit, TextInput, ImageField, ImageInput, FileInput, FileField, required } from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import PageTitle from "../../components/navigation/PageTitle";

const DownloadsEdit = ({ ...props }) => {
  const onTransform = (data: any) => {
    return data;
  };

  return (
    <Edit {...props} transform={onTransform}>
      <SimpleForm>
        <PageTitle text={(record: any) => record.name} />

        <Grid container direction={"column"}>
          <TextInput required source="name" label="Nom du logiciel" />
          <TextInput required source="description" label="Description" multiline />
          <TextInput required source="version" label="Version" />
          <ImageInput
            validate={required()}
            source="pictures"
            label="Image (max 200ko)"
            maxSize={200000}
            accept="image/*"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <FileInput isRequired source="software" label="Exécutable du logiciel">
            <FileField source="src" title="title" />
          </FileInput>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default DownloadsEdit;
