import { Grid, Typography, Button } from "@mui/material";
import React, { useState, useCallback, useContext, useEffect } from "react";
import {
  TextInput,
  required,
  Labeled,
  SaveButton,
  Toolbar,
  FormDataConsumer,
  TabbedForm,
  SelectInput,
  BooleanInput,
  ReferenceInput,
  useGetIdentity,
  AutocompleteInput,
  useUpdate,
  useNotify,
  useRedirect,
  useRecordContext,
  PasswordInput,
  useDelete,
  Confirm,
  TextField,
  Button as RaButton,
  ReferenceField,
} from "react-admin";
import { Edit, IfCanAccess, FormTab } from "@react-admin/ra-rbac";
import { TITLES, USERS_ROLES } from "../../services/common-utils/constants";
import { getAuth, updatePassword } from "firebase/auth";
import { useFormContext, useWatch } from "react-hook-form";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import api from "../../services/apiWithAuth";
import PageTitle from "../../components/navigation/PageTitle";

const UsersToolBar = ({ ...props }) => {
  const record = useRecordContext();
  return (
    <Toolbar>
      <SaveButton label="Sauvegarder" />
      <div style={{ flex: 1 }} />
      {props.identity?.status === "Collaborateur" ||
        (props.identity?.status !== "Collaborateur" && record.id !== props.identity.email && <CustomDelete />)}
    </Toolbar>
  );
};

const CustomDelete = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);

  const [deleteOne, { isLoading, error }] = useDelete("users", { id: record.id, previousData: record });
  if (error) {
    return <p>ERROR</p>;
  }

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    deleteOne();
    setOpen(false);
  };

  return (
    <>
      <RaButton label="Supprimer" onClick={handleClick} sx={{ color: "red" }} />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Suppression utilisateur"
        content="Etes-vous sur de vouloir supprimer votre compte utilisateur ? Toutes les données liées à celui-ci seront supprimées."
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const Form = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  const redirect = useCustomListRedirect();
  const notify = useNotify();
  const record = useRecordContext();

  const [update] = useUpdate();
  const save = useCallback(
    async (values: any) => {
      values.email = values.email.trim().toLowerCase();
      const errors = {} as any;
      try {
        const password = values.password;
        delete values.password;
        delete values.updateWithPassword;
        await update("users", { id: record.id, data: values, previousData: record }, { returnPromise: true });
        if (password) {
          if (password.length < 8) {
            errors.password = "Nombre de caractères insuffisant (8 min)";
            return errors;
          }
          try {
            await api.post("/utils/updateAuthUser", { email: values.email, password });
          } catch (err) {
            errors.password = "Erreur lors de la mise à jour du mot de passe.";
            return errors;
          }
        }
        notify("Utilisateur mis à jour avec succès", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
        redirect("users");
      } catch (error) {}
    },
    [update, notify, redirect, record]
  );

  if (!identity || !record) return null;
  return (
    <TabbedForm toolbar={<UsersToolBar identity={identity} />} onSubmit={save}>
      {/* General form */}
      <FormTab label="Géneral" name="general">
        <Typography variant="h6" mb={"20px"}>
          Informations générales
        </Typography>
        <SelectInput
          source="title"
          defaultValue={null}
          label="Civilité"
          choices={TITLES.map((s) => ({ id: s, name: s }))}
        />
        <TextInput source="firstName" defaultValue={null} label="Prénom" />
        <TextInput source="lastName" defaultValue={null} label="Nom de famille" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.id === identity?.id && ["Administrateur"].includes(identity?.role) ? (
              <Labeled label="Adresse email">
                <TextField source="email" mb={2} />
              </Labeled>
            ) : (
              <TextInput
                defaultValue={null}
                source="email"
                label="Adresse email"
                helperText="Cet e-mail est utilisée pour se connecter"
              />
            )
          }
        </FormDataConsumer>
        <ReferenceInput
          required
          source="company"
          reference="companies"
          filter={identity?.status === "Collaborateur" ? {} : { parentCompany: identity?.company }}
          sort={{ field: "company", order: "ASC" }}
          perPage={1000}
        >
          <AutocompleteInput
            label="Organisation"
            optionText="company"
            filterToQuery={(search: any) => ({ company: search })}
          />
        </ReferenceInput>
        <ReferenceField label="Groupe" source="crmGroupId" reference="groups">
          <Labeled label="Groupe">
            <TextField source="name" />
          </Labeled>
        </ReferenceField>
        <TextInput defaultValue={null} source="service" label="Service" />
        <TextInput defaultValue={null} source="position" label="Fonction" />
        <TextInput defaultValue={null} source="proMobilePhone" label="Numéro mobile PRO" />
        <TextInput defaultValue={null} source="proPhone" label="Numéro de téléphone PRO" />
        <TextInput source="note" defaultValue={null} label="Note" />
      </FormTab>

      {["Super Admin", "Administrateur"].includes(identity?.role) && (
        <FormTab label="Rôle et sécurité" name="security">
          {record.id !== identity?.id && (
            <SelectInput
              validate={required()}
              source="role"
              label="Rôle"
              choices={USERS_ROLES.map((s) => ({ id: s, name: s }))}
            />
          )}
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.role === "Administrateur" &&
              record.id !== identity?.id && (
                <BooleanInput defaultValue={true} source="authorized" label="Autoriser l'accès au CRM" />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.role === "Administrateur" && (
                <BooleanInput defaultValue={false} source="updateWithPassword" label="Modifier mot de passe" />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.role === "Administrateur" &&
              formData.updateWithPassword && (
                <PasswordInput
                  required
                  defaultValue={null}
                  source="password"
                  label="Mot de passe"
                  helperText="Minimum 8 caractères"
                />
              )
            }
          </FormDataConsumer>
        </FormTab>
      )}
    </TabbedForm>
  );
};

const UsersEdit = ({ ...props }) => {
  const onTransform = async (data: any) => {
    data.email = data.email.trim().toLowerCase();
    if (data.role !== "Administrateur") {
      data.authorized = false;
    }
    return data;
  };

  return (
    <Edit {...props} transform={onTransform} hasShow={false}>
      <PageTitle text={(record: any) => `${record.firstName} ${record.lastName}`}/>
      <Form {...props} />
    </Edit>
  );
};

export default UsersEdit;
