import React, { Fragment, useCallback } from "react";
import {
  DateField,
  Filter,
  TextField,
  TextInput,
  useGetIdentity,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  BulkDeleteButton,
  FunctionField,
  useListContext,
  useGetOne,
  SelectInput,
} from "react-admin";
import { IfCanAccess, List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import LicensesBulkRemove from "./LicensesBulkRemove";
import LicensesBulkTransfer from "./LicensesBulkTransfer";
import LicensesMerge from "./LicensesMerge";
import { Typography } from "@mui/material";
import LicensesBulkSendReminder from "./LicensesBulkSendReminder";
import PageTitle from "../../components/navigation/PageTitle";

const BulkActionButtons = ({ ...props }) => {
  return (
    <>
      {!window.location.pathname.includes("companies") && props.identity?.status === "Revendeur" && (
        <LicensesBulkTransfer identity={props.identity} />
      )}
      <LicensesMerge />
      <LicensesBulkRemove />
      <LicensesBulkSendReminder />
      <IfCanAccess action="delete" resource="licenses">
        <BulkDeleteButton />
      </IfCanAccess>
    </>
  );
};

// Having group_id prop means that component is called from companies edit.
const LicensesList = ({ ...props }) => {
  const { isLoading, identity } = useGetIdentity();
  return isLoading ? null : (
    <>
      <PageTitle text="Licences" backButton={false} />
      <List
        {...props}
        filter={
          props.group_id
            ? { group_id: props.group_id }
            : ["Client", "Indirect"].includes(identity?.status)
            ? { group_id: identity?.company }
            : {}
        }
        filters={
          <Filter {...props}>
            {!props.group_id && ["Collaborateur", "Revendeur"].includes(identity?.status) && (
              <ReferenceInput
                source="group_id"
                reference="companies"
                alwaysOn
                filter={identity?.status === "Revendeur" ? { parentCompany: identity?.company } : {}}
                sort={{ field: "company", order: "ASC" }}
                perPage={1000}
              >
                <AutocompleteInput
                  label="Organisation"
                  optionText="company"
                  filterToQuery={(search: any) => ({ company: search })}
                />
              </ReferenceInput>
            )}
            <TextInput label="Email" source="email" alwaysOn />
            <ReferenceInput
              source="crmGroupId"
              reference="groups"
              alwaysOn
              filter={
                props.group_id
                  ? { company: props.group_id }
                  : identity?.status !== "Collaborateur"
                  ? { company: identity?.company }
                  : {}
              }
              sort={{ field: "name", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput label="Groupe" optionText="name" filterToQuery={(search: any) => ({ name: search })} />
            </ReferenceInput>
            <SelectInput
              source="assigned"
              label="Etat affectation"
              alwaysOn
              choices={[
                { id: true, name: "Affectée" },
                { id: false, name: "Non affectée" },
              ]}
            />
            <SelectInput
              source="connected"
              label="Etat connexion"
              alwaysOn
              choices={[
                { id: true, name: "Connecté" },
                { id: false, name: "Déconnecté" },
              ]}
            />
          </Filter>
        }
        exporter={false}
        sort={{ field: "createdAt", order: "DESC" }}
        empty={false}
      >
        <Datagrid group_id={props.group_id} identity={identity} />
      </List>
    </>
  );
};

const LicenseAttachedTo = ({ licenseId }: any) => {
  const { data: license } = useGetOne("licenses", { id: licenseId });
  return !license ? null : <span>rattaché à {license.email}</span>;
};

export default LicensesList;
const Datagrid = ({ group_id, identity }: any) => {
  const { data: company } = useGetOne("companies", { id: group_id || identity?.company });
  return (
    <DigisoftDatagrid
      bulkActionButtons={<BulkActionButtons identity={identity} company={group_id || identity?.company} />}
      // rowStyle={(record: any, index: number) => ({
      //   backgroundColor:
      //     record?.secondaryLicenseIds?.length >= 1 ? "#52adc445" : record?.mainLicenseId ? "#e9b20045" : "white",
      // })}
      isRowSelectable={(record: any) => !record?.mainLicenseId}
    >
      <TextField label="Produit" source="license_type" />
      {(!group_id || (company && company.status === "Revendeur")) && (
        <ReferenceField label="Affecté à" source="group_id" reference="companies" link={false}>
          <TextField source="company" />
        </ReferenceField>
      )}
      <FunctionField
        label="Utilisateur"
        render={({ email, mainLicenseId }: any) => {
          return (
            <div
              style={{
                background: email ? "#58A14E" : mainLicenseId ? "#58a14e92" : "#db8f00",
                width: "fit-content",
                color: "white",
                paddingLeft: "8px",
                paddingRight: "8px",
                paddingTop: "4px",
                paddingBottom: "4px",
                borderRadius: "6px",
              }}
            >
              {email ? email : mainLicenseId ? <LicenseAttachedTo licenseId={mainLicenseId} /> : "Non affectée"}
            </div>
          );
        }}
      />
      <FunctionField
        label="Nombre de connexions"
        sx={{ width: "100%" }}
        render={({ max_connections }: any) => <Typography>{max_connections || 1}</Typography>}
      />
      <DateField label="Dernière connexion" source="lastConnectionDate" showTime />
      <FunctionField
        label="Statut"
        render={({ disabled }: any) => {
          return (
            <Typography
              sx={{
                color: "white",
                bgcolor: !disabled ? "success.light" : "error.main",
                px: 1,
                py: 0.5,
                borderRadius: 2,
                width: "fit-content",
                fontSize: "0.9rem",
                opacity: 0.8,
              }}
            >
              {!disabled ? "Active" : "Désactivée"}
            </Typography>
          );
        }}
      />
      {/* <FunctionField
        label="Dernière connexion"
        render={({ connexions }: any) => {
          return <Typography>{!connexions ? "Active" : "Désactivée"}</Typography>;
        }}
      /> */}
      {identity?.status === "Collaborateur" && <TextField label="ID License" source="id" />}
      <DateField label="Expire le" source="expiration_date" />
    </DigisoftDatagrid>
  );
};
