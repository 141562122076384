import { Grid, Typography, InputAdornment } from "@mui/material";
import React from "react";
import {
  Edit,
  TextInput,
  required,
  NumberInput,
  SaveButton,
  Toolbar,
  DeleteButton,
  SelectArrayInput,
  SelectInput,
  BooleanInput,
  ImageField,
  ImageInput,
  FormDataConsumer,
  FunctionField,
  SimpleFormIterator,
  ArrayInput,
  useEditController,
  CreateButton,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import {
  PRODUCTS_TAXES,
  PRODUCTS_TYPES,
  PRODUCTS_STATUSES,
  PRODUCTS_PAYMENT_MODELS,
  PRODUCTS_BILLING_PERIOD,
  COMPANIES_STATUSES,
  COUNTRIES,
  TYPES,
} from "../../services/common-utils/constants";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PageTitle from "../../components/navigation/PageTitle";

const ProductsToolBar = ({ ...props }) => (
  <Toolbar>
    <SaveButton label="Sauvegarder" />
    <div style={{ flex: 1 }} />
    <DeleteButton label="Supprimer" />
  </Toolbar>
);

const ProductsEdit = ({ ...props }) => {
  const onTransform = (data: any) => {
    data.tarifs.forEach((tarif: any, index: number) => {
      if (!tarif.id) {
        tarif.id = index;
      }
    });
    return data;
  };

  return (
    <Edit {...props} transform={onTransform} hasShow={false}>
      <PageTitle text={(record: any) => `${record.name}`} />
      <SimpleForm toolbar={<ProductsToolBar />}>
        <Typography variant="h6" mb={"20px"}>
          Informations générales
        </Typography>
        <Grid container direction={"column"}>
          <TextInput required defaultValue={null} source="name" label="Nom du produit" />
          <TextInput required defaultValue={null} source="description" label="Description du produit" multiline />
          <ImageInput
            isRequired
            source="pictures"
            label="Images (max 200ko)"
            maxSize={200000}
            accept="image/*"
            multiple
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput defaultValue={null} source="unitLabel" label="Libellé d'unité" />
        </Grid>

        <Typography variant="body1" mb={"20px"}>
          Catégorie
        </Typography>
        <Grid container direction={"column"}>
          {/* TODO: Categories <SelectInput required source="type" label="Nature de l'organisation" choices={TYPES.map((s) => ({ id: s, name: s}))}/>*/}
          <SelectInput
            validate={required()}
            source="type"
            label="Type de produit"
            choices={PRODUCTS_TYPES.map((s) => ({ id: s, name: s }))}
          />
          <SelectInput
            defaultValue="Invisible"
            validate={required()}
            source="status"
            label="Statut"
            choices={PRODUCTS_STATUSES.map((s) => ({ id: s, name: s }))}
          />
          <NumberInput defaultValue={100} required source="order" label="Ordre d'affichage" step={1} />
        </Grid>

        <Typography variant="h6" mb={"20px"}>
          Paiement
        </Typography>
        <ArrayInput required source="tarifs" label="Tarifs">
          <SimpleFormIterator
            sx={{
              "& .RaSimpleFormIterator-form": {
                backgroundColor: "rgba(0,0,0,0.04)",
                padding: "10px",
                marginTop: "10px",
                borderRadius: "2px",
                border: "solid 1px",
                mb: 2,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
              },
            }}
            disableClear
          >
            <TextInput defaultValue={null} source="tarifDescription" label="Description tarif pour client" />
            <SelectInput
              validate={required()}
              source="paymentModel"
              label="Modèle de tarification"
              choices={PRODUCTS_PAYMENT_MODELS.map((s) => ({ id: s, name: s }))}
            />
            <FormDataConsumer>
              {({ scopedFormData, getSource, ...rest }) => (
                <NumberInput
                  required
                  source={getSource?.("price") || ""}
                  label="Tarif"
                  step={0.01}
                  {...rest}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">€ {scopedFormData?.includeTaxes ? "TTC" : "HT"}</InputAdornment>
                    ),
                  }}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ scopedFormData, getSource, ...rest }) =>
                scopedFormData?.paymentModel === "Forfait" && (
                  <NumberInput
                    required
                    source={getSource?.("packageSize") || ""}
                    label=""
                    {...rest}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">pour</InputAdornment>,
                      endAdornment: <InputAdornment position="start">unités</InputAdornment>,
                    }}
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ scopedFormData, getSource, ...rest }) =>
                scopedFormData?.paymentModel === "Forfait" && (
                  <TextInput
                    required
                    source={getSource?.("packUnitLabel") || ""}
                    label="Libellé unité du pack"
                    {...rest}
                  />
                )
              }
            </FormDataConsumer>
            <BooleanInput defaultValue={false} source="includeTaxes" label="Inclure les taxes dans le tarif ?" />
            <SelectInput
              validate={required()}
              source="taxRate"
              label="Taxe"
              choices={PRODUCTS_TAXES.map((s) => ({ id: s, name: s + " %" }))}
            />
            <SelectArrayInput
              validate={required()}
              defaultValue={COUNTRIES}
              source="countries"
              label="Pays du client"
              helperText="Pays du client pouvant voir le produit"
              choices={COUNTRIES.map((s: any) => ({ id: s, name: s }))}
            />
            <SelectArrayInput
              validate={required()}
              defaultValue={["Client", "Revendeur"]}
              source="companyStatus"
              label="Statut des clients"
              helperText="Statut des clients pouvant voir le produit"
              choices={COMPANIES_STATUSES.map((s: any) => ({ id: s, name: s }))}
            />
            <SelectArrayInput
              validate={required()}
              defaultValue={TYPES}
              source="companyTypes"
              label="Types de clients"
              helperText="Types de clients pouvant voir le produit"
              choices={TYPES.map((s: any) => ({ id: s, name: s }))}
            />
            <ReferenceArrayInput source="companyIds" reference="companies" defaultValue={[]} validate={required()}>
              <AutocompleteArrayInput
                label="Clients"
                optionText="company"
                filterToQuery={(search: any) => ({
                  company: search,
                })}
                helperText="Clients pouvant voir le produit, si vide accessible à tout le monde"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              source="blackListedCompanyIds"
              reference="companies"
              defaultValue={[]}
              validate={required()}
            >
              <AutocompleteArrayInput
                label="Blacklist clients"
                optionText="company"
                filterToQuery={(search: any) => ({ company: search })}
                helperText="Clients ne pouvant pas voir le produit"
              />
            </ReferenceArrayInput>
            <BooleanInput defaultValue={false} source="isRecurring" label="Paiment récurrent" />
            <FormDataConsumer>
              {({ scopedFormData, getSource, ...rest }) =>
                scopedFormData?.isRecurring && (
                  <SelectInput
                    validate={required()}
                    source={getSource?.("billingPeriod") || ""}
                    label="Cycle de facturation"
                    choices={PRODUCTS_BILLING_PERIOD.map((s) => ({ id: s, name: s }))}
                    {...rest}
                  />
                )
              }
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        {/* Compta */}
        {/* <FormTab label="Comptabilité">
          <Typography variant="body1" mb={"20px"}>
            Compatbilité
          </Typography>
          <Grid container direction={"column"}>
            <SelectInput required source="sellCodes" label="Codes comptables de vente" choices={['a', 'b'].map((s) => ({ id: s, name: s}))}/>
            <SelectInput required source="buyCodes" label="Codes comptables d'achat" choices={['a', 'b'].map((s) => ({ id: s, name: s}))}/>
          </Grid>
        </FormTab> */}
      </SimpleForm>
    </Edit>
  );
};

export default ProductsEdit;
