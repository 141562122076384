import { Grid, Typography } from "@mui/material";
import React from "react";
import { Create, TextInput, ImageField, ImageInput, FileInput, FileField } from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import PageTitle from "../../components/navigation/PageTitle";

const DownloadsCreate = ({ ...props }) => {
  const onTransform = (data: any) => {
    return data;
  };

  return (
    <Create {...props} transform={onTransform}>
      <SimpleForm>
        <PageTitle text="Création d'un fichier à télécharger" divider />

        <Grid container direction={"column"}>
          <TextInput required source="name" label="Nom du logiciel" />
          <TextInput required source="description" label="Description" multiline />
          <TextInput required source="version" label="Version" />
          <ImageInput isRequired source="pictures" label="Image (max 200ko)" maxSize={200000} accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <FileInput isRequired source="software" label="Exécutable du logiciel">
            <FileField source="src" title="title" />
          </FileInput>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default DownloadsCreate;
