import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  Create,
  required,
  TextInput,
  SelectInput,
  useNotify,
  useRedirect,
  useGetIdentity,
  FormDataConsumer,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import { COMPANIES_STATUSES, COUNTRIES, TYPES } from "../../services/common-utils/constants";
import api from "../../services/apiWithAuth";
import PageTitle from "../../components/navigation/PageTitle";

const CompaniesCreate = ({ ...props }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { identity } = useGetIdentity();

  // Transform override
  const onTransform = async (data: any) => {
    let createGroupRes = await api.post("/utils/createGroup", { reference: data.siret || data.vat || data.company });
    const groupId = createGroupRes.data.groupId;
    data = {
      ...data,
      id: groupId,
      country: data?.address?.country || null,
      accountManager: identity?.company === "Collaborateur" ? identity?.email : null,
      parentCompany: identity?.status === "Revendeur" ? identity?.company : groupId,
      status: identity?.status === "Revendeur" ? "Indirect" : data.status,
      clientSince: new Date().toISOString().split("T")[0],
      freeTry: {
        available: true,
        licenseId: null,
        expirationDate: null,
        licenseConverted: false,
      },
    };
    return data;
  };

  // Success override
  const onSuccess = (data: any) => {
    notify(`Client créé avec succès`, { type: "success" });
    redirect("edit", "companies", data.id, data);
  };

  return (
    <Create {...props} transform={onTransform} mutationOptions={{ onSuccess }}>
      <SimpleForm>
        <PageTitle text="Création d'un nouveau client" divider />

        <TextInput required defaultValue={null} source="company" label="Nom de l'organisation" />
        <SelectInput
          validate={required()}
          defaultValue="Entreprise privée"
          source="type"
          label="Nature de l'organisation"
          choices={TYPES.map((s) => ({ id: s, name: s }))}
        />
        <SelectInput
          validate={required()}
          defaultValue="France"
          source="address.country"
          label="Pays"
          choices={COUNTRIES.map((s) => ({ id: s, name: s }))}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.address &&
            formData.address.country &&
            formData.address.country === "France" && (
              <TextInput defaultValue={null} required source="siret" label="SIRET" />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.address &&
            formData.address.country &&
            formData.address.country !== "France" && (
              <TextInput defaultValue={null} source="vat" label="Numéro de TVA Intracommunautaire" />
            )
          }
        </FormDataConsumer>
        <SelectInput
          validate={required()}
          source="status"
          defaultValue="Client"
          label="Statut"
          choices={COMPANIES_STATUSES.map((s) => ({ id: s, name: s }))}
        />
        <SelectInput
          defaultValue={null}
          source="prospect"
          label="Prospect"
          emptyText={"Non"}
          emptyValue={false}
          choices={[{ id: true, name: "Oui" }]}
          alwaysOn
        />
      </SimpleForm>
    </Create>
  );
};

export default CompaniesCreate;
