import React from "react";
import {
  CreateButton,
  DateField,
  Filter,
  FunctionField,
  ReferenceField,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  useGetIdentity,
  TextInput,
  BulkDeleteButton,
  useNotify,
  ExportButton,
} from "react-admin";
import { IfCanAccess, List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import { ImportButton } from "react-admin-import-csv";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { IUser } from "./IUser";
import stc from "string-to-color";
import UsersBulkGroup from "./UsersBulkGroup";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Button, Typography } from "@mui/material";
import UsersBulkAssignLicenses from "./UsersBulkAssignLicenses";
import UsersBulkRemoveLicenses from "./UsersBulkRemoveLicenses";
import api from "../../services/apiWithAuth";
import { isEmailValid } from "../../services/common-utils/functions";
import ImportButtons from "../../components/importButtons/ImportButtons";
import { CustomExportButton } from "../../components/react-admin/CustomExportButton";
import PageTitle from "../../components/navigation/PageTitle";

const headersMap: any = {
  Email: "email",
  Prénom: "firstName",
  Nom: "lastName",
  Service: "service",
  Fonction: "position",
  "Numéro mobile Pro": "proMobilePhone",
  "Numéro téléphone Pro": "proPhone",
};

const ListActions = ({ ...props }) => {
  const notify = useNotify();
  const config = {
    transformRows: (csvRows: any) => {
      // hack to change labels because resourceName cannot be customized in package
      let matches = document.querySelectorAll(".MuiPaper-root p, .MuiPaper-root h2");
      for (let i = 0; i < matches.length; i++) {
        matches[i].innerHTML = matches[i].innerHTML.replace(/"users"/g, "<b>Utilisateurs</b>");
      }
      if (
        Object.keys(csvRows[0]).some((key: string) => {
          return !key || key === "undefined";
        })
      ) {
        notify("Erreur de formatage du fichier, merci de vérifier qu'il soit bien enregistré au format CSV UTF8", {
          type: "error",
          autoHideDuration: 20000,
        });
        return null;
      }
      const transformedRows = csvRows.map((row: any, index: number) => {
        if (row.email) {
          const transformedRow = {
            email: row.email.trim().toLowerCase(),
            company: props.company,
            firstName: row.firstName || null,
            lastName: row.lastName || null,
            service: row.service || null,
            position: row.position || null,
            proMobilePhone: row.proMobilePhone || null,
            proPhone: row.proPhone || null,
            role: "Utilisateur sans accès",
            licenseId: null,
            title: null,
            authorized: false,
            importLine: index + 1,
          };
          return transformedRow;
        } else {
          return null;
        }
      });
      return transformedRows.filter((row: any) => row !== null);
    },
    validateRow: async (csvRow: any) => {
      let error = "";
      if (!isEmailValid(csvRow.email)) {
        error = csvRow.email + " n'est pas une adresse email valide";
      }
      if (!error) {
        const { data: emailExists } = await api.post("/utils/checkEmailExists", { email: csvRow.email });
        if (emailExists) {
          error = csvRow.email + " est déjà utilisée par un autre utilisateur";
        }
      }
      // if (!csvRow.firstName || !csvRow.lastName) {
      //   error = csvRow.id + " : nom ou prénom manquant"
      // }
      // if (!csvRow.proMobilePhone || !csvRow.proPhone) {
      //   error = csvRow.id + " : numéro de téléphone manquant"
      // }
      if (error) {
        error = "Erreur ligne " + csvRow.importLine + " : " + error;
        delete csvRow.importLine;
        notify(error, { type: "error", autoHideDuration: 20000 });
        return Promise.reject(new Error(error));
      } else {
        return Promise.resolve();
      }
    },
  };

  return (
    <Toolbar>
      <CreateButton label="Nouvel Utilisateur" />
      {props.company && (
        <ImportButtons
          config={config}
          headersMap={headersMap}
          xlsxTemplate={process.env.REACT_APP_TEMPLATE_IMPORT_USERS_EXCEL}
          csvTemplate={process.env.REACT_APP_TEMPLATE_IMPORT_USERS}
        />
      )}
      {props.company && (
        <CustomExportButton
          fields={[
            { variable: "firstName", label: "Prénom" },
            { variable: "lastName", label: "Nom" },
            { variable: "email", label: "Email" },
            { variable: "service", label: "Service" },
            { variable: "position", label: "Fonction" },
            { variable: "proMobilePhone", label: "Numéro mobile PRO" },
            { variable: "proPhone", label: "Numéro de téléphone PRO" },
            {
              variable: "licenseId",
              label: "Possède une licence",
              transform: (licenseId: any) => (licenseId ? "Oui" : "Non"),
            },
            { variable: "note", label: "Note" },
          ]}
          fileName="utilisateurs"
        />
      )}
    </Toolbar>
  );
};

const BulkActionButtons = ({ ...props }) => {
  return (
    <>
      <UsersBulkGroup company={props.company} />
      <UsersBulkAssignLicenses company={props.company} identity={props.identity} />
      <UsersBulkRemoveLicenses />
      <IfCanAccess action="delete">
        <BulkDeleteButton />
      </IfCanAccess>
    </>
  );
};

// Having company prop means that list is called from companies edit
const UsersList = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  if (!identity) return null;
  return (
    <>
      <PageTitle text="Utilisateurs" backButton={false} />
      <List
        {...props}
        filter={
          props.company
            ? { company: props.company }
            : identity?.status !== "Collaborateur"
            ? { company: identity?.company }
            : {}
        }
        sort={{ field: "name", order: "ASC" }}
        filters={
          <Filter {...props}>
            {!props.company && ["Collaborateur", "Revendeur"].includes(identity?.status) && (
              <ReferenceInput
                source="company"
                reference="companies"
                alwaysOn
                filter={identity?.status !== "Collaborateur" ? { parentCompany: identity?.company } : {}}
                sort={{ field: "company", order: "ASC" }}
                perPage={1000}
              >
                <AutocompleteInput
                  label="Organisation"
                  optionText="company"
                  filterToQuery={(search: any) => ({ company: search })}
                />
              </ReferenceInput>
            )}
            <TextInput label="Email" source="email" alwaysOn />
            <TextInput label="Nom" source="lastName" alwaysOn />
            <ReferenceInput
              source="crmGroupId"
              reference="groups"
              alwaysOn
              filter={
                props.group_id
                  ? { company: props.group_id }
                  : identity?.status === "Client"
                  ? { company: identity?.company }
                  : {}
              }
              sort={{ field: "name", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput label="Groupe" optionText="name" filterToQuery={(search: any) => ({ name: search })} />
            </ReferenceInput>
          </Filter>
        }
        actions={<ListActions company={props.company || identity?.company} />}
        empty={false}
      >
        <DigisoftDatagrid
          bulkActionButtons={<BulkActionButtons company={props.company || identity?.company} identity={identity} />}
        >
          <ReferenceField label="Nom complet" source="id" reference="users" link={false}>
            <FunctionField
              render={(record: any) => {
                const formattedFirstName = !record.firstName
                  ? ""
                  : record.firstName[0].toUpperCase() + record.firstName.slice(1);
                const formattedLastName = !record.lastName ? "" : record.lastName.toUpperCase();
                return formattedFirstName && formattedLastName ? (
                  <div style={{ display: "flex", flexDirection: "row", gap: "12px", alignItems: "center" }}>
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "999px",
                        backgroundColor: stc(`${formattedFirstName} ${formattedLastName}`),
                        color: "white",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      {formattedFirstName[0]}
                      {formattedLastName[0]}
                    </div>
                    <b>{`${formattedFirstName} ${formattedLastName}`}</b>
                  </div>
                ) : (
                  <></>
                );
              }}
            />
          </ReferenceField>
          <TextField source="email" />
          {!props.company && (
            <ReferenceField label="Organisation" source="company" reference="companies">
              <FunctionField
                render={(record: any) => {
                  return <b>{record.company}</b>;
                }}
              />
            </ReferenceField>
          )}

          <FunctionField
            label="Groupe"
            render={(record: any) => {
              return record?.crmGroupId ? (
                <ReferenceField source="crmGroupId" reference="groups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
              ) : (
                <Typography sx={{ fontSize: "0.9rem" }}>aucun</Typography>
              );
            }}
          />

          <FunctionField
            label="Rôle"
            render={(record: IUser) => {
              const role = record.role;
              return (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  {role === "Administrateur" && (
                    <StarRateRoundedIcon
                      style={{
                        marginRight: "4px",
                        marginTop: "-.235ex",
                        verticalAlign: "middle",
                        color: "#ffbf00",
                      }}
                    />
                  )}
                  <b>{role}</b>
                </div>
              );
            }}
          />

          <FunctionField
            label="Licence affectée"
            render={(record: IUser) => {
              const statusColor = record.licenseId ? "#58A14E" : "#CD5C5C";
              return (
                <div
                  style={{
                    background: statusColor,
                    width: "fit-content",
                    color: "white",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    borderRadius: "6px",
                    fontWeight: "bold",
                  }}
                >
                  {record.licenseId ? "Oui" : "Non"}
                </div>
              );
            }}
          />
          <DateField label="Modifié le" source="lastupdate" showTime={true} />
        </DigisoftDatagrid>
      </List>
    </>
  );
};

export default UsersList;
