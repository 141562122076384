import { Box, Button, Checkbox, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Labeled, NumberInput, SelectArrayInput, TextField, required } from "react-admin";
import { useRecordContext } from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";
import WarningIcon from "@mui/icons-material/Warning";
import api from "../../services/apiWithAuth";

const CheckboxOption = () => {
  const record = useRecordContext();
  const licensesToDelete = useWatch({ name: "licensesToDelete" });
  return (
    <span>
      <Checkbox checked={licensesToDelete?.includes(record.id)} color="warning" />
      {record.name}
    </span>
  );
};

const QuantityInput = ({ source, label, ...props }: any) => {
  const record = useRecordContext(props);
  const [helper, setHelper] = useState<any>(null);
  const [prorationAmount, setProrationAmount] = useState<number | null>(null);
  const quantityToAdd = useWatch({ name: "quantityToAdd" });
  const licensesToDelete = useWatch({ name: "licensesToDelete" });
  const quantityAction = useWatch({ name: "quantityAction" });
  const { setValue } = useFormContext();

  useEffect(() => {
    if (quantityAction === "add") {
      const priceCoeff = (record.quantity + quantityToAdd) / record.quantity;
      setHelper(
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
          <WarningIcon sx={{ color: "warning.main", fontSize: "48px", mr: 2 }} />
          <div style={{ maxWidth: "600px" }}>
            En ajoutant des licences à votre abonnement, vous serez prélevé immédiatement du montant des nouvelles
            licences au prorata du temps restant avant le renouvellement de votre abonnement{" "}
            <b>{prorationAmount && quantityToAdd > 0 ? `, soit ${prorationAmount.toFixed(2)}€ TTC` : ""}</b>
            .
            <br />
            {quantityToAdd > 0 && (
              <b>
                Les prochains prélèvements seront ensuite de {(record.billingPriceTtc * priceCoeff).toFixed(2)}€ TTC
                (soit {(record.billingPrice * priceCoeff).toFixed(2)}€ HT)
              </b>
            )}
          </div>
        </Box>
      );
    } else if (quantityAction === "delete") {
      const priceCoeff =
        (record.quantity - (licensesToDelete?.length || 0)) /
        (record.quantity - (record?.licensesToDelete?.length || 0));
      setHelper(
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
          <WarningIcon sx={{ color: "warning.main", fontSize: "48px", mr: 2 }} />
          <div style={{ maxWidth: "600px" }}>
            En supprimant des licences de votre abonnement, ces dernières ne seront plus utilisables dans le complément
            SMS Meeting <b>à la fin de la période de facturation en cours</b>. De plus cela changera le montant de votre
            abonnement. <br />
            <b>
              Les prochains prélèvements seront de {(record.billingPriceTtc * priceCoeff).toFixed(2)}€ TTC (soit{" "}
              {(record.billingPrice * priceCoeff).toFixed(2)}€ HT)
            </b>
          </div>
        </Box>
      );
    } else if (quantityAction === "cancelDeletion") {
      const priceCoeff = record.quantity / (record.quantity - (record?.licensesToDelete?.length || 0));
      setHelper(
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
          <WarningIcon sx={{ color: "warning.main", fontSize: "48px", mr: 2 }} />
          <div style={{ maxWidth: "600px" }}>
            En annulant la suppression programmée des licences que vous aviez sélectionnées, votre abonnement reprendra son prix
            initial de {(record.billingPriceTtc * priceCoeff).toFixed(2)}€ TTC (soit{" "}
            {(record.billingPrice * priceCoeff).toFixed(2)}€ HT)
          </div>
        </Box>
      );
    } else {
      setHelper(null);
    }
  }, [
    licensesToDelete?.length,
    prorationAmount,
    quantityAction,
    quantityToAdd,
    record.billingPrice,
    record.billingPriceTtc,
    record?.licensesToDelete?.length,
    record.quantity,
  ]);

  useEffect(() => {
    if (quantityAction === "add" && quantityToAdd > 0 && record.autoRenewal && record?.paymentMethod?.id) {
      const asyncCall = async () => {
        const {
          data: { prorationAmount },
        } = await api.post("/utils/getProrationAmount", {
          stripeId: record.stripe_id,
          billingPriceTtc: ((record.quantity + quantityToAdd) / record.quantity) * record.billingPriceTtc,
          paymentMethodId: record.paymentMethod.id,
        });
        setProrationAmount(prorationAmount);
      };
      asyncCall();
    }
  }, [
    quantityAction,
    quantityToAdd,
    record.autoRenewal,
    record.billingPriceTtc,
    record.id,
    record?.paymentMethod?.id,
    record.quantity,
    record.stripe_id,
  ]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Labeled sx={{ mb: 2 }} label="Quantité licences">
        <TextField required source="quantity" />
      </Labeled>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {record.licensesToDelete?.length > 0 ? (
          <>
            <Button
              color="primary"
              variant={quantityAction === "cancelDeletion" ? "contained" : "outlined"}
              size="small"
              onClick={() => {
                if (quantityAction !== "cancelDeletion") {
                  setValue(
                    "licensesToDelete",
                    record?.licensesToDelete?.length > 0 ? record?.licensesToDelete : []
                  );
                }
                setValue("quantityAction", quantityAction !== "cancelDeletion" ? "cancelDeletion" : "");
              }}
            >
              Annuler suppression des licences
            </Button>
            <Button
              sx={{ ml: 2 }}
              color="warning"
              variant={quantityAction === "delete" ? "contained" : "outlined"}
              size="small"
              onClick={() => {
                if (quantityAction === "delete") {
                  setValue(
                    "licensesToDelete",
                    record?.licensesToDelete?.length > 0 ? record?.licensesToDelete : []
                  );
                }
                setValue("quantityAction", quantityAction !== "delete" ? "delete" : "");
              }}
            >
              Modifier licences à supprimer
            </Button>
          </>
        ) : (
          <>
            <Button
              color="primary"
              variant={quantityAction === "add" ? "contained" : "outlined"}
              size="small"
              onClick={() => {
                if (quantityAction !== "add") {
                  setValue(
                    "licensesToDelete",
                    record?.licensesToDelete?.length > 0 ? record?.licensesToDelete : []
                  );
                }
                setValue("quantityAction", quantityAction !== "add" ? "add" : "");
              }}
            >
              Ajouter des licences
            </Button>
            <Button
              sx={{ ml: 2 }}
              color="warning"
              variant={quantityAction === "delete" ? "contained" : "outlined"}
              size="small"
              onClick={() => setValue("quantityAction", quantityAction !== "delete" ? "delete" : "")}
            >
              Supprimer des licences
            </Button>
          </>
        )}
      </Box>
      {quantityAction === "add" && (
        <NumberInput
          required
          source="quantityToAdd"
          label="Nombre de licences à rajouter"
          helperText={helper}
          step={1}
          min={1}
          sx={{ "& .MuiFormHelperText-root": { ml: 0 } }}
        />
      )}
      {(quantityAction === "delete" || record.licensesToDelete?.length > 0) && (
        <SelectArrayInput
          sx={{
            "& .MuiChip-root": { bgcolor: "warning.main", "& .MuiCheckbox-root": { display: "none" } },
            mt: 2,
            "& .MuiFormHelperText-root": { ml: 0 },
          }}
          source="licensesToDelete"
          defaultValue={record?.licensesToDelete?.length > 0 || []}
          label={
            record.licensesToDelete?.length > 0 ? "Licences prochainement supprimées" : "Licences à supprimer"
          }
          choices={record?.licenses?.map((license: any) => ({
            id: license.uuid,
            name: license.email || `licence non attribuée (${license.uuid})`,
          }))}
          optionText={<CheckboxOption />}
          helperText={helper}
          disabled={record.licensesToDelete?.length > 0 && quantityAction !== "delete"}
        />
      )}
    </Box>
  );
};

export default QuantityInput;
