import { Box, Typography, Stack, Button, useMediaQuery } from "@mui/material";
import React from "react";
import ProductCard from "./ProductCard";
import CartHeader from "./CartHeader";
import PromoCode from "../checkout-resume/PromoCode";

// widths of shopping cart elements (4 or 5 columns)
let widths = [
  [0.3, 0.3],
  [0.25, 0.175],
  [0.2, 0.175],
  [0.25, 0.175],
  [0, 0.175],
];

const CheckoutCart = ({ cart, totals }: any) => {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  if (matches) {
    widths = widths.filter((width: [], i: number) => i <= 3);
  }

  return (
    <Box>
      <Typography variant="h6" sx={{ my: 2, fontSize: ["1rem", "1.2rem"], textAlign: ["center", "left"] }}>
        Vous avez actuellement <b>{cart.length}</b> produits dans votre panier.
      </Typography>

      <Box display="flex" flexDirection="row" alignItems={"start"} gap="25px">
        <Stack gap="10px" width={"100%"}>
          <CartHeader widths={widths} labels={["Produit", "Prix HT", "Quantité", "Total HT", "Action"]} />
          {cart.map((p: any) => {
            return <ProductCard key={p.product.id + p.tarifId} widths={widths} item={p} totals={totals}/>;
          })}
        </Stack>
      </Box>

    </Box>
  );
};

export default CheckoutCart;
