import React, { useCallback, useState } from "react";
import {
  useListContext,
  useUnselectAll,
  SimpleForm,
  TextInput,
  useRefresh,
  useNotify,
  useCreate,
} from "react-admin";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";

const GroupsUserButton = ({ company }: any) => {
  const notify = useNotify();
  const { selectedIds, data } = useListContext();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll("users");
  //using key for the react-admin dialog component is a hack to close the dialog after submitting
  //without interfering with the isOpen property.
  const [key, setKey] = useState(0);

  const [create] = useCreate();
  const save = useCallback(
    async (values: any) => {
      const selectedUsers = data.filter(({ id }) => values.users.includes(id));
      const oneUserAlreadyInGroup = selectedUsers.some((user) => user.inCrmGroup);
      if (oneUserAlreadyInGroup) {
        notify("Au moins un utilisateur sélectionné appartient déjà à un groupe.", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
        setKey((key) => key + 1);
        refresh();
        return;
      }
      try {
        await create("groups", { data: values }, { returnPromise: true });
        notify("Groupe créé avec succès", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
        unselectAll();
      } catch (error) {
        notify("Erreur lors de la création du groupe", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
      }
      setKey((key) => key + 1);
      refresh();
    },
    [create, data, notify, refresh, unselectAll]
  );

  return (
    <>
      <CreateInDialogButton
        title={"Nouveau groupe"}
        label="Former un groupe"
        resource="groups"
        record={{ company, users: selectedIds }}
        key={key}
      >
        <SimpleForm onSubmit={save}>
          <TextInput label="Nom du groupe" source="name" />
        </SimpleForm>
      </CreateInDialogButton>
    </>
  );
};

export default GroupsUserButton;
