import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useContext, useState, useCallback } from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  SelectInput,
  useNotify,
  useRedirect,
  ReferenceInput,
  useGetIdentity,
  AutocompleteInput,
  required,
  FormDataConsumer,
  BooleanInput,
  PasswordInput,
  useCreate,
} from "react-admin";
import api from "../../services/apiWithAuth";
import { CRM_ROLES, SERVICES, TITLES, USERS_ROLES } from "../../services/common-utils/constants";
import { HistoryContext } from "../../services/historyProvider";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import PageTitle from "../../components/navigation/PageTitle";

const UsersCreate = ({ ...props }) => {
  const notify = useNotify();
  const redirect = useCustomListRedirect();
  const { data: identity } = useGetIdentity();
  const history: string[] = useContext(HistoryContext);
  const [fromCompany, setFromCompany] = useState("");

  const [create] = useCreate();
  const save = useCallback(
    async (values: any) => {
      const errors = {} as any;
      if (["Client", "Indirect"].includes(identity?.status)) {
        values.company = identity?.company;
      }
      values.email = values.email.trim().toLowerCase();
      values.licenseId = null;
      if (values.role === "Administrateur") {
        try {
          await api.post("/utils/createAuthUser", { email: values.email, password: values.password || null });
          delete values.password;
        } catch (error) {
          notify(`Adresse email invalide`, { type: "error" });
          errors.email = "Cette adresse email est déjà utilisée. Merci d'en choisir une autre.";
          return errors;
        }
      }
      try {
        await create("users", { data: values }, { returnPromise: true });
        notify("Utilisateur créé avec succès", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
        redirect("users");
      } catch (error) {
        notify("Erreur lors de la création de l'utilisateur", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
      }
    },
    [create, identity?.company, identity?.status, notify, redirect]
  );

  // Success override
  const onSuccess = (data: any) => {
    notify(`Compte créé avec succès`, { type: "success" });
    redirect("users");
  };

  useEffect(() => {
    //route from routerContext can be not already updated
    if (history.length && !fromCompany && history[0] === window.location.pathname) {
      const match = history[1].match(/(?:companies\/)(.*)(?:\/)/);
      setFromCompany(match ? match[1] : "none");
    }
  }, [history, fromCompany]);

  return !fromCompany ? (
    <></>
  ) : (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm defaultValues={() => (fromCompany !== "none" ? { company: fromCompany } : {})} onSubmit={save}>
        <PageTitle text="Création d'un nouvel utilisateur" divider />

        <Grid container direction={"column"}>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
            <TextInput required source="email" label="Adresse email" />
            <Typography variant="body2" sx={{ color: "gray" }}>
              Cet e-mail sera utilisée pour se connecter au CRM et/ou y affecter une licence
            </Typography>
          </Box>

          {["Collaborateur", "Revendeur"].includes(identity?.status) && (
            <ReferenceInput
              required
              source="company"
              reference="companies"
              filter={identity?.status === "Collaborateur" ? {} : { parentCompany: identity?.company }}
              sort={{ field: "company", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput
                label="Organisation"
                optionText="company"
                filterToQuery={(search: any) => ({ company: search })}
              />
            </ReferenceInput>
          )}
          <SelectInput
            validate={required()}
            defaultValue={"Utilisateur sans accès"}
            source="role"
            label="Rôle"
            choices={USERS_ROLES.map((s) => ({ id: s, name: s }))}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.role === "Administrateur" && (
                <BooleanInput defaultValue={false} source="createWithPassword" label="Personnaliser mot de passe" />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.role === "Administrateur" &&
              formData.createWithPassword && (
                <PasswordInput required defaultValue={null} source="password" label="Mot de passe" />
              )
            }
          </FormDataConsumer>
        </Grid>

        <div style={{ borderBottom: "1px solid lightgray", width: "100%", marginBottom: "28px", marginTop: "16px" }} />

        <Typography variant="body1" mb={"20px"}>
          Informations du compte
        </Typography>

        <Grid container direction={"column"}>
          <SelectInput
            source="title"
            defaultValue={null}
            label="Civilité"
            choices={TITLES.map((s) => ({ id: s, name: s }))}
          />
          <TextInput source="firstName" defaultValue={null} label="Prénom" />
          <TextInput source="lastName" defaultValue={null} label="Nom de famille" />
          <TextInput source="service" defaultValue={null} label="Service" />
          <TextInput source="position" defaultValue={null} label="Fonction" />
          <TextInput source="proMobilePhone" defaultValue={null} label="Numéro mobile PRO" />
          <TextInput source="proPhone" defaultValue={null} label="Numéro de téléphone PRO" />
          <TextInput source="note" defaultValue={null} label="Note" />
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default UsersCreate;
