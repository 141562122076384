import React, { Fragment, useCallback } from "react";
import {
  CreateButton,
  useGetIdentity,
  useListContext,
  Count,
  DateField,
  Filter,
  FunctionField,
  ReferenceField,
  TextField,
  Toolbar,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  FormDataConsumer,
  Labeled,
  SelectInput,
} from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { DigisoftDatagrid } from "../../components/react-admin/DigisoftDatagrid";
import { ISubscription } from "./ISubscription";
import { Divider, Tabs, Tab, Typography } from "@mui/material";
import PaymentMethodField from "../../components/fields/PaymentMethodField";
import PageTitle from "../../components/navigation/PageTitle";

const paymentStatusToColor: any = {
  "Paiement accepté": "success.light",
  "Paiement en cours": "warning.main",
  "En attente": "neutral.main",
  "Paiement en erreur": "error.main",
  Litige: "error.main",
};

// Having company prop means that component is called from companies edit.
const SubscriptionsList = ({ ...props }) => {
  const { isLoading, data: identity } = useGetIdentity();
  return isLoading ? (
    <></>
  ) : (
    <>
      <PageTitle text="Abonnements" backButton={false} />
      <List
        {...props}
        filter={
          props.company
            ? { company_id: props.company }
            : identity?.status !== "Collaborateur"
            ? { company_id: identity?.company }
            : {}
        }
        filters={
          <Filter {...props}>
            {identity?.status === "Collaborateur" && !props.company && (
              <ReferenceInput
                source="company_id"
                reference="companies"
                alwaysOn
                sort={{ field: "company", order: "ASC" }}
                perPage={1000}
              >
                <AutocompleteInput
                  label="Rechercher par organisation"
                  optionText="company"
                  filterToQuery={(search: any) => ({ company: search })}
                />
              </ReferenceInput>
            )}
            <ReferenceInput
              source="product_id"
              reference="products"
              alwaysOn
              sort={{ field: "name", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput
                label="Rechercher par produit"
                optionText="name"
                filterToQuery={(search: any) => ({ name: search })}
              />
            </ReferenceInput>
            <SelectInput
              source="cancelled"
              label="État"
              choices={[
                { id: false, name: "En cours" },
                { id: true, name: "Annulé" },
              ]}
              alwaysOn
            />
          </Filter>
        }
        filterDefaultValues={{ cancelled: false }}
        actions={
          identity?.status !== "Collaborateur" ? (
            false
          ) : (
            <Toolbar>
              <CreateButton label="Nouvel Abonnement" />
            </Toolbar>
          )
        }
        empty={false}
        sort={{ field: "periodEnd", order: "DESC" }}
      >
        <DigisoftDatagrid bulkActionButtons={false}>
          <DateField label="Souscrit le" source="createdate" showTime />
          {/* <DateField label="Souscrit le" source="subscriptionDate" /> */}
          <ReferenceField label="Produit" source="product_id" reference="products" link={false}>
            <TextField source="name" />
          </ReferenceField>
          {!props.company && ["Collaborateur", "Revendeur"].includes(identity?.status) && (
            <ReferenceField label="Organisation" source="company_id" reference="companies" link={false}>
              <TextField source="company" />
            </ReferenceField>
          )}
          <FunctionField
            label="Montant HT"
            render={(record: any) => Number(record.billingPrice).toFixed(2) + " €"}
            mb={"20px"}
          />

          {identity?.status === "Collaborateur" && (
            <FunctionField
              label="Renouvellement"
              render={(record: any) => {
                return record.autoRenewal ? "Automatique" : "Manuel";
              }}
            />
          )}
          <FunctionField
            label="Mode de paiement"
            render={(record: any) => {
              return record.autoRenewal ? (
                <PaymentMethodField source="paymentMethod" />
              ) : (
                <TextField source="manualPaymentMode" />
              );
            }}
          />
          <FunctionField
            label="Prochaine facture"
            render={(record: any) =>
              record.cancelled
                ? "aucune"
                : !record.periodEnd
                ? ""
                : new Date(record.periodEnd * 1000).toLocaleDateString("fr")
            }
          />
          <FunctionField
            label="Abonné depuis"
            render={(record: any) => (record.cancelled ? "" : getDurationSince(record.subscriptionDate) + " mois")}
          />
          <FunctionField
            label="État"
            render={(record: any) => {
              return (
                <Typography
                  sx={{
                    color: "white",
                    bgcolor: record.cancelled ? "error.main" : "success.main",
                    px: 1,
                    py: 0.5,
                    borderRadius: 2,
                    width: "fit-content",
                    fontSize: "0.9rem",
                    opacity: 0.8,
                  }}
                >
                  {record.cancelled ? "Annulé" : "En cours"}
                </Typography>
              );
            }}
          />
        </DigisoftDatagrid>
      </List>
    </>
  );
};

export default SubscriptionsList;

function getDurationSince(startDate: string) {
  const today = new Date();
  const date = new Date(startDate);
  const yearsDiff = today.getFullYear() - date.getFullYear();
  const monthsDiff = today.getMonth() - date.getMonth();
  const correction = today.getDate() - date.getDate() < 0 ? 0 : 1;

  // +1 to avoid "from 0 month"
  return yearsDiff * 12 + monthsDiff + correction;
}
