export const COUNTRIES = ["France", "Belgique", "Suisse", "Canada", "Etats-Unis", "Guadeloupe", "Saint-Barthelemy"];
export const COUNTRIES_WITHOUT_VAT = ["Suisse", "Canada", "Etats-Unis", "Guadeloupe", "Saint-Barthelemy"]; //out of EU
export const TYPES = ["Secteur public", "Association", "Entreprise privée"];
export const TITLES = ["Monsieur", "Madame", "Mademoiselle"];
export const SERVICES = [
  "Commercial",
  "Marketing",
  "Achats",
  "Ressources humaines",
  "Juridique",
  "Informatique",
  "Financier",
  "Recherche et développement",
  "Direction",
];
export const CRM_ROLES = ["Super Admin", "Direction", "Commercial", "Technique"];
export const STATUSES = ["Actif", "Inactif"];
export const COMPANIES_STATUSES = ["Client", "Revendeur", "Indirect"];
export const COMPANIES_SOURCES = [
  "sms-meeting.com",
  "Sms-outlook.com",
  "Web Marketing",
  "Réseaux B to B",
  "Digisoft.pro",
  "SEO",
  "Google Ads",
  "Autres",
];
export const COMPANIES_ACCOUNT_STATUSES = ["Actif", "Archivé", "Bloqué"];
export const COMPANIES_SECTORS = [
  "Action Sociale",
  "Administration publique",
  "Automobile",
  "Banque / Assurance",
  "BTP / Construction",
  "Commerce / Distribution",
  "Comptabilité",
  "Édition / Communication",
  "Études et conseils",
  "Formation continue",
  "Immobilier",
  "Industrie pharmaceutique",
  "Informatique / Télécoms",
  "Juridique / Droit",
  "Santé",
  "Sports",
  "Transports / Logistique",
];
export const USERS_ROLES = ["Administrateur", "Utilisateur sans accès"];
export const PRODUCTS_TYPES = ["Standard", "Licence", "Crédits SMS"];
export const PRODUCTS_PAYMENT_MODELS = ["Forfait", "Standard"];
export const PRODUCTS_TAXES = [0, 20];
export const PRODUCTS_STATUSES = ["En ligne", "Invisible", "Hors ligne"];
export const PRODUCTS_BILLING_PERIOD = ["Quotidienne", "Mensuelle", "Annuelle"];
export const DELAYS = [
  { name: "15 minutes", id: 15 },
  { name: "30 minutes", id: 30 },
  { name: "1 heure", id: 60 },
  { name: "2 heures", id: 60 * 2 },
  { name: "4 heures", id: 60 * 4 },
  { name: "6 heures", id: 60 * 6 },
  { name: "8 heures", id: 60 * 8 },
  { name: "10 heures", id: 60 * 10 },
  { name: "1 jour", id: 1440 },
  { name: "2 jours", id: 1440 * 2 },
  { name: "8 jours", id: 1440 * 8 },
  { name: "4 heures après", id: -60 * 4 },
  { name: "1 jour après", id: -1440 },
];
export const DISCOUNT_APPLICATION_TYPES = ["Automatique", "Code promo"];
export const DISCOUNT_TYPES = ["Pourcentage", "Montant", "Produit offert"];
export const DISCOUNT_SCOPES = ["Panier", "Produits spécifiques"];
export const OPPORTUNITIES_STEPS = ["Premier contact", "Démonstration", "Devis"];
export const OPPORTUNITIES_STATUSES = ["En cours", "Version d'essai activée", "Gagné", "Perdu"];
export const MANUAL_PAYMENT_MODES = ["Virement", "Mandat administratif"];
export const CONTACT_VISIBILITIES = ["Tous les utilisateurs", "Cloisonné à un groupe d'utilisateurs", "Privé"];
export const RESOURCES_LIST = [
  "admin",
  "collaborators",
  "companies",
  "users",
  "orders",
  "products",
  "discounts",
  "attributes",
  "subscriptions",
  "licenses",
  "templates",
  "contacts",
  "contactLists",
  "sms",
  "providers",
  "paymentMethods",
  "groups",
  "downloads",
  "opportunities",
];
