import * as React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

export default function GoBackButton({ backUrl }: { backUrl?: string }) {
  const navigate = useNavigate();
  return (
    <Tooltip title="Retour">
      <IconButton
        onClick={() => {
          if (backUrl) {
            navigate(backUrl);
          } else {
            navigate(-1);
          }
        }}
      >
        <KeyboardReturnIcon sx={{ fontSize: 20 }} color="primary" />
      </IconButton>
    </Tooltip>
  );
}
