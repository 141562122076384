import { ExportButton, downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import React, { useState } from "react";
import DownloadIcon from "@mui/icons-material/GetApp";
import CircularProgress from "@mui/material/CircularProgress";

export const CustomExportButton = ({ fields, fileName }: { fields: any[]; fileName: string }) => {
  const [isExporting, setIsExporting] = useState(false);

  const exporter = (
    records: any,
    fetchRelatedRecords: any,
    dataProvider: any,
    selectedFields: any[],
    fileName: string
  ) => {
    const recordsForExport = records.map((record: any) => {
      let recordForExport: any = {};
      selectedFields.forEach((field: any) => {
        let value = record[field.variable] || "";
        if (field.transform) {
          value = field.transform(value);
        }
        recordForExport[field.variable] = value;
      });
      return recordForExport;
    });
    return jsonExport(
      recordsForExport,
      {
        rowDelimiter: ";",
        headers: selectedFields.map((field: any) => field.variable),
        rename: selectedFields.map((field: any) => field.label),
      },
      (err: any, csv: any) => {
        downloadCSV(csv, fileName);
        setIsExporting(false);
      }
    );
  };

  return (
    <ExportButton
      exporter={(records, fetchRelatedRecords, dataProvider) =>
        exporter(records, fetchRelatedRecords, dataProvider, fields, fileName)
      }
      icon={isExporting ? <CircularProgress size={25}/> : <DownloadIcon />}
      onClick={() => setIsExporting(true)}
    />
  );
};

export default CustomExportButton;
