import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button as MuiButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useListContext, useNotify, useRefresh, useUnselectAll, Button, Toolbar, SaveButton } from "react-admin";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import api from "../../services/apiWithAuth";

// const TransferToolBar = () => (
//   <Toolbar>
//     <SaveButton label="Transferer" icon={<MoveDownIcon />} />
//   </Toolbar>
// );

const LicensesMerge = ({ identity }: any) => {
  const { selectedIds, data } = useListContext();
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("licenses");
  const [company, setCompany] = useState<string | null>(null);
  const [attribuatedLicense, setAttribuatedLicense] = useState<any>(null);

  useEffect(() => {
    if (selectedIds.length >= 2) {
      const selectedItems = selectedIds
        .map((id: any) => {
          const license = data.find((license) => id === license.id);
          return license;
        })
        .filter((license) => license !== undefined);
      if (!selectedItems?.length) {
        unselectAll();
        setCompany(null);
        setAttribuatedLicense(null);
        return;
      }
      const attribuatedLicenses = selectedItems.filter((license: any) => !!license.email);
      //Merge only possible if 1 license is attribuated
      if (attribuatedLicenses.length === 1) {
        setAttribuatedLicense(attribuatedLicenses[0]);
        const company = selectedItems[0].group_id;
        setCompany(selectedItems.every((license: any) => license.group_id === company) ? company : null);
      } else {
        setAttribuatedLicense(null);
        setCompany(null);
      }
    }
  }, [data, selectedIds, unselectAll]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const mergeLicenses = async () => {
    const someLicenseDisabled = selectedIds.some((licenseId) => {
      const license = data?.find((license: any) => licenseId === license.id);
      return license?.disabled;
    });
    if (someLicenseDisabled) {
      setOpen(false);
      notify("Fusion impossible : une des licences à fusionner est désactivée", { type: "error" });
      return;
    }
    const secondaryLicenseIds = selectedIds.filter((licenseId) => licenseId !== attribuatedLicense.uuid);
    await api.post("/licenses/mergeLicenses", {
      mainLicenseId: attribuatedLicense.uuid,
      secondaryLicenseIds,
    });
    refresh();
    notify("Licences fusionnées avec succès");
    unselectAll();
    setOpen(false);
  };

  return attribuatedLicense !== null && company ? (
    <>
      <Button label="Fusionner des licences" onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle color="primary.main">Fusionner des licences pour utilisation multiposte</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vous avez sélectionné {selectedIds.length -1} licences à rattacher à celle affectée à <b>{attribuatedLicense.email}</b>. Après
            validation vous pourrez utiliser cette licence sur {(attribuatedLicense.max_connections || 1) + selectedIds.length - 1} postes différents. <br /> <br />
            <b>Attention :</b> les licences rattachées ne seront plus affectables à des utilisateurs.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleClose} sx={{ color: "warning.main" }}>
            Annuler
          </MuiButton>
          <MuiButton onClick={mergeLicenses} sx={{ color: "green" }} autoFocus>
            Confirmer
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};

export default LicensesMerge;
