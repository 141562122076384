import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  Create,
  TextInput,
  SelectInput,
  required,
  useNotify,
  useRedirect,
  ReferenceInput,
  useGetIdentity,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import { COUNTRIES, OPPORTUNITIES_STEPS, OPPORTUNITIES_STATUSES } from "../../services/common-utils/constants";
import PageTitle from "../../components/navigation/PageTitle";

const OpportunitiesCreate = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  const notify = useNotify();
  const redirect = useRedirect();
  const onTransform = (data: any) => {
    return {
      ...data,
      status: "En cours",
      origin: "admin",
      seenBy: [identity?.id],
    };
  };

  // Success override
  const onSuccess = (data: any) => {
    notify(`Opportunité créée avec succès`, { type: "success" });
    redirect("list", "opportunities", data.id, data);
  };

  return (
    <Create transform={onTransform} {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm>
        <PageTitle text="Création d'une opportunité" divider />

        <TextInput defaultValue={null} source="company" label="Organisation" />
        <SelectInput
          validate={required()}
          defaultValue="France"
          source="country"
          label="Pays"
          choices={COUNTRIES.map((s) => ({ id: s, name: s }))}
        />
        <TextInput defaultValue={null} source="mainContact" label="Contact principal" />
        <TextInput defaultValue={null} source="phone" label="Téléphone" />
        <TextInput defaultValue={null} source="email" label="Email" />
        <TextInput defaultValue={null} source="note" label="Note" multiline />
        <ReferenceInput
          label="Collaborateur responsable"
          source="collaborator_id"
          reference="collaborators"
          perPage={1000}
        >
          <SelectInput
            label="Collaborateur responsable"
            optionText={(record) => {
              return `${record.firstName} ${record.lastName} `;
            }}
          />
        </ReferenceInput>
        <SelectInput
          defaultValue={null}
          source="step"
          label="Etape"
          choices={OPPORTUNITIES_STEPS.map((s: any) => ({ id: s, name: s }))}
        />
      </SimpleForm>
    </Create>
  );
};

export default OpportunitiesCreate;
